import ProgramMtPower from "./Programs/ProgramMtPower/ProgramMtPower";
import ProgramNMGas from "./Programs/ProgramNMGas/ProgramNMGas";

const UtilityPartner = ({
  role,
  propertyMetrics,
  properties,
  kwhUrl,
  incentiveUrl,
  program,
  showYearPopover,
  handlePopoverVisibility,
  isYearDropdownOpen,
  setIsYearDropdownOpen,
  yearOptions,
  setSelectedYear,
  filterPropertiesByYear,
  handlePopoverVisibilityOnBlur,
  isMobileScreen,
  selectedYear,
  type,
  storedPropertyMetrics,
  isLoading,
  search,
  setKwhUrl,
  closeStatus,
  setCloseStatus,
  community,
  setCommunity,
  barrierCount,
  setBarrierCount,
  daysInReportingStage,
  setDaysInReportingStage,
  currentPageProperties,
  isFiltering,
  showStatusPopover,
  selectedStatus,
  selectedOptionStatus,
  setSelectedStatus,
  filterProperties,
  showRebateTypePopover,
  selectedRebateType,
  selectedOptionRebateType,
  setSelectedRebateType,
  showLIMRPopover,
  selectedLIMR,
  selectedOptionLIMR,
  setSelectedLIMR,
  showDatePopover,
  selectedDate,
  selectedOptionDate,
  setSelectedDate,
  mdValue,
  handleSetSearch,
  handleSearch,
  role_type,
  propertiesOffset,
  lastPageIndex,
  propertiesPerPage,
  handlePropertiesPerPage,
  handlePageClick,
  setIncentiveUrl,
}) => {
  return (
    <>
      {role_type === "Nm Gas" ? (
        <ProgramNMGas
          role={role}
          propertyMetrics={propertyMetrics}
          properties={properties}
          kwhUrl={kwhUrl}
          incentiveUrl={incentiveUrl}
          program={program}
          showYearPopover={showYearPopover}
          handlePopoverVisibility={handlePopoverVisibility}
          isYearDropdownOpen={isYearDropdownOpen}
          setIsYearDropdownOpen={setIsYearDropdownOpen}
          yearOptions={yearOptions}
          setSelectedYear={setSelectedYear}
          filterPropertiesByYear={filterPropertiesByYear}
          handlePopoverVisibilityOnBlur={handlePopoverVisibilityOnBlur}
          isMobileScreen={isMobileScreen}
          selectedYear={selectedYear}
          type={type}
          storedPropertyMetrics={storedPropertyMetrics}
          isLoading={isFiltering}
          search={search}
          setKwhUrl={setKwhUrl}
          closeStatus={closeStatus}
          setCloseStatus={setCloseStatus}
          community={community}
          setCommunity={setCommunity}
          barrierCount={barrierCount}
          setBarrierCount={setBarrierCount}
          daysInReportingStage={daysInReportingStage}
          setDaysInReportingStage={setDaysInReportingStage}
          currentPageProperties={currentPageProperties}
          isFiltering={isFiltering}
          showStatusPopover={showStatusPopover}
          selectedStatus={selectedStatus}
          selectedOptionStatus={selectedOptionStatus}
          setSelectedStatus={setSelectedStatus}
          filterProperties={filterProperties}
          showRebateTypePopover={showRebateTypePopover}
          selectedRebateType={selectedRebateType}
          selectedOptionRebateType={selectedOptionRebateType}
          setSelectedRebateType={setSelectedRebateType}
          showLIMRPopover={showLIMRPopover}
          selectedLIMR={selectedLIMR}
          selectedOptionLIMR={selectedOptionLIMR}
          setSelectedLIMR={setSelectedLIMR}
          showDatePopover={showDatePopover}
          selectedDate={selectedDate}
          selectedOptionDate={selectedOptionDate}
          setSelectedDate={setSelectedDate}
          mdValue={mdValue}
          handleSetSearch={handleSetSearch}
          handleSearch={handleSearch}
          role_type={role_type}
          propertiesOffset={propertiesOffset}
          lastPageIndex={lastPageIndex}
          propertiesPerPage={propertiesPerPage}
          handlePropertiesPerPage={handlePropertiesPerPage}
          handlePageClick={handlePageClick}
          setIncentiveUrl={setIncentiveUrl}
        />
      ) : role_type === "Rocky Mt. Power" ? (
        <ProgramMtPower
          role={role}
          propertyMetrics={propertyMetrics}
          properties={properties}
          kwhUrl={kwhUrl}
          incentiveUrl={incentiveUrl}
          program={program}
          showYearPopover={showYearPopover}
          handlePopoverVisibility={handlePopoverVisibility}
          isYearDropdownOpen={isYearDropdownOpen}
          setIsYearDropdownOpen={setIsYearDropdownOpen}
          yearOptions={yearOptions}
          setSelectedYear={setSelectedYear}
          filterPropertiesByYear={filterPropertiesByYear}
          handlePopoverVisibilityOnBlur={handlePopoverVisibilityOnBlur}
          isMobileScreen={isMobileScreen}
          selectedYear={selectedYear}
          type={type}
          storedPropertyMetrics={storedPropertyMetrics}
          isLoading={isFiltering}
          search={search}
          setKwhUrl={setKwhUrl}
          closeStatus={closeStatus}
          setCloseStatus={setCloseStatus}
          community={community}
          setCommunity={setCommunity}
          barrierCount={barrierCount}
          setBarrierCount={setBarrierCount}
          daysInReportingStage={daysInReportingStage}
          setDaysInReportingStage={setDaysInReportingStage}
          currentPageProperties={currentPageProperties}
          isFiltering={isFiltering}
          showStatusPopover={showStatusPopover}
          selectedStatus={selectedStatus}
          selectedOptionStatus={selectedOptionStatus}
          setSelectedStatus={setSelectedStatus}
          filterProperties={filterProperties}
          showRebateTypePopover={showRebateTypePopover}
          selectedRebateType={selectedRebateType}
          selectedOptionRebateType={selectedOptionRebateType}
          setSelectedRebateType={setSelectedRebateType}
          showLIMRPopover={showLIMRPopover}
          selectedLIMR={selectedLIMR}
          selectedOptionLIMR={selectedOptionLIMR}
          setSelectedLIMR={setSelectedLIMR}
          showDatePopover={showDatePopover}
          selectedDate={selectedDate}
          selectedOptionDate={selectedOptionDate}
          setSelectedDate={setSelectedDate}
          mdValue={mdValue}
          handleSetSearch={handleSetSearch}
          handleSearch={handleSearch}
          role_type={role_type}
          propertiesOffset={propertiesOffset}
          lastPageIndex={lastPageIndex}
          propertiesPerPage={propertiesPerPage}
          handlePropertiesPerPage={handlePropertiesPerPage}
          handlePageClick={handlePageClick}
          setIncentiveUrl={setIncentiveUrl}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <span className="fs-4 fw-bold text-secondary text-center">
            Dashboard Under Construction
          </span>
        </div>
      )}
    </>
  );
};

export default UtilityPartner;
