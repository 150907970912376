import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  Fragment,
} from "react";
import { Modal, Button, Alert, Navbar, Form } from "react-bootstrap";
import "./Feedback.scss";
import * as yup from "yup";
import { Formik } from "formik";
import { upload } from "../../../utils/BeeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { UserContext } from "../../../contexts/UserProvider";

const Feedback = ({ propertyName = "" }) => {
  const { email, firstName, role, program = {} } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  function handleCloseModal() {
    setShow(false);
    setSuccess(false);
    setError();
    setIsLoading();
  }

  function handleShowModal() {
    setShow(true);
  }

  function handleShowConfirmationModal() {
    setIsShow(true);
    setSuccess(false);
    setShow(false);
  }

  function handleCloseConfirmationModal() {
    setIsShow(false);
    setSuccess(false);
    setError();
    setIsLoading();
  }

  function handleSubmitFeedback(values) {
    const formData = new FormData();
    let feedbackSubject = "";
    let feedbackBodyPrefix = "";

    switch (role) {
      case "utility_partner":
        feedbackSubject = "Feedback - Utility Partner";
        feedbackBodyPrefix = `Program Name: ${program?.name}\r\n\r\n`;
        break;
      case "deep":
        feedbackSubject = "Feedback - REPS";
        feedbackBodyPrefix = `REPS-specific information here\r\n\r\n`;
        break;
      case "utility_wap":
        feedbackSubject = "Feedback - Utility/Wap";
        feedbackBodyPrefix = `Utility/Wap-specific information here\r\n\r\n`;
        break;
      default:
        feedbackSubject = "Feedback - Property Owner";
        feedbackBodyPrefix = `Property Name: ${propertyName}\r\n\r\n`;
        break;
    }

    formData.append("body", `${feedbackBodyPrefix}${values.body}`);
    formData.append("subject", feedbackSubject);
    formData.append("email", email ?? "");
    formData.append("name", firstName ?? "");

    if (values.attachment) {
      formData.append("attachment", values.attachment || "", "Attachment");
    }

    const contactPromise = upload("/contactus", formData);

    contactPromise.promise
      .then(() => {
        setError();
        setIsLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(contactPromise);
  }

  const fileSizeSchema = (file) => {
    let valid = true;
    if (file) {
      file.size <= 1000 * 2000 ? (valid = true) : (valid = false);
    }

    return valid;
  };

  const fileFormatSchema = (file) => {
    let valid = true;
    if (file) {
      [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png",
        "application/pdf",
      ].includes(file.type)
        ? (valid = true)
        : (valid = false);
    }
    return valid;
  };

  const feedbackSchema = yup.object().shape({
    body: yup.string().min(3, "Too Short").required(),
    attachment: yup
      .mixed()
      .optional()
      .test("FILE_SIZE", "Uploaded file is too big.", (file) =>
        fileSizeSchema(file)
      )
      .test("FILE_FORMAT", "Uploaded file has unsupported format.", (file) =>
        fileFormatSchema(file)
      ),
  });

  const form = (
    <Formik
      initialValues={{
        body: "",
        attachment: "",
      }}
      validationSchema={feedbackSchema}
      onSubmit={(values, action) => {
        handleSubmitFeedback(values);
        action.resetForm();
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isValid,
        setFieldValue,
      }) => (
        <form
          className="FeedbackForm"
          onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
        >
          <Modal.Header closeButton>
            <Modal.Title>Submit your comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Do you have a question, comment or suggestion? Let us know.</p>
            <Form.Group controlId="body">
              <Form.Control
                as="textarea"
                rows={5}
                name="body"
                value={values.body}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Kindly describe it ..."
                isValid={touched.body && !errors.body}
                isInvalid={touched.body && errors.body}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.body}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="attachment" className="my-3">
              <Form.Label>Please attach a screenshot. (optional)</Form.Label>
              <Form.Control
                size="sm"
                type="file"
                name="attachment"
                value={undefined}
                onChange={(event) => {
                  setFieldValue("attachment", event.currentTarget.files[0]);
                }}
                onBlur={handleBlur}
                isValid={touched.attachment && !errors.attachment}
                isInvalid={touched.attachment && errors.attachment}
                accept=".png,.jpg,.jpeg,.pdf"
              />
              <Form.Control.Feedback type="invalid">
                {errors.attachment}
              </Form.Control.Feedback>
            </Form.Group>
            {error && <ErrorHandler error={error} />}
            {isLoading && <Loader error={isLoading} />}
            {success && (
              <Alert variant="success" className="mt-2 mb-2">
                <h4>Thanks.</h4>
                <p>Comment Submitted Successfully.</p>
              </Alert>
            )}
          </Modal.Body>

          <Modal.Footer
            className={
              success ? "justify-content-between" : "justify-content-end"
            }
          >
            {success ? (
              <>
                <Button variant="primary" onClick={handleShowConfirmationModal}>
                  Add New Comment
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    handleCloseModal();
                    handleCloseConfirmationModal();
                  }}
                >
                  Close
                </Button>
              </>
            ) : (
              <div>
                <Button
                  variant="link"
                  onClick={() => {
                    handleCloseModal();
                    handleCloseConfirmationModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!isValid || !values.body}
                >
                  Submit
                </Button>
              </div>
            )}
          </Modal.Footer>
        </form>
      )}
    </Formik>
  );

  return (
    <Fragment>
      <Navbar.Text
        className="me-3"
        onClick={handleShowModal}
        style={{ cursor: "pointer" }}
      >
        {" "}
        {propertyName ? "" : "Comments"}
        <span className="material-icons-outlined">comment</span>
      </Navbar.Text>

      <Modal show={show} onHide={handleCloseModal} backdrop="static">
        {form}
      </Modal>

      <Modal
        show={isShow}
        onHide={handleCloseConfirmationModal}
        backdrop="static"
      >
        {form}
      </Modal>
    </Fragment>
  );
};

export default Feedback;
