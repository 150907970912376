import React from "react";
import { Alert } from "react-bootstrap";

import "./ErrorHandler.scss";

const ErrorHandler = ({ error, message, handleClose, show }) => {
  let status = "";
  let statusText = "Oh snap! You got an error!";
  let _message = "Something unexpected happened. Please try again.";
  if (error) {
    status = error.status || status;
    statusText = error.statusText || statusText;

    if (error.data) {
      if (error.data.error) {
        if ("message" in error.data.error) {
          _message = error.data.error.message;
        } else {
          _message =
            error.data.error[Object.keys(error.data.error)[0]] ?? _message;
        }
      } else {
        if ("message" in error.data) {
          _message = error.data.message;
        }
      }
    }
  }
  return (
    <Alert
      variant="warning"
      className={`my-3 text-danger`}
      onClose={handleClose}
      dismissible
      show={show}
    >
      <h4>{statusText}</h4>
      <p>{message || _message}</p>
      <small>{status}</small>
    </Alert>
  );
};

export default ErrorHandler;
