import React from "react";
import { Container, Row, Table } from "react-bootstrap";
import { roundOfNumber } from "../../../utils/RoundOfNumber";

const ReportTable = ({ properties = [] }) => (
  <Container className="reportTable">
    <Row className="justify-content-center p-3 h5">
      Monthly Pipeline Report from ICAST
    </Row>
    <Row className="justify-content-center">
      <Table className="reportFontSize" bordered>
        <thead>
          <tr>
            <th>Property Name</th>
            <th>Property City</th>
            <th>Total kWh Savings</th>
          </tr>
        </thead>
        <tbody>
          {properties &&
            !!properties.length &&
            properties.map((property) => {
              return (
                <tr key={property.id}>
                  <td>{property.name}</td>
                  <td>{property.addressCity}</td>
                  <td>{`$ ${roundOfNumber(property.ecm.kwhDeemedSavings)}`}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Row>
  </Container>
);

export default ReportTable;
