import React, { createContext, useState, useEffect, useRef } from "react";
import { get } from "../utils/BeeApi";
import { get as deGet } from "../utils/DeApi";
import Loader from "../components/Loader/Loader";

const UserContext = createContext(undefined);
const UserDispatchContext = createContext(undefined);
const UserDispatchDestoryContext = createContext(undefined);

const UserProvider = ({ children }) => {
  const subscribedPromises = useRef([]);

  const [user, setUser] = useState(undefined);
  const [isLoading, setIsLoading] = useState();

  const fetchUser = () => {
    const userPromise = get("/user/anon");
    setIsLoading(true);

    userPromise.promise
      .then((response) => {
        setIsLoading(false);
        const userPromiseDe = deGet("/login");
        subscribedPromises.current.push(userPromiseDe);

        const utiltiyPromiseDe = deGet(`/associate/${response.data.email}`);
        utiltiyPromiseDe.promise
          .then((utlityResponse) => {
            const readOnly =
              response.data?.categories?.filter(
                (category) => category?.categoryName === "Read Only"
              ).length > 0
                ? true
                : false;
            const newUserObject = {
              ...response.data,
              ...utlityResponse.data,
              readOnly,
            };
            setUser(newUserObject);
          })
          .catch((error) => {
            if (!error.isCanceled) {
              setUser(null);
              setIsLoading(false);
            }
          });
        subscribedPromises.current.push(utiltiyPromiseDe);

        return [utiltiyPromiseDe.promise, userPromiseDe.promise];
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setUser(null);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(userPromise);
  };

  useEffect(() => {
    fetchUser();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logOUt = () => setUser(null);

  if (isLoading) return <Loader />;
  if (user === undefined) return <span />;

  return (
    <UserContext.Provider value={user}>
      <UserDispatchContext.Provider value={fetchUser}>
        <UserDispatchDestoryContext.Provider value={logOUt}>
          {children}
        </UserDispatchDestoryContext.Provider>
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};

export {
  UserProvider,
  UserContext,
  UserDispatchContext,
  UserDispatchDestoryContext,
};
