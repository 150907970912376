import React, { useRef, useEffect } from "react";
import * as echarts from "echarts";

const StackedBarChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    const option = {
      title: {
        text: "Actual and In Progress Spend to Goal",
        left: "center",
        top: "20",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (params) {
          let result = `${params[0].name}<br/>`;
          params.forEach((item) => {
            result += `${item.seriesName}: $${item.value}<br/>`;
          });
          return result;
        },
      },
      legend: {
        data: ["Admin", "H&S", "Project Ops", "Other", "T&TA"],
        bottom: "20",
      },
      xAxis: {
        type: "category",
        data: ["Actual Spend", "In Progress Spend"],
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "${value}",
        },
      },
      grid: {
        top: 60,
        left: 50,
        right: 50,
        bottom: 80,
      },
      series: [
        {
          name: "Admin",
          type: "bar",
          stack: "total",
          barWidth: "30%", // Adjusted bar width
          label: {
            show: true,
            position: "insideTop",
            formatter: "${c}",
          },
          itemStyle: {
            color: "#1f77b4",
          },
          data: [50, 50],
        },
        {
          name: "H&S",
          type: "bar",
          stack: "total",
          barWidth: "30%", // Adjusted bar width
          label: {
            show: true,
            position: "insideTop",
            formatter: "${c}",
          },
          itemStyle: {
            color: "#ff7f0e",
          },
          data: [100, 75],
        },
        {
          name: "Project Ops",
          type: "bar",
          stack: "total",
          barWidth: "30%", // Adjusted bar width
          label: {
            show: true,
            position: "insideTop",
            formatter: "${c}",
          },
          itemStyle: {
            color: "#2ca02c",
          },
          data: [300, 200],
        },
        {
          name: "Other",
          type: "bar",
          stack: "total",
          barWidth: "30%", // Adjusted bar width
          label: {
            show: true,
            position: "insideTop",
            formatter: "${c}",
          },
          itemStyle: {
            color: "rgb(21, 164, 223)",
          },
          data: [400, 300],
        },
        {
          name: "T&TA",
          type: "bar",
          stack: "total",
          barWidth: "30%", // Adjusted bar width
          label: {
            show: true,
            position: "insideTop",
            formatter: "${c}",
          },
          itemStyle: {
            color: "#9467bd",
          },
          data: [200, 50],
          markLine: {
            data: [
              {
                yAxis: 1200,
                name: "Goal",
                label: {
                  position: "insideEndTop",
                  formatter: "Goal: $1,200",
                  color: "#000",
                  fontWeight: "bold",
                },
                lineStyle: {
                  color: "green",
                  type: "dashed",
                },
              },
            ],
          },
        },
      ],
    };

    chartInstance.setOption(option);

    const resizeChart = () => {
      chartInstance.resize();
    };

    window.addEventListener("resize", resizeChart);

    return () => {
      chartInstance.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, []);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
};

export default StackedBarChart;
