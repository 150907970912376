import ProgramMFA from "./Programs/ProgramMFA/ProgramMFA";

const Wap = ({
  currentPageProperties,
  isFiltering,
  type,
  role,
  role_type,
  isLoading,
  properties,
  propertiesOffset,
  lastPageIndex,
  propertiesPerPage,
  showStatusPopover,
  selectedStatus,
  selectedOptionStatus,
  showClientPopover,
  selectedClient,
  selectedOptionClient,
  search,
  isMobileScreen,
  mdValue,
  handleSearch,
  handleSetSearch,
  setSelectedClient,
  setSelectedStatus,
  filterProperties,
  handlePropertiesPerPage,
  handlePageClick,
  handlePopoverVisibility,
  handlePopoverVisibilityOnBlur,
}) => {
  return (
    <>
      {role_type === "NM-MFA" ? (
        <ProgramMFA
          currentPageProperties={currentPageProperties}
          isFiltering={isFiltering}
          type={type}
          role={role}
          role_type={role_type}
          isLoading={isLoading}
          properties={properties}
          propertiesOffset={propertiesOffset}
          lastPageIndex={lastPageIndex}
          propertiesPerPage={propertiesPerPage}
          showStatusPopover={showStatusPopover}
          selectedStatus={selectedStatus}
          selectedOptionStatus={selectedOptionStatus}
          showClientPopover={showClientPopover}
          selectedClient={selectedClient}
          selectedOptionClient={selectedOptionClient}
          search={search}
          isMobileScreen={isMobileScreen}
          mdValue={mdValue}
          handleSearch={handleSearch}
          handleSetSearch={handleSetSearch}
          setSelectedClient={setSelectedClient}
          setSelectedStatus={setSelectedStatus}
          filterProperties={filterProperties}
          handlePropertiesPerPage={handlePropertiesPerPage}
          handlePageClick={handlePageClick}
          handlePopoverVisibility={handlePopoverVisibility}
          handlePopoverVisibilityOnBlur={handlePopoverVisibilityOnBlur}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <span className="fs-4 fw-bold text-secondary text-center">
            Dashboard Under Construction
          </span>
        </div>
      )}
    </>
  );
};

export default Wap;
