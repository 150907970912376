import * as React from "react";
import { Badge, Container, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { roundOfNumber } from "../../../../../../utils/RoundOfNumber";

const PropertyTable = ({ properties = [], isLoading = false, type = "" }) => {
  const projectStatus = (projectStatus = "") => {
    let renderedElement;
    switch (projectStatus) {
      case "Complete":
        renderedElement = (
          <Badge
            className="
          badge-color-primary"
            pill
          >
            {projectStatus}
          </Badge>
        );
        break;
      case "In Progress":
        renderedElement = (
          <Badge
            className="
          badge-color-sucess"
            pill
          >
            {projectStatus}
          </Badge>
        );
        break;
      case "Pipeline":
        renderedElement = (
          <Badge
            className="
        badge-color-warning"
            pill
          >
            {projectStatus}
          </Badge>
        );
        break;
      case "Closed - Testing Only":
        renderedElement = (
          <Badge
            className="
          badge-color-primary"
            pill
          >
            {projectStatus}
          </Badge>
        );
        break;
      case "Closed - Not Interested":
        renderedElement = (
          <Badge
            className="
          badge-color-sucess"
            pill
          >
            {projectStatus}
          </Badge>
        );
        break;
      case "Closed - Assessment Only":
        renderedElement = (
          <Badge
            className="
          badge-color-warning"
            pill
          >
            {projectStatus}
          </Badge>
        );
        break;
      case "Project on Hold":
        renderedElement = (
          <Badge
            className="
          badge-color-danger"
            pill
          >
            {projectStatus}
          </Badge>
        );
        break;
      case "Dropped":
        renderedElement = (
          <Badge bg="secondary" pill>
            {projectStatus}
          </Badge>
        );
        break;
      case "Reserved":
        renderedElement = (
          <Badge
            className="
          badge-color-danger"
            pill
          >
            {projectStatus}
          </Badge>
        );
        break;
      default:
        renderedElement = <span>{projectStatus}</span>;
    }

    return renderedElement;
  };

  return (
    <>
      {isLoading ? (
        <Container fluid className="p-3">
          <Row className="justify-content-md-center">
            <Spinner />
          </Row>
        </Container>
      ) : (
        <Table
          hover
          style={{
            backgroundColor: "white",
          }}
          className="small"
        >
          <thead>
            <tr>
              <th
                className="p-2 text-primary"
                style={{ backgroundColor: "#EBEBEB" }}
              >
                Project Name
              </th>
              <th
                className="p-2 text-primary"
                style={{ backgroundColor: "#EBEBEB" }}
              >
                Status
              </th>
              <th
                className="p-2 text-primary"
                style={{ backgroundColor: "#EBEBEB" }}
              >
                Client Name
              </th>
              <th
                className="p-2 text-primary"
                style={{ backgroundColor: "#EBEBEB" }}
              >
                Address
              </th>
              <th
                className="p-2 text-primary"
                style={{ backgroundColor: "#EBEBEB" }}
              >
                Number of Units
              </th>
              <th
                className="p-2 text-primary"
                style={{ backgroundColor: "#EBEBEB" }}
              >
                Income Eligible / Market Rate
              </th>
              {type !== "gas" && (
                <th
                  className="p-2 text-primary"
                  style={{ backgroundColor: "#EBEBEB" }}
                >
                  Project Start Date
                </th>
              )}
              <th
                className="p-2 text-primary"
                style={{ backgroundColor: "#EBEBEB" }}
              >
                Projected Completion Date
              </th>
              <th
                className="p-2 text-primary"
                style={{ backgroundColor: "#EBEBEB" }}
              >
                ACPU
              </th>
              <th
                className="p-2 text-primary"
                style={{ backgroundColor: "#EBEBEB" }}
              >
                Project Complete Date
              </th>
              <th
                className="p-2 text-primary"
                style={{ backgroundColor: "#EBEBEB" }}
              >
                kWh Deemed Savings
              </th>
              <th
                className="p-2 text-primary"
                style={{ backgroundColor: "#EBEBEB" }}
              >
                kW Deemed Savings
              </th>
              <th
                className="p-2 text-primary"
                style={{ backgroundColor: "#EBEBEB" }}
              >
                Rebate Type
              </th>
              <th
                className="p-2 text-primary"
                style={{ backgroundColor: "#EBEBEB" }}
              >
                {type === "gas" ? "Gas" : "Electric"} Rebate Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {properties &&
              properties?.map((property, key) => {
                const totalEcmCost = Array.isArray(property.ecm?.EcmCost)
                  ? property.ecm.EcmCost.reduce((acc, cost) => acc + cost, 0)
                  : 0;
                const numberOfRebateUnits = property.numberOfRebateUnites;
                let ecmCostPerUnit = numberOfRebateUnits
                  ? totalEcmCost / numberOfRebateUnits
                  : "0";
                ecmCostPerUnit = isNaN(ecmCostPerUnit) ? "0" : ecmCostPerUnit;
                return (
                  <tr key={key}>
                    <td
                      className="p-2"
                      style={{
                        backgroundColor: "#FBFAFA",
                        borderBottomColor: "white",
                        borderBottomWidth: 2,
                      }}
                    >
                      {" "}
                      <Link
                        to={`properties/${property.id}`}
                        className="text-dark text-decoration-none"
                      >
                        {property.name}
                      </Link>{" "}
                    </td>
                    <td className="p-2 text-dark">
                      {projectStatus(property.projectStatus)}
                    </td>
                    <td className="p-2 text-dark">
                      {property.clientName === "0" ? null : property.clientName}
                    </td>
                    <td className="p-2 text-dark">
                      {property.addressStreet}, {property.addressCity},{" "}
                      {property.addressState} {property.addressZip}
                    </td>
                    <td className="p-2 text-dark">
                      {property.numberOfRebateUnites}
                    </td>
                    <td className="p-2 text-dark">
                      {property.lowIncomeMartketRate}
                    </td>
                    {type !== "gas" && (
                      <td className="p-2 text-dark">
                        {property.dataverseCreatedDate
                          ? new Date(
                              property.dataverseCreatedDate
                            ).toLocaleDateString("en-US")
                          : null}
                      </td>
                    )}
                    <td className="p-2 text-dark">
                      {property.projectCompletionDate
                        ? new Date(
                            property.projectCompletionDate
                          ).toLocaleDateString("en-US")
                        : null}
                    </td>
                    <td className="p-2 text-dark">{ecmCostPerUnit}</td>
                    <td className="p-2 text-dark">
                      {property.projectCompleteDate
                        ? new Date(
                            property.projectCompleteDate
                          ).toLocaleDateString("en-US")
                        : null}
                    </td>
                    <td className="p-2 text-dark">
                      {roundOfNumber(property.ecm.kwhDeemedSavings, 0)}
                    </td>
                    <td className="p-2 text-dark">
                      {roundOfNumber(property.ecm.kwDeemedSavings, 0)}
                    </td>
                    <td className="p-2 text-dark">{property?.rebateType}</td>
                    <td className="p-2 text-dark">
                      {`$ ${roundOfNumber(
                        type === "gas"
                          ? property.ecm.gasRebateAmount
                          : property.ecm.electricRebateAmount,
                        0
                      )}`}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}
    </>
  );
};
export default PropertyTable;
