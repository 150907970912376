import { logOut } from "./BeeApi";

export function apiErrorHandler(error) {
  if (error.response) {
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/reset-password"
    ) {
      return Promise.reject({
        data: error.response.data,
        status: error.response.status,
        statusText: error.response.statusText,
      }).then(() => {
        logOut().then(() => {
          window.location.replace("/login");
        });
      });
    } else {
      return Promise.reject({
        data: error.response.data,
        status: error.response.status,
        statusText: error.response.statusText,
      });
    }
  } else if (error.request) {
    return Promise.reject({
      data: "",
      status: 0,
      statusText: "No Response",
    });
  } else {
    return Promise.reject({
      data: "",
      status: 0,
      statusText: error.message,
    });
  }
}
