import React from "react";
import { Row, Col } from "react-bootstrap";
import { roundOfNumber } from "../../../utils/RoundOfNumber";

const RenderContent = ({ property = "", value = "" }) => {
  return (
    <Row>
      <Col className="small reportFontSize">{property}</Col>
      <Col className="small reportFontSize">{value}</Col>
    </Row>
  );
};

const ReportContent = ({ property = {} }) => (
  <Row className="reportContent">
    <Col className="small col-2 reportFontSize">{property.name}</Col>
    <Col>
      <RenderContent
        property="Project Status:"
        value={property.projectStatus}
      />
      <RenderContent
        property={"Completion Date:"}
        value={new Date(property.projectCompletionDate).toLocaleDateString(
          "en-US"
        )}
      />
      <Row className="small reportFontSize">
        {`${property.addressStreet ?? ""}, ${property.addressCity ?? ""}, ${
          property.addressState ?? ""
        } ${property.addressZip ?? ""}`}
      </Row>
    </Col>
    <Col>
      <RenderContent
        property={"Rebate Completed:"}
        value={`$ ${roundOfNumber(property.ecm.electricRebateAmount)}`}
      />
      <RenderContent
        property={"Impacted Units:"}
        value={property.numberOfRebateUnites}
      />
      <RenderContent value={property.lowIncomeMartketRate} />
    </Col>
    <Col>
      <RenderContent
        property={"kWh Deemed Savings:"}
        value={`$ ${roundOfNumber(property.ecm.kwhDeemedSavings)}`}
      />
      <RenderContent
        property={"kW Deemed Savings:"}
        value={`$ ${roundOfNumber(property.ecm.kwDeemedSavings)}`}
      />
    </Col>
  </Row>
);

export default ReportContent;
