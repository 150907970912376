import React from "react";
import { Container } from "react-bootstrap";
import Header from "../App/Header/Header";
import Footer from "../App/Footer/Footer";
import PropertyList from "../Property/PropertyList/PropertyList";

const Dashboard = () => {
  return (
    <>
      <Header />
      <Container className="MainContent p-3">
        <PropertyList />
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
