import React from "react";
import { Row, Col, Image } from "react-bootstrap";

const ReportHeader = () => {
  return (
    <Row className="w-100">
      <Col className="pt-2">
        <Image
          src="https://ecard.icastusa.org/rocky.png"
          fluid
          height={150}
          width={150}
        />
      </Col>
      <Col className="pt-3">
        <h5 className="text-center">Monthly Report</h5>
      </Col>
      <Col className="text-end">
        <Image
          src="https://ecard.icastusa.org/icast_logo.png"
          fluid
          height={70}
          width={70}
        />
      </Col>
    </Row>
  );
};

export default ReportHeader;
