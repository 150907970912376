import React from "react";
import { Col, Row, Image, OverlayTrigger, Popover } from "react-bootstrap";
import Select from "react-select";
import ReportPreview from "../ReportPreview/ReportPreview";

const UtilityAndProperty = ({
  role,
  propertyMetrics,
  properties,
  kwhUrl,
  incentiveUrl,
  program,
  showYearPopover,
  handlePopoverVisibility,
  isYearDropdownOpen,
  setIsYearDropdownOpen,
  yearOptions,
  setSelectedYear,
  filterPropertiesByYear,
  handlePopoverVisibilityOnBlur,
  isMobileScreen,
  selectedYear,
  type,
}) => {
  return (
    <div>
      <Row className="mt-3">
        <Col>
          {role === "property_owner" ? (
            <span className="fs-4 fw-bold text-secondary">Welcome</span>
          ) : program?.name?.trim().toLowerCase() === "nm gas" ? (
            <Image src="/nm.png" fluid height={200} width="45%" />
          ) : (
            <Image src="/rocky.png" fluid height={150} width="35%" />
          )}
        </Col>
        <Col
          sm={12}
          md={7}
          className={`${
            isMobileScreen
              ? "d-flex justify-content-between mt-1"
              : "justify-content-md-end d-flex d-inline"
          }`}
        >
          {type === "gas" ? (
            <div className="me-3">
              <label className="text-center text-primary small fw-bolder mb-2">
                Select Historical Data
              </label>
              <div>
                <Select
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: "12px",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: "12px",
                    }),
                  }}
                  menuIsOpen={isYearDropdownOpen}
                  onMenuOpen={() => setIsYearDropdownOpen(true)}
                  onMenuClose={() => setIsYearDropdownOpen(false)}
                  closeMenuOnSelect={true}
                  defaultValue={yearOptions.find(
                    (option) => option.value === selectedYear
                  )}
                  onChange={(selectedOption) => {
                    setSelectedYear(selectedOption.value);
                    filterPropertiesByYear();
                  }}
                  options={yearOptions}
                  placeholder="Select Year..."
                  id="year"
                  className="basic-single me-3"
                  onBlur={handlePopoverVisibilityOnBlur}
                />
              </div>
            </div>
          ) : (
            <OverlayTrigger
              trigger="click"
              placement="top"
              show={showYearPopover}
              onToggle={() => handlePopoverVisibility("year")}
              overlay={
                <Popover id="popover-year">
                  <Popover.Body>
                    <small>Select Historical Data</small>
                  </Popover.Body>
                </Popover>
              }
            >
              <div>
                <Select
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: "12px",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: "12px",
                    }),
                  }}
                  menuIsOpen={isYearDropdownOpen}
                  onMenuOpen={() => setIsYearDropdownOpen(true)}
                  onMenuClose={() => setIsYearDropdownOpen(false)}
                  closeMenuOnSelect={true}
                  defaultValue={yearOptions.find(
                    (option) => option.value === selectedYear
                  )}
                  onChange={(selectedOption) => {
                    setSelectedYear(selectedOption.value);
                    filterPropertiesByYear();
                  }}
                  options={yearOptions}
                  placeholder="Select Year..."
                  id="year"
                  className="basic-single me-3"
                  onBlur={handlePopoverVisibilityOnBlur}
                />
              </div>
            </OverlayTrigger>
          )}

          <ReportPreview
            propertyMetrics={propertyMetrics}
            properties={properties}
            kwhUrl={kwhUrl}
            incentiveUrl={incentiveUrl}
            program={program}
          />
        </Col>
      </Row>
    </div>
  );
};

export default UtilityAndProperty;
