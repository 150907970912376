import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Alert,
  Form,
  InputGroup,
  Spinner,
  Card,
} from "react-bootstrap";

import { put } from "../../../utils/BeeApi";
import TextLogo from "../TextLogo/TextLogo";
import { useSearchParams } from "react-router-dom";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { UserContext } from "../../../contexts/UserProvider";
import { isValidEmail, isValidPassword } from "../../../utils/StringUtils";
import RedirectIfAuthenticated from "../RedirectIfAuthenticated/RedirectIfAuthenticated";

const ResetPassword = () => {
  const [error, setError] = useState(undefined);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const subscribedPromises = useRef([]);

  const user = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const validationToken = searchParams.get("verification");

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => promises.forEach((promise) => promise.cancel());
  }, []);

  useEffect(() => {
    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        navigate("/login", { state: { from: location } });
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const handleEmailChange = (event) => {
    let email = event.target.value;
    setEmail(email);
    setEmailValid(isValidEmail(email));
  };

  const handlePasswordChange = (event) => {
    let password = event.target.value;
    setPassword(password);
    setPasswordValid(isValidPassword(password));
  };

  const handleConfirmPasswordChange = (event) => {
    let confirmPassword = event.target.value;
    setConfirmPassword(confirmPassword);
    setConfirmPasswordValid(
      isValidPassword(confirmPassword) && confirmPassword === password
    );
  };

  const toggleVisiblePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailBlur = () => {
    setFormSubmitted(true);
  };

  const handlePasswordBlur = () => {
    setFormSubmitted(true);
  };

  const handleConfirmPasswordBlur = () => {
    setFormSubmitted(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);

    if (emailValid && passwordValid && confirmPasswordValid) {
      setError();
      setIsLoading(true);

      const createPromise = put("/user/anon/password", {
        userId: "anon",
        email: email,
        token: validationToken,
        password: password,
        password_confirmation: confirmPassword,
      });
      createPromise.promise
        .then(() => {
          setIsLoading(false);
          setSuccess(true);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });

      subscribedPromises.current.push(createPromise);
    }
  };

  if (user) return <RedirectIfAuthenticated isAuthenticated={user} />;

  const renderResetPasswordForm = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <div className="text-center">
          <h2 className="pre-title">Set Password</h2>
          <p>Enter your email and a new password for your account.</p>
        </div>
        <hr />
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
            isInvalid={!emailValid && formSubmitted}
            placeholder="Enter email"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            {formSubmitted &&
              !emailValid &&
              "This should be a valid email address"}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={showPassword ? "text" : "password"}
              value={password}
              onBlur={handlePasswordBlur}
              onChange={handlePasswordChange}
              isInvalid={!passwordValid && formSubmitted}
              placeholder="Password"
            />
            <Button
              variant="light"
              className="border-top border-end border-start pt-0 border-bottom"
              onClick={toggleVisiblePassword}
            >
              {showPassword ? (
                <span className="material-icons-outlined text-muted">
                  visibility_off
                </span>
              ) : (
                <span className="material-icons-outlined text-muted">
                  visibility
                </span>
              )}
            </Button>
            <Form.Control.Feedback type="invalid">
              {formSubmitted &&
                !passwordValid &&
                "Password must contain at least 6 characters, including UPPER/lowercase and numbers."}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3 text-input">
          <Form.Label>Confirm Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onBlur={handleConfirmPasswordBlur}
              onChange={handleConfirmPasswordChange}
              isInvalid={!confirmPasswordValid && formSubmitted}
              placeholder="Confirm Password"
            />
            <Button
              variant="light"
              className="border-top border-end border-start pt-0 border-bottom"
              onClick={toggleVisiblePassword}
            >
              {showPassword ? (
                <span className="material-icons-outlined text-muted">
                  visibility_off
                </span>
              ) : (
                <span className="material-icons-outlined text-muted">
                  visibility
                </span>
              )}
            </Button>
            <Form.Control.Feedback type="invalid">
              {formSubmitted &&
                !confirmPasswordValid &&
                "Confirm password must match with password"}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        {error &&
          ((error) => {
            if (error.status === 403) {
              return (
                <Alert variant="warning" className="mt-3 mb-3">
                  <p>
                    Sorry, your link to set a new password has expired or is
                    invalid. Please request for another link{" "}
                    <Link to="/login-help">here</Link>.
                  </p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}
        {success && (
          <Alert variant="success">
            <h4>Success!</h4>
            <p>You have successfully updated your password.</p>
          </Alert>
        )}
        {!success && (
          <div className="d-grid gap-2 mb-3">
            <Button
              className="mb-4"
              type="submit"
              color="primary"
              disabled={isLoading || !emailValid || !confirmPasswordValid}
            >
              {isLoading && (
                <Spinner
                  className="mr-2"
                  animation="border"
                  size="sm"
                  variant="light"
                />
              )}
              Continue
            </Button>
          </div>
        )}
        <p className="text-center">
          <Link to="/login">Cancel</Link>
        </p>
      </Form>
    );
  };

  return (
    <Container className="ResetPassword mt-3 mb-3">
      <Row>
        <Col
          xl={{ span: 4, offset: 4 }}
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          sm={{ span: 8, offset: 2 }}
        >
          <TextLogo />
          <Card className="shadow-sm">
            <Card.Body>{renderResetPasswordForm()}</Card.Body>
          </Card>
          <p className="text-center text-sm pt-2 text-muted">
            {" "}
            &copy; ICAST {new Date().getFullYear()}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
