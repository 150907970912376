import React, { useState, useRef, useEffect } from "react";
import { Formik } from "formik";
import {
  Form,
  Button,
  Alert,
  Container,
  Row,
  Col,
  Spinner,
  Modal,
} from "react-bootstrap";
import * as yup from "yup";
import Select from "react-select";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import { get, post } from "../../../../utils/DeApi";

const Icast = ({ email }) => {
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [roles, setRoles] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [clientNames, setClientNames] = useState([]);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showDashboardModal, setShowDashboardModal] = useState(false);

  useEffect(() => {
    const promises = subscribedPromises.current;
    fetchRolesAndPrograms();
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  const fetchRolesAndPrograms = () => {
    setError();

    const rolesAndProgramPromise = get("associate/utility/roles");

    rolesAndProgramPromise.promise
      .then((response) => {
        setRoles(response?.data?.roles);
        setPrograms(response?.data?.programs);
        setClientNames(response?.data?.client_name);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      });

    subscribedPromises.current.push(rolesAndProgramPromise);
  };

  const handleRoleChange = (selectedRole) => {
    if (selectedRole === "property_owner") {
      setFilteredPrograms(clientNames.filter((name) => name !== null));
    } else {
      const filtered = programs.filter(
        (program) => program.role_type === selectedRole
      );
      setFilteredPrograms(filtered);
    }
  };

  const handleProgramChange = (selectedProgramId) => {
    const selected = programs.find(
      (program) => program.id === selectedProgramId
    );
    setSelectedProgram(selected);
  };

  const IcastSchema = yup.object().shape({
    role: yup.string().required("Required"),
    program: yup.string().required("Required"),
  });

  const submitIcastData = (values, { setSubmitting }) => {
    setError();
    setShowSuccess(false);

    let payload = {
      role: values.role,
      email: email,
    };

    if (values.role === "property_owner") {
      payload = {
        ...payload,
        client_name: values.program,
      };
    } else {
      payload = {
        ...payload,
        utility_partner_id: selectedProgram?.id,
      };
    }

    const icastPromise = post("associate/utility/roles", payload);

    icastPromise.promise
      .then((response) => {
        setShowSuccess(true);
        setShowAlertModal(true);

        setTimeout(() => {
          setShowAlertModal(false);
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      })
      .finally(() => setSubmitting(false));

    subscribedPromises.current.push(icastPromise);
  };

  const handleSelectDashboard = () => {
    setShowDashboardModal(true);
  };

  const handleCloseModal = () => {
    setShowDashboardModal(false);
  };

  return (
    <Container fluid className="py-4">
      <Row className="mb-4">
        <Col className="d-flex justify-content-between align-items-center">
          <h6
            className="text-center text-primary fw-bolder mb-2"
            style={{ fontSize: "30px" }}
          >
            ICAST Team Dashboard
          </h6>
          <Button variant="outline-primary" onClick={handleSelectDashboard}>
            Switch Dashboard
          </Button>
        </Col>
      </Row>

      <Modal show={showDashboardModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Switch Dashboard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={IcastSchema}
            onSubmit={submitIcastData}
            initialValues={{
              role: "",
              program: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
              values,
              isValid,
              errors,
              isSubmitting,
            }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                className="p-4 shadow-sm rounded"
              >
                {error && <ErrorHandler error={error} />}
                <Form.Group controlId="role">
                  <Form.Label>Select a Role</Form.Label>
                  <Select
                    name="role"
                    options={roles
                      .filter((role) => role.name !== "Icast")
                      .map((role) => ({
                        value: role.value,
                        label: role.name,
                      }))}
                    onChange={(selectedOption) => {
                      handleChange({
                        target: { name: "role", value: selectedOption.value },
                      });
                      handleRoleChange(selectedOption.value);
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.role && !!errors.role}
                    classNamePrefix="custom-select"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.role}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="program" className="mt-3">
                  <Form.Label>Select a Program</Form.Label>
                  <Select
                    name="program"
                    options={filteredPrograms.map((programOrClient) => ({
                      value:
                        values.role === "property_owner"
                          ? programOrClient
                          : programOrClient.id,
                      label:
                        values.role === "property_owner"
                          ? programOrClient
                          : programOrClient.name,
                    }))}
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: "program",
                          value: selectedOption.value,
                        },
                      });
                      handleProgramChange(selectedOption.value);
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.program && !!errors.program}
                    classNamePrefix="custom-select"
                    isDisabled={!values.role}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      menu: (base) => ({
                        ...base,
                        maxHeight: "550px",
                        overflowY: "auto",
                      }),
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.program}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  type="submit"
                  className="mt-3 d-flex align-items-center"
                  disabled={!isValid || isSubmitting}
                  variant="primary"
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Updating...
                    </>
                  ) : (
                    "Update"
                  )}
                </Button>
                {showAlertModal && (
                  <Alert variant="success" className="text-center mt-4">
                    <p>Role Updated Successfully.</p>
                  </Alert>
                )}
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Icast;
