import React from "react";
import Header from "../App/Header/Header";
import Footer from "../App/Footer/Footer";
import { Container } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import PropertyDetails from "./PropertyDetails/PropertyDetails";

const Property = () => {
  return (
    <>
      <Header />
      <Container className="MainContent p-5">
        <Routes>
          <Route path="/:propertyId" element={<PropertyDetails />} />
        </Routes>
      </Container>
      <Footer />
    </>
  );
};

export default Property;
