import React from "react";
import { Row, Col, Image } from "react-bootstrap";

const ReportChart = ({ kwhUrl = "", incentiveUrl = "" }) => {
  return (
    <Row className="reportTable pt-5">
      <Col className="text-center">
        <Row className="mb-2">
          <h6 className="small text-decoration-underline">
            Program Status KPIs
          </h6>
        </Row>
        <Image src={kwhUrl} height={150} width={300} />
      </Col>
      <Col className="text-center">
        <Row className="mb-2">
          <h6 className="small text-decoration-underline">
            Income Eligible/Market Rate KPIs
          </h6>
        </Row>
        <Image src={incentiveUrl} height={150} width={300} />
      </Col>
    </Row>
  );
};

export default ReportChart;
