import React, { useState, useEffect } from "react";
import "./BarrierTable.scss";
const BarrierTable = ({ properties }) => {
  const [liheapTotal, setLiheapTotal] = useState(0);
  const [liheapTotalMold, setLiheapTotalMold] = useState(0);
  const [liheapTotalKnob, setLiheapTotalKnob] = useState(0);
  const [liheapTotalAsbestos, setLiheapTotalAsbestos] = useState(0);
  const [liheapTotalOther, setLiheapTotalOther] = useState(0);
  const [arpaTotal, setArpaTotal] = useState(0);
  const [arpaTotalMold, setArpaTotalMold] = useState(0);
  const [arpaTotalKnob, setArpaTotalKnob] = useState(0);
  const [arpaTotalAsbestos, setArpaTotalAsbestos] = useState(0);
  const [arpaTotalOther, setArpaTotalOther] = useState(0);
  const [totalProjectCosts, setTotalProjectCosts] = useState(0);
  const [totalProjectCostsMold, setTotalProjectCostsMold] = useState(0);
  const [totalProjectCostsKnob, setTotalProjectCostsKnob] = useState(0);
  const [totalProjectCostsAsbestos, setTotalProjectCostsAsbestos] = useState(0);
  const [totalProjectCostsOther, setTotalProjectCostsOther] = useState(0);
  const [totalNumberOfReferredVermi, setTotalNumberOfReferredVermi] =
    useState(0);
  const [totalNumberOfReferredMold, setTotalNumberOfReferredMold] = useState(0);
  const [totalNumberOfReferredKnob, setTotalNumberOfReferredKnob] = useState(0);
  const [totalNumberOfReferredAsbestos, setTotalNumberOfReferredAsbestos] =
    useState(0);
  const [totalNumberOfReferredOther, setTotalNumberOfReferredOther] =
    useState(0);
  const [totalNumberOfPipelineVermi, setTotalNumberOfPipelineVermi] =
    useState(0);
  const [totalNumberOfPipelineMold, setTotalNumberOfPipelineMold] = useState(0);
  const [totalNumberOfPipelineKnob, setTotalNumberOfPipelineKnob] = useState(0);
  const [totalNumberOfPipelineAsbestos, setTotalNumberOfPipelineAsbestos] =
    useState(0);
  const [totalNumberOfPipelineOther, setTotalNumberOfPipelineOther] =
    useState(0);

  const [totalNumberOfInProgressVermi, setTotalNumberOfInProgressVermi] =
    useState(0);
  const [totalNumberOfInProgressMold, setTotalNumberOfInProgressMold] =
    useState(0);
  const [totalNumberOfInProgressKnob, setTotalNumberOfInProgressKnob] =
    useState(0);
  const [totalNumberOfInProgressAsbestos, setTotalNumberOfInProgressAsbestos] =
    useState(0);
  const [totalNumberOfInProgressOther, setTotalNumberOfInProgressOther] =
    useState(0);

  const [totalNumberOfCompleteVermi, setTotalNumberOfCompleteVermi] =
    useState(0);
  const [totalNumberOfCompleteMold, setTotalNumberOfCompleteMold] = useState(0);
  const [totalNumberOfCompleteKnob, setTotalNumberOfCompleteKnob] = useState(0);
  const [totalNumberOfCompleteAsbestos, setTotalNumberOfCompleteAsbestos] =
    useState(0);
  const [totalNumberOfCompleteOther, setTotalNumberOfCompleteOther] =
    useState(0);

  const [totalNumberOfUnitsVermi, setTotalNumberOfUnitsVermi] = useState(0);
  const [totalNumberOfUnitsMold, setTotalNumberOfUnitsMold] = useState(0);
  const [totalNumberOfUnitsKnob, setTotalNumberOfUnitsKnob] = useState(0);
  const [totalNumberOfUnitsAsbestos, setTotalNumberOfUnitsAsbestos] =
    useState(0);
  const [totalNumberOfUnitsOther, setTotalNumberOfUnitsOther] = useState(0);

  useEffect(() => {
    // Calculate LIHEAP total
    const liheapTotalValueVermi = properties
      .filter(
        (property) =>
          property.liheapExpenditure && property.barrierType === "Vermiculite"
      )
      .map((property) => property.liheapExpenditure || 0)
      .reduce((a, b) => a + b, 0);

    setLiheapTotal(liheapTotalValueVermi);

    // Calculate ARPA total
    const arpaTotalValueVermi = properties
      .filter(
        (property) =>
          property.arpaExpenditure && property.barrierType === "Vermiculite"
      )
      .map((property) => property.arpaExpenditure || 0)
      .reduce((a, b) => a + b, 0);

    setArpaTotal(arpaTotalValueVermi);

    //Mold

    const liheapTotalValueMold = properties
      .filter(
        (property) =>
          property.liheapExpenditure &&
          property.barrierType === "Mold & Moisture"
      )
      .map((property) => property.liheapExpenditure || 0)
      .reduce((a, b) => a + b, 0);

    setLiheapTotalMold(liheapTotalValueMold);

    // Calculate ARPA total
    const arpaTotalValueMold = properties
      .filter(
        (property) =>
          property.arpaExpenditure && property.barrierType === "Mold & Moisture"
      )
      .map((property) => property.arpaExpenditure || 0)
      .reduce((a, b) => a + b, 0);

    setArpaTotalMold(arpaTotalValueMold);

    //knob

    const liheapTotalValueKnob = properties
      .filter(
        (property) =>
          property.liheapExpenditure && property.barrierType === "Knob & Tube"
      )
      .map((property) => property.liheapExpenditure || 0)
      .reduce((a, b) => a + b, 0);

    setLiheapTotalKnob(liheapTotalValueKnob);

    // Calculate ARPA total
    const arpaTotalValueKnob = properties
      .filter(
        (property) =>
          property.arpaExpenditure && property.barrierType === "Knob & Tube"
      )
      .map((property) => property.arpaExpenditure || 0)
      .reduce((a, b) => a + b, 0);

    setArpaTotalKnob(arpaTotalValueKnob);

    //Asbestos

    const liheapTotalValueAsbestos = properties
      .filter(
        (property) =>
          property.liheapExpenditure && property.barrierType === "Asbestos"
      )
      .map((property) => property.liheapExpenditure || 0)
      .reduce((a, b) => a + b, 0);

    setLiheapTotalAsbestos(liheapTotalValueAsbestos);

    // Calculate ARPA total
    const arpaTotalValueAsbestos = properties
      .filter(
        (property) =>
          property.arpaExpenditure && property.barrierType === "Asbestos"
      )
      .map((property) => property.arpaExpenditure || 0)
      .reduce((a, b) => a + b, 0);

    setArpaTotalAsbestos(arpaTotalValueAsbestos);

    //Other

    const liheapTotalValueOther = properties
      .filter(
        (property) =>
          property.liheapExpenditure && property.barrierType === "Other"
      )
      .map((property) => property.liheapExpenditure || 0)
      .reduce((a, b) => a + b, 0);

    setLiheapTotalOther(liheapTotalValueOther);

    // Calculate ARPA total
    const arpaTotalValueOther = properties
      .filter(
        (property) =>
          property.arpaExpenditure && property.barrierType === "Other"
      )
      .map((property) => property.arpaExpenditure || 0)
      .reduce((a, b) => a + b, 0);

    setArpaTotalOther(arpaTotalValueOther);

    // Calculate Total Allocation
    setTotalProjectCosts(liheapTotalValueVermi + arpaTotalValueVermi);
    setTotalProjectCostsMold(liheapTotalValueMold + arpaTotalValueMold);
    setTotalProjectCostsKnob(liheapTotalValueKnob + arpaTotalValueKnob);
    setTotalProjectCostsAsbestos(
      liheapTotalValueAsbestos + arpaTotalValueAsbestos
    );
    setTotalProjectCostsOther(liheapTotalValueOther + arpaTotalValueOther);

    //Referred Vermiculite
    const referredValueVermi = properties
      .filter(
        (property) =>
          property.projectStatus === "Referred" &&
          property.barrierType === "Vermiculite"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfReferredVermi(referredValueVermi);

    //Referred Mold
    const referredValueMold = properties
      .filter(
        (property) =>
          property.projectStatus === "Referred" &&
          property.barrierType === "Mold & Moisture"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfReferredMold(referredValueMold);

    //Referred Knob
    const referredValueKnob = properties
      .filter(
        (property) =>
          property.projectStatus === "Referred" &&
          property.barrierType === "Knob & Tube"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfReferredKnob(referredValueKnob);

    //Referred Asbestos
    const referredValueAsbestos = properties
      .filter(
        (property) =>
          property.projectStatus === "Referred" &&
          property.barrierType === "Asbestos"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfReferredAsbestos(referredValueAsbestos);

    //Referred Other
    const referredValueOther = properties
      .filter(
        (property) =>
          property.projectStatus === "Referred" &&
          property.barrierType === "Other"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfReferredOther(referredValueOther);

    //Complete Vermi
    const completeValueVermi = properties
      .filter(
        (property) =>
          property.projectStatus === "Complete" &&
          property.barrierType === "Vermiculite"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfCompleteVermi(completeValueVermi);

    //Complete Mold
    const completeValueMold = properties
      .filter(
        (property) =>
          property.projectStatus === "Complete" &&
          property.barrierType === "Mold & Moisture"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfCompleteMold(completeValueMold);

    //Complete Knob
    const completeValueKnob = properties
      .filter(
        (property) =>
          property.projectStatus === "Complete" &&
          property.barrierType === "Knob & Tube"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfCompleteKnob(completeValueKnob);

    //Complete Asbestos
    const completeValueAsbestos = properties
      .filter(
        (property) =>
          property.projectStatus === "Complete" &&
          property.barrierType === "Asbestos"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfCompleteAsbestos(completeValueAsbestos);

    //Complete Other
    const completeValueOther = properties
      .filter(
        (property) =>
          property.projectStatus === "Complete" &&
          property.barrierType === "Other"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfCompleteOther(completeValueOther);

    //Pipeline Vermi
    const pipelineValueVermi = properties
      .filter(
        (property) =>
          property.projectStatus === "Pipeline" &&
          property.barrierType === "Vermiculite"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfPipelineVermi(pipelineValueVermi);

    //Pipeline Mold

    const pipelineValueMold = properties
      .filter(
        (property) =>
          property.projectStatus === "Pipeline" &&
          property.barrierType === "Mold & Moisture"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfPipelineMold(pipelineValueMold);

    //Pipeline Knob

    const pipelineValueKnob = properties
      .filter(
        (property) =>
          property.projectStatus === "Pipeline" &&
          property.barrierType === "Knob & Tube"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfPipelineKnob(pipelineValueKnob);

    //Pipeline Asbestos

    const pipelineValueAsbestos = properties
      .filter(
        (property) =>
          property.projectStatus === "Pipeline" &&
          property.barrierType === "Asbestos"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfPipelineAsbestos(pipelineValueAsbestos);

    //Pipeline Other

    const pipelineValueOther = properties
      .filter(
        (property) =>
          property.projectStatus === "Pipeline" &&
          property.barrierType === "Other"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfPipelineOther(pipelineValueOther);

    //In Progress Vermiculite

    const InProgressValueVermi = properties
      .filter(
        (property) =>
          property.projectStatus === "In Progress" &&
          property.barrierType === "Vermiculite"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfInProgressVermi(InProgressValueVermi);

    //In Progress Mold

    const InProgressValueMold = properties
      .filter(
        (property) =>
          property.projectStatus === "In Progress" &&
          property.barrierType === "Mold & Moisture"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfInProgressMold(InProgressValueMold);

    //In Progress Knob

    const InProgressValueKnob = properties
      .filter(
        (property) =>
          property.projectStatus === "In Progress" &&
          property.barrierType === "Knob & Tube"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfInProgressKnob(InProgressValueKnob);

    //In Progress ASbestos
    const InProgressValueAsbestos = properties
      .filter(
        (property) =>
          property.projectStatus === "In Progress" &&
          property.barrierType === "Asbestos"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfInProgressAsbestos(InProgressValueAsbestos);

    //In Progress Other

    const InProgressValueOther = properties
      .filter(
        (property) =>
          property.projectStatus === "In Progress" &&
          property.barrierType === "Other"
      )
      .map((property) => parseInt(property.numberOfRebateUnites || 0))
      .reduce((a, b) => a + b, 0);

    setTotalNumberOfInProgressOther(InProgressValueOther);

    //Number Of Units Vermi

    setTotalNumberOfUnitsVermi(
      referredValueVermi +
        pipelineValueVermi +
        InProgressValueVermi +
        completeValueVermi
    );

    //Number Of Units Mold And Moisture

    setTotalNumberOfUnitsMold(
      referredValueMold +
        pipelineValueMold +
        InProgressValueMold +
        completeValueMold
    );

    //Number Of Units Knob & Tube

    setTotalNumberOfUnitsKnob(
      referredValueKnob +
        pipelineValueKnob +
        InProgressValueKnob +
        completeValueKnob
    );

    //Number Of Units Asbestos

    setTotalNumberOfUnitsAsbestos(
      referredValueAsbestos +
        pipelineValueAsbestos +
        InProgressValueAsbestos +
        completeValueAsbestos
    );

    setTotalNumberOfUnitsOther(
      referredValueOther +
        pipelineValueOther +
        InProgressValueOther +
        completeValueOther
    );
  }, [properties]);
  return (
    <>
      <div className="main-container">
        <div className="table-container">
          <div className="table-row header">
            <div className="table-cell main-heading">Vermiculite</div>
          </div>

          <div className="table-row">
            <div className="table-cell">LIHEAP Allocation</div>
            <div className="table-cell" id="liheap-total">
              {liheapTotal.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="table-row">
            <div className="table-cell">ARPA Allocation</div>
            <div className="table-cell" id="arpa-total">
              {arpaTotal.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          {/* <div className="table-row">
            <div className="table-cell">Total Allocation</div>
            <div className="table-cell">
              {totalProjectCosts.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div> */}

          <div className="table-row">
            <div className="table-cell">Pipeline</div>
            <div className="table-cell">{totalNumberOfPipelineVermi}</div>
          </div>

          <div className="table-row">
            <div className="table-cell">In Progress</div>
            <div className="table-cell">{totalNumberOfInProgressVermi}</div>
          </div>

          <div className="table-row">
            <div className="table-cell">Complete</div>
            <div className="table-cell">{totalNumberOfCompleteVermi}</div>
          </div>
        </div>

        <div className="table-container">
          <div className="table-row header">
            <div className="table-cell main-heading">Mold And Moisture</div>
          </div>

          <div className="table-row">
            <div className="table-cell">LIHEAP Allocation</div>
            <div className="table-cell">
              {liheapTotalMold.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="table-row">
            <div className="table-cell">ARPA Allocation</div>
            <div className="table-cell">
              {arpaTotalMold.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          {/* <div className="table-row">
            <div className="table-cell">Total Allocation</div>
            <div className="table-cell">
              {totalProjectCostsMold.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div> */}

          <div className="table-row">
            <div className="table-cell">Pipeline</div>
            <div className="table-cell">{totalNumberOfPipelineMold}</div>
          </div>

          <div className="table-row">
            <div className="table-cell">In Progress</div>
            <div className="table-cell">{totalNumberOfInProgressMold}</div>
          </div>

          <div className="table-row">
            <div className="table-cell">Complete</div>
            <div className="table-cell">{totalNumberOfCompleteMold}</div>
          </div>
        </div>

        <div className="table-container">
          <div className="table-row header">
            <div className="table-cell main-heading">Knob And Tube</div>
          </div>

          <div className="table-row">
            <div className="table-cell">LIHEAP Allocation</div>
            <div className="table-cell">
              {liheapTotalKnob.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="table-row">
            <div className="table-cell">ARPA Allocation</div>
            <div className="table-cell">
              {arpaTotalKnob.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          {/* <div className="table-row">
            <div className="table-cell">Total Allocation</div>
            <div className="table-cell">
              {totalProjectCostsKnob.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div> */}

          <div className="table-row">
            <div className="table-cell">Pipeline</div>
            <div className="table-cell">{totalNumberOfPipelineKnob}</div>
          </div>

          <div className="table-row">
            <div className="table-cell">In Progress</div>
            <div className="table-cell">{totalNumberOfInProgressKnob}</div>
          </div>

          <div className="table-row">
            <div className="table-cell">Complete</div>
            <div className="table-cell">{totalNumberOfCompleteKnob}</div>
          </div>
        </div>

        <div className="table-container">
          <div className="table-row header">
            <div className="table-cell main-heading">Asbestos</div>
          </div>

          <div className="table-row">
            <div className="table-cell">LIHEAP Allocation</div>
            <div className="table-cell">
              {liheapTotalAsbestos.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="table-row">
            <div className="table-cell">ARPA Allocation</div>
            <div className="table-cell">
              {arpaTotalAsbestos.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          {/* <div className="table-row">
            <div className="table-cell">Total Allocation</div>
            <div className="table-cell">
              {totalProjectCostsAsbestos.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div> */}

          <div className="table-row">
            <div className="table-cell">Pipeline</div>
            <div className="table-cell">{totalNumberOfPipelineAsbestos}</div>
          </div>

          <div className="table-row">
            <div className="table-cell">In Progress</div>
            <div className="table-cell">{totalNumberOfInProgressAsbestos}</div>
          </div>

          <div className="table-row">
            <div className="table-cell">Complete</div>
            <div className="table-cell">{totalNumberOfCompleteAsbestos}</div>
          </div>
        </div>

        <div className="table-container">
          <div className="table-row header">
            <div className="table-cell main-heading">Other</div>
          </div>

          <div className="table-row">
            <div className="table-cell">LIHEAP Allocation</div>
            <div className="table-cell">
              {liheapTotalOther.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="table-row">
            <div className="table-cell">ARPA Allocation</div>
            <div className="table-cell">
              {arpaTotalOther.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>

          {/* <div className="table-row">
            <div className="table-cell">Total Allocation</div>
            <div className="table-cell">
              {totalProjectCostsOther.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div> */}

          <div className="table-row">
            <div className="table-cell">Pipeline</div>
            <div className="table-cell">{totalNumberOfPipelineOther}</div>
          </div>

          <div className="table-row">
            <div className="table-cell">In Progress</div>
            <div className="table-cell">{totalNumberOfInProgressOther}</div>
          </div>

          <div className="table-row">
            <div className="table-cell">Complete</div>
            <div className="table-cell">{totalNumberOfCompleteOther}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BarrierTable;
