import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <div className="Footer bg-white text-primary mt-auto">
      <Container fluid className="text-center">
        <Row className="border-top">
          <Col xs={12} className="my-2">
            ECARD Portal <small>&copy; {new Date().getFullYear()}</small>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
