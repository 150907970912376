import React, { useEffect, useRef, useLayoutEffect } from "react";
import * as echarts from "echarts";

const formatCurrency = (value) => {
  return `$${parseInt(value).toLocaleString()}`;
};

const formatYAxisLabel = (value) => {
  return `${(value / 1e6).toFixed(0)}M`;
};

const formatValueLabel = (value) => {
  return `${(value / 1e6).toFixed(1)}M`;
};

const ARPHAChart = ({ properties, isLoading }) => {
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    const chart = echarts.init(chartRef.current);

    const updateChartSize = () => {
      chart.resize();
    };

    window.addEventListener("resize", updateChartSize);

    return () => {
      window.removeEventListener("resize", updateChartSize);
      chart.dispose();
    };
  }, []);

  useEffect(() => {
    if (!isLoading && properties && properties.length > 0) {
      const chart = echarts.getInstanceByDom(chartRef.current);

      const completeProjects = properties.filter(
        (item) => item.projectStatus === "Complete"
      );
      const inProgressProjects = properties.filter(
        (item) => item.projectStatus === "In Progress"
      );

      const totalExpenditureComplete = completeProjects.reduce(
        (total, item) => total + parseFloat(item.arpaExpenditure || 0),
        0
      );

      const totalExpenditureInProgress = inProgressProjects.reduce(
        (total, item) => total + parseFloat(item.arpaExpenditure || 0),
        0
      );

      const totalExpenditure =
        totalExpenditureComplete + totalExpenditureInProgress;

      const option = {
        title: {
          text: "ARPA Funds: Spend to Budget",
          left: "center",
          top: "20",
          textStyle: {
            color: "#000",
            fontSize: 20,
          },
          subtext: "July 22, 2022 - September 30, 2024",
          subtextStyle: {
            color: "#666",
            fontSize: 16,
            align: "center",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            let result = `${params.name}<br/>`;
            params.seriesData.forEach((item) => {
              result += `${item.seriesName}: ${formatCurrency(
                item.value
              )}<br/>`;
            });
            return result;
          },
        },
        legend: {
          data: ["Complete", "In Progress"],
          bottom: 20,
          left: "center",
        },
        xAxis: {
          type: "category",
          data: ["ARPA Expenditure"],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: formatYAxisLabel,
          },
          min: 0,
          max: 7e6,
          interval: 1e6,
          name: "The Expenditure of REPS Funds",
          nameLocation: "middle",
          nameGap: 50,
          nameTextStyle: {
            fontSize: 14,
            fontWeight: "bold",
            color: "#000",
          },
        },
        grid: {
          top: 80,
          left: 70,
          right: 70,
          bottom: 100,
        },
        series: [
          {
            name: "Complete",
            type: "bar",
            stack: "total",
            data: [totalExpenditureComplete],
            barWidth: "20%",
            itemStyle: {
              color: "purple",
            },
            label: {
              show: true,
              position: "insideTop",
              formatter: (params) => formatValueLabel(params.value),
            },
          },
          {
            name: "In Progress",
            type: "bar",
            stack: "total",
            data: [totalExpenditureInProgress],
            barWidth: "20%",
            itemStyle: {
              color: "lightblue",
            },
            label: {
              show: true,
              position: "insideTop",
              formatter: (params) => formatValueLabel(params.value),
            },
          },
          {
            name: "Goal",
            type: "line",
            data: [6.6e6],
            markLine: {
              data: [
                {
                  yAxis: 6.6e6,
                  name: "Goal",
                  label: {
                    position: "insideEndTop",
                    formatter: "Goal: $6,600,000",
                    color: "#000",
                    fontWeight: "bold",
                  },
                  lineStyle: {
                    color: "green",
                    type: "dashed",
                  },
                },
              ],
            },
          },
        ],
      };

      if (chart) {
        chart.setOption(option);
      }
    }
  }, [isLoading, properties]);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }}></div>;
};

export default ARPHAChart;
