import React from "react";
import { Spinner } from "react-bootstrap";
import "./Loader.scss";

const Loader = ({ size }) => {
  return (
    <div className={`Loader pt-5 pb-5 `}>
      <Spinner animation="border" variant="primary" size={size} />
    </div>
  );
};

export default Loader;
