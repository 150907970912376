import React from "react";
import {
  Col,
  Row,
  Table,
  Image,
  OverlayTrigger,
  Popover,
  Button,
  InputGroup,
  Form,
} from "react-bootstrap";
import StackedBarChart from "./Charts/StackedBarChart";
import ActualSpendByMonthChart from "./Charts/BarWapChart";
import BudgetData from "./DataModel/BudgetData.json";
import Select from "react-select";
import PropertyCard from "../../PropertyCard/PropertyCard";

const ProgramMFA = ({
  currentPageProperties,
  isFiltering,
  type,
  role,
  role_type,
  isLoading,
  properties,
  propertiesOffset,
  lastPageIndex,
  propertiesPerPage,
  showStatusPopover,
  selectedStatus,
  selectedOptionStatus,
  showClientPopover,
  selectedClient,
  selectedOptionClient,
  search,
  isMobileScreen,
  mdValue,
  handleSearch,
  handleSetSearch,
  setSelectedClient,
  setSelectedStatus,
  filterProperties,
  handlePropertiesPerPage,
  handlePageClick,
  handlePopoverVisibility,
  handlePopoverVisibilityOnBlur,
}) => {
  return (
    <>
      <div>
        <Row className="mt-3">
          <Col md={2} className="text-start">
            <Image
              src="/NM MFA.png"
              fluid
              style={{
                height: "135px",
                width: "130px",
                paddingBottom: "0px",
              }}
            />
          </Col>
          <Col md={8} className="text-center mt-4">
            <h2>
              <strong>WAP - Annual</strong>
            </h2>
          </Col>
        </Row>
      </div>
      <div className="mt-5">
        <Row>
          <Col md={12} xs={12}>
            <div className="mt-1 mb-3 border border-secondary mr-2">
              <StackedBarChart />
            </div>
          </Col>
          <Col md={12} xs={12}>
            <div className="mt-1 mb-3 border border-secondary mr-2">
              <ActualSpendByMonthChart />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table
              hover
              style={{
                backgroundColor: "white",
                borderCollapse: "collapse",
                border: "1px solid #ddd",
              }}
              className="small mt-4"
            >
              <thead>
                <tr>
                  <th
                    className="p-2 text-primary"
                    style={{
                      backgroundColor: "#EBEBEB",
                      border: "1px solid #ddd",
                    }}
                  >
                    Role
                  </th>
                  <th
                    className="p-2 text-primary"
                    style={{
                      backgroundColor: "#EBEBEB",
                      border: "1px solid #ddd",
                    }}
                  >
                    Budget Amount
                  </th>
                  <th
                    className="p-2 text-primary"
                    style={{
                      backgroundColor: "#EBEBEB",
                      border: "1px solid #ddd",
                    }}
                  >
                    Actual Spend
                  </th>
                  <th
                    className="p-2 text-primary"
                    style={{
                      backgroundColor: "#EBEBEB",
                      border: "1px solid #ddd",
                    }}
                  >
                    In Progress Spend
                  </th>
                </tr>
              </thead>
              <tbody>
                {BudgetData.map((data, index) => (
                  <tr key={index} style={{ border: "1px solid #ddd" }}>
                    <td style={{ border: "1px solid #ddd" }}>{data.role}</td>
                    <td style={{ border: "1px solid #ddd" }}>
                      {data.budgetAmount}
                    </td>
                    <td style={{ border: "1px solid #ddd" }}>
                      {data.actualSpend}
                    </td>
                    <td style={{ border: "1px solid #ddd" }}>
                      {data.inProgressSpend}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="justify-content-start my-4">
          <OverlayTrigger
            trigger="click"
            placement="top"
            show={showStatusPopover}
            onToggle={() => handlePopoverVisibility("status")}
            overlay={
              <Popover id="popover-basic">
                <Popover.Body>
                  <small>Select Multiple Status</small>
                </Popover.Body>
              </Popover>
            }
          >
            <Col xs={12} sm={6} md={2} className="mb-2">
              <Select
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  placeholder: (provided) => ({
                    ...provided,
                    fontSize: "12px",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    fontSize: "12px",
                  }),
                }}
                closeMenuOnSelect={false}
                value={selectedStatus}
                isMulti
                size="sm"
                options={selectedOptionStatus}
                onChange={(selected) => {
                  setSelectedStatus(selected);
                  filterProperties();
                }}
                className="basic-multi-select"
                placeholder="Select Status.."
                id="status"
                onBlur={handlePopoverVisibilityOnBlur}
              />
            </Col>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="click"
            placement="top"
            show={showClientPopover}
            onToggle={() => handlePopoverVisibility("client")}
            overlay={
              <Popover id="popover-basic">
                <Popover.Body>
                  <small>Select Multiple ClientName</small>
                </Popover.Body>
              </Popover>
            }
          >
            <Col xs={12} sm={6} md={2} className="mb-2">
              <Select
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  placeholder: (provided) => ({
                    ...provided,
                    fontSize: "12px",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    fontSize: "12px",
                  }),
                }}
                closeMenuOnSelect={false}
                value={selectedClient}
                isMulti
                size="sm"
                options={selectedOptionClient}
                onChange={(selected) => {
                  setSelectedClient(selected);
                  filterProperties();
                }}
                className="basic-multi-select"
                placeholder="Select ClientName.."
                id="status"
                onBlur={handlePopoverVisibilityOnBlur}
              />
            </Col>
          </OverlayTrigger>
          <Col
            xs={12}
            sm={12}
            md={mdValue}
            className={`${
              isMobileScreen ? "text-end" : "d-flex justify-content-end"
            }`}
          >
            <div className="d-flex justify-content-md-end">
              <InputGroup style={{ maxHeight: "3rem" }}>
                <Form.Control
                  type="search"
                  name="search"
                  value={search}
                  onChange={handleSetSearch}
                  placeholder={
                    role !== "utility_partner" && role !== "property_owner"
                      ? "Filter By Property Name"
                      : "Filter By Project Name"
                  }
                  size="sm"
                  onKeyDown={handleSearch}
                  style={{
                    height: `${isMobileScreen ? "100%" : "80%"}`,
                    width: "80%",
                  }}
                  className="placeholder-small-font-size"
                />
                <Button
                  style={{
                    height: `${isMobileScreen ? "100%" : "80%"}`,
                  }}
                  variant="primary"
                  size="sm"
                  onClick={handleSearch}
                  disabled={!search}
                >
                  <i className="material-icons-outlined">search</i>
                </Button>
              </InputGroup>
            </div>
          </Col>
          <PropertyCard
            currentPageProperties={currentPageProperties}
            isFiltering={isFiltering}
            type={type}
            role={role}
            role_type={role_type}
            isLoading={isLoading}
            properties={properties}
            propertiesOffset={propertiesOffset}
            lastPageIndex={lastPageIndex}
            propertiesPerPage={propertiesPerPage}
            handlePropertiesPerPage={handlePropertiesPerPage}
            handlePageClick={handlePageClick}
          />
        </Row>
      </div>
    </>
  );
};

export default ProgramMFA;
