import React, { useEffect, useState, useRef, useContext } from "react";
import { Row, Card, Col, Container, Form, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { get } from "../../../utils/DeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import BreadCrumbs from "../../App/BreadCrumbs/BreadCrumbs";
import { roundOfNumber } from "../../../utils/RoundOfNumber";
import { UserContext } from "../../../contexts/UserProvider";
import { constructiontems, newContructionItems } from "./CheckItems";
import FileUpload from "./fileUpload";
import Files from "./Files";
import Demographics from "./Demographics";

const PropertyDetails = () => {
  const { role, readOnly, program = {} } = useContext(UserContext);
  const { type } = program;

  const subscribedPromises = useRef([]);
  const { propertyId } = useParams();
  const [files, setFiles] = useState([]);

  const [error, setError] = useState(undefined);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [ecm, setEcm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [detailsList, setDetailsList] = useState([]);

  useEffect(() => {
    fetchPropertyDetails();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleSetEcm();
    hanldeCheckBoxStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyDetails]);

  const handleSetEcm = () => {
    if (propertyDetails) {
      setEcm(propertyDetails?.ecm);
    }
  };

  const hanldeCheckBoxStatus = () => {
    let tempCheckItems;

    if (propertyDetails.rebateType !== "New Construction") {
      tempCheckItems = constructiontems;
    }
    if (propertyDetails.rebateType === "New Construction") {
      tempCheckItems = constructiontems.concat(newContructionItems);
    }

    tempCheckItems.map((item) => {
      return propertyDetails.projectStatus === "Complete"
        ? (item.checked = true)
        : (item.checked = false);
    });

    setDetailsList(tempCheckItems);
  };

  const fetchPropertyDetails = () => {
    setIsLoading(true);
    setError();

    const propertyPromise = get(`reports/properties/${propertyId}`);

    propertyPromise.promise
      .then((response) => {
        setPropertyDetails(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(propertyPromise);
  };

  const RenderPropertyDetails = ({ property, value }) => {
    return (
      <Row className="py-3">
        <Col md={4} xs={12}>
          <h6 className="text-secondary px-2 small">{property}</h6>
        </Col>
        <Col
          md={{ span: 8, order: 3, offset: 0 }}
          xs={{ span: 12, order: 2, offset: 0 }}
        >
          <h6 className="text-end px-3 text-secondary small">{value}</h6>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <BreadCrumbs
        breadcrumbs={[
          { name: "Dashboard", link: "/" },
          {
            name: "Project Details",
            link: `/properties/${propertyId}`,
            active: true,
          },
        ]}
      />
      <>
        {isLoading && <Loader />}
        {error && <ErrorHandler error={error} />}

        {propertyDetails && !isLoading && (
          <Col>
            {role !== "utility_wap" && role !== "govt" ? (
              <Container className="mt-5 w-65">
                {role !== "wap" && (
                  <>
                    <h6 className="text-center text-primary small fw-bold">
                      Project Information
                    </h6>
                    <Card className="my-3 shadow-sm border-0">
                      <RenderPropertyDetails
                        property={"Client Name"}
                        value={propertyDetails.clientName}
                      />
                    </Card>
                    <Card className="my-3 shadow-sm border-0">
                      <RenderPropertyDetails
                        property={"Project Name"}
                        value={propertyDetails.name}
                      />
                    </Card>

                    <Card className="my-3 shadow-sm border-0">
                      <RenderPropertyDetails
                        property={"Property Address"}
                        value={`${propertyDetails.addressStreet},  ${propertyDetails.addressCity}, ${propertyDetails.addressState} ${propertyDetails.addressZip}`}
                      />
                    </Card>

                    <Card className="my-3 shadow-sm border-0">
                      <RenderPropertyDetails
                        property={"Number of Units"}
                        value={propertyDetails.numberOfRebateUnites}
                      />
                    </Card>

                    <Card className="my-3 shadow-sm border-0">
                      <RenderPropertyDetails
                        property={"Status"}
                        value={propertyDetails.projectStatus}
                      />
                    </Card>

                    <Card className="my-3 shadow-sm border-0">
                      <RenderPropertyDetails
                        property={"Income Eligible/Market Rate"}
                        value={propertyDetails.lowIncomeMartketRate}
                      />
                    </Card>

                    {type !== "gas" && (
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Project Type"}
                          value={propertyDetails.rebateType}
                        />
                      </Card>
                    )}

                    {propertyDetails?.projectCompleteDate ? (
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Completion Date"}
                          value={
                            propertyDetails.projectCompleteDate
                              ? new Date(
                                  propertyDetails.projectCompleteDate
                                ).toLocaleDateString("en-US")
                              : null
                          }
                        />
                      </Card>
                    ) : (
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Projected Completion Date"}
                          value={
                            propertyDetails.projectCompletionDate
                              ? new Date(
                                  propertyDetails.projectCompletionDate
                                ).toLocaleDateString("en-US")
                              : null
                          }
                        />
                      </Card>
                    )}
                  </>
                )}
                {role !== "wap" && (
                  <>
                    <h6 className="text-center text-primary small fw-bold mt-5">
                      Program Advisor
                    </h6>
                    <Card className="my-3 shadow-sm border-0">
                      <RenderPropertyDetails
                        property={"Name"}
                        value={propertyDetails?.contactName}
                      />
                    </Card>
                    <Card className="my-3 shadow-sm border-0">
                      <RenderPropertyDetails
                        property={"Email"}
                        value={propertyDetails?.contactEmail}
                      />
                    </Card>

                    <Card className="my-3 shadow-sm border-0">
                      <RenderPropertyDetails
                        property={"Phone Number"}
                        value={propertyDetails?.contactPhoneNumber}
                      />
                    </Card>
                  </>
                )}
                {role === "wap" && (
                  <>
                    <>
                      <h6
                        className="text-center text-primary small fw-bold"
                        style={{ fontStyle: "italic" }}
                      >
                        Project Information
                      </h6>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Property Name"}
                          value={propertyDetails.name}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Project Status"}
                          value={propertyDetails.projectStatus}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Total Number of Units Weatherized"}
                          value={propertyDetails.numberOfRebateUnites}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Utility Provider - Electric"}
                          value={propertyDetails.electricUtilityProvider}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Utility Provider – Gas"}
                          value={propertyDetails.gasUtilityProvider}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Auditor Name"}
                          value={propertyDetails.auditor}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Inspector Name"}
                          value={propertyDetails.inspector}
                        />
                      </Card>
                    </>

                    <>
                      <h6
                        className="text-center text-primary small fw-bold mt-5"
                        style={{ fontStyle: "italic" }}
                      >
                        Property Information
                      </h6>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Year Built"}
                          value={propertyDetails.yearBuilt}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Total Property GFA"}
                          value={propertyDetails.propertygfa}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Primary Heating Fuel"}
                          value={propertyDetails.typeOfHeating}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Heating Equipment"}
                          value={propertyDetails.heatingEquipment}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Dwelling Type"}
                          value={propertyDetails.dwellingType}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Cumulative SIR"}
                          value={propertyDetails.cumulativeSIR}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Historic Properties Affected"}
                          value={propertyDetails.historicPropertiesAffected}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Required Additional Review by SHPO"}
                          value={propertyDetails.requiredAdditionalReviewbySHPO}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Health & Safety Barriers"}
                          value={propertyDetails.barrierType}
                        />
                      </Card>
                    </>

                    <>
                      <Demographics propertyDetails={propertyDetails} />
                    </>
                  </>
                )}
                {ecm?.kwhDeemedSavings !== undefined &&
                ecm?.kwDeemedSavings !== undefined &&
                type === "electric" ? (
                  <>
                    <h6 className="text-center text-primary small fw-bold mt-5">
                      Estimated Annual Savings
                    </h6>
                    {type === "electric" && (
                      <>
                        <Card className="my-3 shadow-sm border-0">
                          <RenderPropertyDetails
                            property={"kWh Deemed Savings"}
                            value={roundOfNumber(ecm?.kwhDeemedSavings, 0)}
                          />{" "}
                        </Card>
                        <Card className="my-3 shadow-sm border-0">
                          <RenderPropertyDetails
                            property={"kW Deemed Savings"}
                            value={roundOfNumber(ecm?.kwDeemedSavings, 0)}
                          />
                        </Card>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
                <>
                  {role !== "property_owner" &&
                    role !== "wap" &&
                    propertyDetails.gasUtilityProvider !== "NM Gas" &&
                    propertyDetails.projectType !== "Trade Ally" &&
                    propertyDetails.rebateType !== "TA Retrofit" && (
                      <>
                        <h6 className="text-center text-primary small fw-bold mt-5">
                          Estimated Rebate Amount
                        </h6>
                        <Card className="my-3 shadow-sm border-0">
                          {type === "electric" && (
                            <RenderPropertyDetails
                              property={"Electric Rebate Amount"}
                              value={`$ ${roundOfNumber(
                                ecm?.electricRebateAmount,
                                0
                              )}`}
                            />
                          )}
                          {type === "gas" && (
                            <RenderPropertyDetails
                              property={"Gas Rebate Amount"}
                              value={`$ ${roundOfNumber(
                                ecm?.gasRebateAmount,
                                0
                              )}`}
                            />
                          )}
                        </Card>
                      </>
                    )}
                </>
                {type === "gas" && (
                  <>
                    <h6 className="text-center text-primary small fw-bold mt-5">
                      Estimate Energy Savings
                    </h6>
                    <Card className="my-3 shadow-sm border-0">
                      <RenderPropertyDetails
                        property={"Therm Savings"}
                        value={`$ ${roundOfNumber(
                          propertyDetails?.ecm?.thermDeemedSavings,
                          0
                        )}`}
                      />
                    </Card>
                  </>
                )}
                {(role === "property_owner" || role === "utility_partner") && (
                  <>
                    <div className="d-flex justify-content-between align-items-center mt-5">
                      <div className="text-center flex-grow-1">
                        <h6
                          className="text-primary small fw-bold"
                          style={{
                            marginLeft: "50px",
                          }}
                        >
                          Required Documentation
                        </h6>
                      </div>
                      <div className="text-end">
                        {!readOnly && (
                          <FileUpload
                            value={propertyDetails.id}
                            setFiles={setFiles}
                          />
                        )}
                      </div>
                    </div>
                    <Card className="my-3 p-2 shadow-sm border-0">
                      <div className="row p-2">
                        <Files
                          property={propertyDetails}
                          files={files}
                          setFiles={setFiles}
                        />
                      </div>
                    </Card>
                  </>
                )}
              </Container>
            ) : (
              <Container className="mt-5 w-65">
                <>
                  <h6
                    className="text-center text-primary small fw-bold"
                    style={{ fontStyle: "italic" }}
                  >
                    Project Information
                  </h6>
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"Property Name"}
                      value={propertyDetails.name}
                    />
                  </Card>
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"Project ID"}
                      value={propertyDetails.id}
                    />
                  </Card>
                  {role === "govt" && (
                    <Card className="my-3 shadow-sm border-0">
                      <RenderPropertyDetails
                        property={"Client Name"}
                        value={propertyDetails.clientName}
                      />
                    </Card>
                  )}
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"Barrier Type"}
                      value={propertyDetails.barrierType}
                    />
                  </Card>
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"Number of Units"}
                      value={propertyDetails.numberOfRebateUnites}
                    />
                  </Card>
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"Household Type"}
                      value={propertyDetails.houseHoldType}
                    />
                  </Card>
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"Geographic Location"}
                      value={
                        propertyDetails.geographicLocation
                          ? propertyDetails.geographicLocation.replace(/,/g, "")
                          : ""
                      }
                    />
                  </Card>
                  {role === "govt" && (
                    <>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={
                            "Designation of Environmental Justice Community"
                          }
                          value={
                            propertyDetails.ejCommunity === 0
                              ? "False"
                              : propertyDetails.ejCommunity === 1
                              ? "True"
                              : ""
                          }
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Designation of Energy Burden Community"}
                          value={
                            propertyDetails.ebCommunity === 0
                              ? "False"
                              : propertyDetails.ebCommunity === 1
                              ? "True"
                              : ""
                          }
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"ARPA Funds"}
                          value={`$${
                            !isNaN(propertyDetails.arpaExpenditure)
                              ? Number(
                                  propertyDetails.arpaExpenditure
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : "0"
                          }`}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"LIHEAP Funds"}
                          value={`$${
                            !isNaN(propertyDetails.liheapExpenditure)
                              ? Number(
                                  propertyDetails.liheapExpenditure
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : "0"
                          }`}
                        />
                      </Card>
                      <Card className="my-3 shadow-sm border-0">
                        <RenderPropertyDetails
                          property={"Heating Fuel Type"}
                          value={propertyDetails?.typeOfHeating}
                        />
                      </Card>
                    </>
                  )}
                </>

                <>
                  <h6
                    className="text-center text-primary small fw-bold mt-5"
                    style={{ fontStyle: "italic" }}
                  >
                    Project Status
                  </h6>
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"Status"}
                      value={propertyDetails?.projectStatus}
                    />
                  </Card>
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"Date Created"}
                      value={
                        propertyDetails?.dataverseCreatedDate
                          ? new Date(
                              propertyDetails.dataverseCreatedDate
                            ).toLocaleDateString("en-US")
                          : null
                      }
                    />
                  </Card>
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"CEAP Award Letter Status"}
                      value={propertyDetails?.ceapAwardStatus}
                    />
                  </Card>
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"Remediation Work Completed Date"}
                      value={
                        propertyDetails.remediationWorkCompleteDate
                          ? new Date(
                              propertyDetails.remediationWorkCompleteDate
                            ).toLocaleDateString("en-US")
                          : null
                      }
                    />
                  </Card>
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"Project Notes"}
                      value={propertyDetails?.projectNotes}
                    />
                  </Card>
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"Signed H&S Form"}
                      value={propertyDetails?.signedHSForm}
                    />
                  </Card>
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"Project Complete Date"}
                      value={
                        propertyDetails?.projectCompleteDate
                          ? new Date(
                              propertyDetails?.projectCompleteDate
                            ).toLocaleDateString("en-US")
                          : null
                      }
                    />
                  </Card>
                </>
                <>
                  <h6
                    className="text-center text-primary small fw-bold mt-5"
                    style={{ fontStyle: "italic" }}
                  >
                    Referral Information
                  </h6>

                  {role === "govt" && (
                    <Card className="my-3 shadow-sm border-0">
                      <RenderPropertyDetails
                        property={"Referral Source"}
                        value={propertyDetails.referralSource}
                      />
                    </Card>
                  )}
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"Energy Efficiency Measures"}
                      value={propertyDetails.eemList}
                    />
                  </Card>
                  <Card className="my-3 shadow-sm border-0">
                    <RenderPropertyDetails
                      property={"Weatherization Status"}
                      value={propertyDetails.weatherizationStatus}
                    />
                  </Card>
                  {role === "govt" && (
                    <>
                      <div className="d-flex justify-content-between align-items-center mt-5">
                        <div className="text-center flex-grow-1">
                          <h6
                            className="text-primary small fw-bold"
                            style={{
                              fontStyle: "italic",
                              marginLeft: "50px",
                            }}
                          >
                            Required Documentation
                          </h6>
                        </div>
                        <div className="text-end">
                          {!readOnly && (
                            <FileUpload
                              value={propertyDetails.id}
                              setFiles={setFiles}
                            />
                          )}
                        </div>
                      </div>

                      <Card className="my-3 p-2 shadow-sm border-0">
                        <div className="row p-2">
                          <Files
                            property={propertyDetails}
                            files={files}
                            setFiles={setFiles}
                          />
                        </div>
                      </Card>
                    </>
                  )}
                </>
              </Container>
            )}
          </Col>
        )}
      </>
    </>
  );
};

export default PropertyDetails;
