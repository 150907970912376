import React from "react";
import { Col, Row } from "react-bootstrap";
import ARPAChart from "../PropertyChart/ARPAChart";
import LIHEAPChart from "../PropertyChart/LIHEAPChart";
import PropertyChart from "../PropertyChart/PropertyChart";
import RadiusPieChart from "../PropertyChart/RadiusPieChart";
import ReferralChart from "../PropertyChart/ReferralChart";
import ReportChart from "../ReportChart";

const Charts = ({
  role,
  program,
  type,
  propertyMetrics,
  incentiveUrl,
  setIncentiveUrl,
  kwhUrl,
  setKwhUrl,
  closeStatus,
  setCloseStatus,
  community,
  setCommunity,
  barrierCount,
  setBarrierCount,
  daysInReportingStage,
  setDaysInReportingStage,
  properties,
  currentPageProperties,
  isLoading,
  isFiltering,
}) => {
  return (
    <div>
      <Row className="mt-3 mb-2">
        {role !== "govt" &&
          role !== "utility_wap" &&
          !(
            role === "utility_partner" &&
            program?.name?.trim().toLowerCase() === "nm gas"
          ) && (
            <>
              <Col md={6} xs={12} className="mt-1">
                <h6 className="text-center text-primary fw-bolder mb-4 text-decoration-underline">
                  Program Status (kWh)
                </h6>

                <PropertyChart
                  propertyMetrics={propertyMetrics}
                  onChartRendered={(kwhUrl) => setKwhUrl(kwhUrl)}
                  scope={1}
                />
              </Col>

              <Col md={6} xs={12} className="mt-1">
                <h6 className="text-center text-primary fw-bolder mb-4 text-decoration-underline">
                  Income Eligible/Market Rate (kWh)
                </h6>

                <PropertyChart
                  propertyMetrics={propertyMetrics}
                  onChartRendered={(incentiveUrl) =>
                    setIncentiveUrl(incentiveUrl)
                  }
                  scope={2}
                />
              </Col>
            </>
          )}
        {role === "utility_partner" &&
          program?.name?.trim().toLowerCase() === "nm gas" && (
            <>
              <Col
                md={6}
                xs={12}
                className="mt-1 mb-3 border border-secondary mr-2"
              >
                <h6
                  className="text-left text-black fw-bolder mb-4 mt-2"
                  style={{ fontSize: "13px" }}
                >
                  Gas Rebate Amount By Reporting Stage
                </h6>

                <PropertyChart
                  propertyMetrics={propertyMetrics}
                  onChartRendered={(kwhUrl) => setKwhUrl(kwhUrl)}
                  scope={6}
                />
              </Col>
              <Col
                md={6}
                xs={12}
                className="mt-1 mb-3 border border-secondary mr-2"
              >
                <h6
                  className="text-left text-black fw-bolder mb-4 mt-2"
                  style={{ fontSize: "13px" }}
                >
                  Total Therm Savings By Reporting Stage
                </h6>

                <PropertyChart
                  propertyMetrics={propertyMetrics}
                  onChartRendered={(kwhUrl) => setKwhUrl(kwhUrl)}
                  scope={9}
                />
              </Col>

              <Col
                md={6}
                xs={12}
                className="mt-1 mb-3 border border-secondary mr-2"
              >
                <h6
                  className="text-left text-black fw-bolder mb-4 mt-2"
                  style={{ fontSize: "13px" }}
                >
                  Low-Income Gas Rebate Amount By Reporting Stage
                </h6>

                <PropertyChart
                  properties={properties}
                  propertyMetrics={propertyMetrics}
                  onChartRendered={(kwhUrl) => setKwhUrl(kwhUrl)}
                  scope={8}
                />
              </Col>
              <Col
                md={6}
                xs={12}
                className="mt-1 mb-3 border border-secondary mr-2"
              >
                <h6
                  className="text-left text-black fw-bolder mb-4 mt-2"
                  style={{ fontSize: "13px" }}
                >
                  Market Rate Gas Rebate Amount By Reporting Stage
                </h6>

                <PropertyChart
                  properties={properties}
                  propertyMetrics={propertyMetrics}
                  onChartRendered={(kwhUrl) => setKwhUrl(kwhUrl)}
                  scope={7}
                />
              </Col>

              <Col
                md={6}
                xs={12}
                className="mt-1 mb-3 border border-secondary mr-2"
              >
                <h6
                  className="text-left text-black fw-bolder mb-4 mt-2"
                  style={{ fontSize: "13px" }}
                >
                  Low-Income Therm Savings By Reporting Stage
                </h6>

                <PropertyChart
                  properties={properties}
                  propertyMetrics={propertyMetrics}
                  onChartRendered={(kwhUrl) => setKwhUrl(kwhUrl)}
                  scope={11}
                />
              </Col>

              <Col
                md={6}
                xs={12}
                className="mt-1 mb-3 border border-secondary mr-2"
              >
                <h6
                  className="text-left text-black fw-bolder mb-4 mt-2"
                  style={{ fontSize: "13px" }}
                >
                  Market Rate Therm Savings By Reporting Stage
                </h6>

                <PropertyChart
                  properties={properties}
                  propertyMetrics={propertyMetrics}
                  onChartRendered={(kwhUrl) => setKwhUrl(kwhUrl)}
                  scope={10}
                />
              </Col>
            </>
          )}

        {role !== "utility_partner" &&
          role !== "property_owner" &&
          role !== "govt" && (
            <>
              <Col
                md={12}
                xs={12}
                className="mt-1 mb-3 border border-secondary mr-2"
              >
                <h6
                  className="text-left text-black fw-bolder mb-4 mt-2"
                  style={{ fontSize: "13px" }}
                >
                  Project Status
                </h6>

                <PropertyChart
                  propertyMetrics={propertyMetrics}
                  onChartRendered={(kwhUrl) => setKwhUrl(kwhUrl)}
                  scope={3}
                />
              </Col>
            </>
          )}

        {role === "govt" && (
          <>
            <Col
              md={12}
              xs={12}
              className="mt-1 mb-3 border border-secondary mr-2"
            >
              <h6
                className="text-left text-black fw-bolder mb-4 mt-2"
                style={{ fontSize: "13px" }}
              >
                Project Process Status
              </h6>

              <PropertyChart
                propertyMetrics={propertyMetrics}
                onChartRendered={(kwhUrl) => setKwhUrl(kwhUrl)}
                scope={4}
              />
            </Col>
            <Col
              md={12}
              xs={12}
              className="mt-1 mb-3 border border-secondary mr-2"
            >
              <h6
                className="text-left text-black fw-bolder mb-4 mt-2"
                style={{ fontSize: "13px" }}
              >
                Completed Projects Distribution Of Customers By Energy Burden
                and Environmental Justice Community
              </h6>

              <RadiusPieChart
                propertyMetrics={propertyMetrics}
                onChartRendered={(community) => setCommunity(community)}
              />
            </Col>
            <Col
              md={12}
              xs={12}
              className="mt-1 mb-3 border border-secondary mr-2"
            >
              <h6
                className="text-left text-black fw-bolder mb-4 mt-2"
                style={{ fontSize: "13px" }}
              >
                Barrier Count
              </h6>

              <PropertyChart
                propertyMetrics={propertyMetrics}
                onChartRendered={(barrierCount) =>
                  setBarrierCount(barrierCount)
                }
              />
            </Col>
            <Col md={6} xs={12} className="mt-1 mb-3 border border-secondary ">
              <ARPAChart properties={properties} isLoading={isFiltering} />
            </Col>
            <Col md={6} xs={12} className="mt-1 mb-3 border border-secondary ">
              <LIHEAPChart properties={properties} isLoading={isFiltering} />
            </Col>
            <Col md={12} xs={12} className="mt-1 mb-3 border border-secondary ">
              <h6
                className="text-left text-black fw-bolder mb-4 mt-2"
                style={{ fontSize: "13px" }}
              >
                ACPU Completed projects
              </h6>
              <ReferralChart properties={properties} isLoading={isFiltering} />
            </Col>

            <Col md={12} xs={12} className="mt-1 mb-3 border border-secondary ">
              <h6
                className="text-left text-black fw-bolder mb-4 mt-2"
                style={{ fontSize: "13px" }}
              >
                Average Days in Project Stage By Reporting Stage
              </h6>

              <ReportChart properties={properties} />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default Charts;
