import React, { useEffect, useState, useRef, useContext } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { upload, xsrfToken } from "../../../utils/DeApi";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import {
  Modal,
  Button,
  Form,
  Spinner,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { UserContext } from "../../../contexts/UserProvider";

const FileUpload = ({ value, setFiles }) => {
  const subscribedPromises = useRef([]);
  const { document_types } = useContext(UserContext);

  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);

  const postFile = (file, comment) => {
    if (!selectedDocumentType) {
      setError("Please select a document type.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("comment", comment);
    formData.append("propertyId", value);
    formData.append("XSRF-TOKEN", xsrfToken);
    formData.append("version", "httpOnly");
    formData.append("property_document_type_id", selectedDocumentType.id);

    const uploadFilePromise = upload("property-documents", formData);
    uploadFilePromise.promise
      .then((response) => {
        setFiles((prevFiles) => [...prevFiles, response.data]);
        setError();
        setIsLoading(false);
        setSuccess(true);

        setTimeout(() => {
          setShow(false);
          setSuccess(false);
        }, 5000);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(uploadFilePromise);
  };

  const uploadFile = async (files, comment) => {
    setIsLoading(true);
    setError();
    try {
      for (const file of Object.values(files)) {
        postFile(file, comment);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const schema = yup.object().shape({
    comment: yup.string(),
    files: yup.mixed().required("File is required"),
    documentType: yup.string().required("Document type is required"),
  });

  return (
    <>
      <OverlayTrigger placement="top" overlay={<Tooltip>Files Upload</Tooltip>}>
        <Button
          variant="outline-primary"
          onClick={() => {
            setShow(true);
            setError();
          }}
          className="border rounded-circle"
          style={{ padding: "15px 20px" }}
        >
          <span className="material-symbols-outlined">upload_file</span>
        </Button>
      </OverlayTrigger>
      <Modal
        show={show}
        size="lg"
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Files</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            const { files, comment } = values;
            uploadFile(files, comment);
          }}
          initialValues={{
            files: "",
            filename: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            errors,
            isValid,
          }) => (
            <>
              {success && (
                <Alert variant="primary" className="mx-3 my-5 font-bolder">
                  You have successfully uploaded the file(s)
                </Alert>
              )}

              {!success && (
                <Form onSubmit={handleSubmit}>
                  <Modal.Body>
                    <Form.Group controlId="documentType" className="mb-3">
                      <Form.Label>Select Document Type</Form.Label>
                      <div className="select-control">
                        <Form.Control
                          as="select"
                          name="documentType"
                          onChange={(event) => {
                            const selectedType = document_types?.find(
                              (type) =>
                                type.document_name === event.target.value
                            );
                            setSelectedDocumentType(selectedType);
                            setFieldValue("documentType", event.target.value);
                          }}
                          onBlur={handleBlur}
                          isValid={touched.documentType && !errors.documentType}
                          isInvalid={
                            touched.documentType && errors.documentType
                          }
                        >
                          <option value="">Select...</option>
                          {document_types?.map((type) => (
                            <option key={type.id} value={type.document_name}>
                              {type.document_name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {errors.documentType}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="files" className="mb-3">
                      <Form.Label>Upload File</Form.Label>
                      <Form.Control
                        type="file"
                        name="files"
                        onChange={(event) => {
                          const files = event.currentTarget.files;
                          setFieldValue("files", files);
                        }}
                        onBlur={handleBlur}
                        isValid={touched.files && !errors.files}
                        isInvalid={touched.files && errors.files}
                        accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                        multiple
                      />
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {errors.files}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="comment" className="mb-3">
                      <Form.Label>Comment</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        name="comment"
                        value={values.comment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={
                          touched.comment &&
                          !errors.comment &&
                          values.comment.trim() !== ""
                        }
                        isInvalid={
                          touched.comment &&
                          (errors.comment ||
                            (values.comment.trim() === "" &&
                              touched.comment &&
                              values.comment))
                        }
                      />
                      {touched.comment &&
                        (values.comment.trim() ||
                          values.comment.trim() === "") && (
                          <Form.Control.Feedback
                            className="d-block"
                            type="invalid"
                          >
                            {errors.comment}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    {error && <ErrorHandler error={error} />}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={() => setShow(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      disabled={!isValid || !values.files || isLoading}
                    >
                      {isLoading ? (
                        <Spinner
                          className="me-2"
                          animation="border"
                          size="sm"
                          variant="light"
                        />
                      ) : null}
                      Upload File(s)
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default FileUpload;
