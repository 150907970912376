const constructiontems = [
  {
    name: "Utility Bill",
    value: "UtilityAccountVerificationReceived",
    colSize: 6,
  },
  {
    name: "Unit Addresses or Meter Number Listing",
    value: "UnitListingReceived",
    colSize: 6,
  },
  {
    name: "Completed Incentive Application",
    value: "SignedApplication",
    colSize: 6,
  },
  {
    name: "Low-Income Verification (if applicable)",
    value: "LowIncomeLetterReceived",
    colSize: 6,
  },
  {
    name: "W9 (if applicable)",
    value: "W9Received",
    colSize: 6,
  },
  {
    name: " COO/Est. Completion Dates per Building",
    value: "CertificateOfOccupancyDate",
    colSize: 6,
    type: "dateTime",
  },
];

const newContructionItems = [
  {
    name: "Permit Plan Set: General Drawing Set, Architectural Drawing Set, Mechanical Drawing Set, Plumbing Drawing Set, Electrical Drawing Set",
    value: "BuildingPlansReceived",
    colSize: 12,
  },
  {
    name: "Please note that a “bid set” is provided at the beginning of a project and the “permit set” is provided after, once equipment selections are decided on.",
    value: "valueSet",
    type: "comment",
    colSize: 12,
  },
];

export { constructiontems, newContructionItems };
