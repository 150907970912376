import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import * as echarts from "echarts";

const ReportChart = ({ properties }) => {
  const [totalReportingDaysPipeline, setTotalReportingDaysPipeline] =
    useState(0);
  const [totalReportingDaysInProgress, setTotalReportingDaysInProgress] =
    useState(0);
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    const myChart = echarts.init(chartRef.current);

    const resizeChart = () => {
      myChart.resize();
    };

    window.addEventListener("resize", resizeChart);

    return () => {
      window.removeEventListener("resize", resizeChart);
      myChart.dispose();
    };
  }, []);

  useEffect(() => {
    const reportChartPipeline = calculateTotalReportingDays("Pipeline");
    setTotalReportingDaysPipeline(reportChartPipeline);

    const reportChartInProgress = calculateTotalReportingDays("In Progress");
    setTotalReportingDaysInProgress(reportChartInProgress);

    const myChart = echarts.init(chartRef.current);

    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: ["Reporting Days"],
      },
      xAxis: {
        type: "category",
        data: ["Pipeline", "In Progress"],
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (value) => value,
        },
      },
      series: [
        {
          name: "Reporting Days",
          data: [
            totalReportingDaysPipeline.toFixed(2),
            totalReportingDaysInProgress.toFixed(2),
          ],
          type: "bar",
          barWidth: "10%",
          itemStyle: {
            color: "rgb(108, 194, 74)",
          },
          label: {
            show: true,
            position: "top",
            formatter: (params) =>
              new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(params.value),
          },
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [properties, totalReportingDaysPipeline, totalReportingDaysInProgress]);

  const calculateTotalReportingDays = (status) => {
    const filteredProperties = properties.filter(
      (property) => property.projectStatus === status
    );
    const totalReportingDays = filteredProperties
      .map((property) => parseInt(property.daysInCurrentReportingStage || 0))
      .reduce((a, b) => a + b, 0);

    return totalReportingDays / filteredProperties.length;
  };

  return (
    <div ref={chartRef} style={{ height: "300px", width: "100%" }}>
      {/* Chart will be mounted here */}
    </div>
  );
};

export default ReportChart;
