import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const ReferralChart = ({ properties, isLoading }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!isLoading && properties && properties.length > 0) {
      const chart = echarts.init(chartRef.current);

      const filteredProperties = properties.filter(
        (property) => property.projectStatus === "Complete"
      );

      const groupedData = filteredProperties.reduce((acc, property) => {
        const referralType = property.referralType;
        const arpaExpenditure = parseFloat(property.arpaExpenditure) || 0;
        const liheapExpenditure = parseFloat(property.liheapExpenditure) || 0;
        if (!acc[referralType]) {
          acc[referralType] = {
            arpaExpenditure: arpaExpenditure,
            liheapExpenditure: liheapExpenditure,
            count: 1,
          };
        } else {
          acc[referralType].arpaExpenditure += arpaExpenditure;
          acc[referralType].liheapExpenditure += liheapExpenditure;
          acc[referralType].count += 1;
        }

        return acc;
      }, {});

      const averageExpenditures = Object.keys(groupedData).map(
        (referralType) => {
          const totalExpenditure =
            groupedData[referralType].arpaExpenditure +
            groupedData[referralType].liheapExpenditure;
          const averageExpenditure =
            totalExpenditure / groupedData[referralType].count;
          return {
            referralType,
            averageExpenditure,
          };
        }
      );

      const xAxisData = averageExpenditures.map((data) =>
        data.referralType === "HES-IE"
          ? "LIHEAP"
          : data.referralType === "WAP"
          ? "ARPA"
          : data.referralType
      );
      const yAxisData = averageExpenditures.map(
        (data) => data.averageExpenditure
      );

      const option = {
        title: {
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return `${params.seriesName} <br/>${
              params.name
            }: ${new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(params.value)}`;
          },
        },
        legend: {
          data: ["Total Expenditure Of REPS Funds"],
        },
        xAxis: {
          type: "category",
          data: xAxisData,
          axisLabel: {
            interval: 0,
            formatter: (value) => value,
            show: true,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: (value) =>
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(value),
          },
        },
        series: [
          {
            name: "Total Expenditure Of REPS Funds",
            type: "bar",
            data: yAxisData,
            barWidth: 20,
            itemStyle: {
              color: "rgb(108, 194, 74)",
            },
            label: {
              show: true,
              position: "top",
              formatter: (params) =>
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(params.value.toFixed(0)),
            },
          },
        ],
      };

      chart.setOption(option);

      window.addEventListener("resize", () => {
        chart.resize();
      });

      return () => {
        chart.dispose();
      };
    }
  }, [isLoading, properties]);

  return (
    <div
      ref={chartRef}
      style={{
        width: "85%",
        height: "300px",
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
      }}
    ></div>
  );
};

export default ReferralChart;
