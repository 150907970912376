import React, { useRef, useLayoutEffect, memo } from "react";
import * as echarts from "echarts";
import "./PropertyReport.scss";
import { roundOfNumber } from "../../../utils/RoundOfNumber";

const RadiusPieChart = (props) => {
  const { propertyMetrics = {}, onChartRendered } = props;
  const propertyRef = useRef(null);

  useLayoutEffect(() => {
    const customColors = ["#0072CE", "#6CC24A", "#F2A900", "#E4002b"];

    const additionalValue =
      parseFloat(propertyMetrics?.completed?.statusCount) -
      parseFloat(
        propertyMetrics?.completed?.energyBurdenCommunity +
          propertyMetrics?.completed?.envJusticeCommunity
      );

    const dataRendered = [
      {
        value: parseFloat(propertyMetrics?.completed?.energyBurdenCommunity),
        name: "Energy Burden",
        itemStyle: {
          color: customColors[0],
        },
      },
      {
        value: parseFloat(propertyMetrics?.completed?.envJusticeCommunity),
        name: "Environmental Justice",
        itemStyle: {
          color: customColors[1],
        },
      },
      {
        value: additionalValue,
        name: "None",
        itemStyle: {
          color: customColors[3], // Change color as needed
        },
      },
    ];

    const option = {
      tooltip: {
        trigger: "item",
        formatter({ value, percent, name }) {
          return `${name}: ${roundOfNumber(value, 0)} (${percent}%)`;
        },
      },
      legend: {
        top: "5%",
        left: "center",
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: ["40%", "60%"],
          avoidLabelOverlap: false,
          label: {
            show: true,
            formatter({ value, percent }) {
              return `${roundOfNumber(value, 0)}`;
            },
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 14,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: true,
          },
          data: dataRendered,
        },
      ],
    };

    const chart = echarts.init(propertyRef.current);
    chart.setOption(option, true);

    chart.on("finished", () => {
      let src = chart.getDataURL({
        pixelRatio: 2,
        backgroundColor: "#fff",
      });

      onChartRendered(src);
    });

    return () => {
      chart.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyRef, propertyMetrics]);

  return (
    <div className="PropertyReportChart row justify-content-center">
      <div className="property-report-chart-container" ref={propertyRef} />
    </div>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.propertyMetrics) ===
    JSON.stringify(nextProps.propertyMetrics)
  );
};

export default memo(RadiusPieChart, arePropsEqual);
