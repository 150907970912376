export function roundOfNumber(number, base = 2) {
  let value = number ?? 0;
  value = parseInt(value, 10);

  value = value.toFixed(base);
  const commmaSeparatedValue = value
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return commmaSeparatedValue;
}
