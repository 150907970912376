import React, { useRef, useEffect } from "react";
import * as echarts from "echarts";

const ActualSpendByMonthChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    const option = {
      title: {
        text: "Actual Spend By Month",
        left: "center",
        top: "20",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: "{b} <br/>${c}",
      },
      xAxis: {
        type: "category",
        data: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        axisLabel: {
          rotate: 45,
          interval: 0,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "${value}",
        },
      },
      grid: {
        top: 80,
        left: 50,
        right: 50,
        bottom: 80,
      },
      series: [
        {
          name: "Spend",
          type: "bar",
          data: [200, 500, 100, 300, 100, 500, 700, 100, 0, 0, 400, 300],
          label: {
            show: true,
            position: "top",
            formatter: "${c}",
          },
          itemStyle: {
            color: "#0072CE",
          },
          barWidth: 25,
        },
      ],
    };

    chartInstance.setOption(option);

    const resizeChart = () => {
      chartInstance.resize();
    };

    window.addEventListener("resize", resizeChart);

    return () => {
      chartInstance.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, []);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
};

export default ActualSpendByMonthChart;
