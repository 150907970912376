import {
  Row,
  Col,
  OverlayTrigger,
  Popover,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import PropertyCard from "./PropertyCard/PropertyCard";
import Select from "react-select";

const PropertyOwner = ({
  role,
  properties,
  handlePopoverVisibility,
  handlePopoverVisibilityOnBlur,
  isMobileScreen,
  type,
  showStatusPopover,
  selectedStatus,
  selectedOptionStatus,
  setSelectedStatus,
  filterProperties,
  showRebateTypePopover,
  selectedRebateType,
  selectedOptionRebateType,
  setSelectedRebateType,
  showLIMRPopover,
  selectedLIMR,
  selectedOptionLIMR,
  setSelectedLIMR,
  showDatePopover,
  selectedDate,
  selectedOptionDate,
  setSelectedDate,
  search,
  handleSetSearch,
  handleSearch,
  currentPageProperties,
  isFiltering,
  role_type,
  isLoading,
  propertiesOffset,
  lastPageIndex,
  propertiesPerPage,
  handlePropertiesPerPage,
  handlePageClick,
  mdValue,
}) => {
  return (
    <>
      <span className="fs-4 fw-bold text-secondary">Welcome</span>
      <Row className="justify-content-start my-4">
        <OverlayTrigger
          trigger="click"
          placement="top"
          show={showStatusPopover}
          onToggle={() => handlePopoverVisibility("status")}
          overlay={
            <Popover id="popover-basic">
              <Popover.Body>
                <small>Select Multiple Status</small>
              </Popover.Body>
            </Popover>
          }
        >
          <Col xs={12} sm={6} md={2} className="mb-2">
            <Select
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: "12px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontSize: "12px",
                }),
              }}
              closeMenuOnSelect={false}
              value={selectedStatus}
              isMulti
              size="sm"
              options={selectedOptionStatus}
              onChange={(selected) => {
                setSelectedStatus(selected);
                filterProperties();
              }}
              className="basic-multi-select"
              placeholder="Select Status.."
              id="status"
              onBlur={handlePopoverVisibilityOnBlur}
            />
          </Col>
        </OverlayTrigger>
        <OverlayTrigger
          trigger="click"
          placement="top"
          show={showRebateTypePopover}
          onToggle={() => handlePopoverVisibility("rebateType")}
          overlay={
            <Popover id="popover-basic">
              <Popover.Body>
                <small>Select Multiple Rebate Type </small>
              </Popover.Body>
            </Popover>
          }
        >
          <Col xs={12} sm={6} md={2} className="mb-2">
            <Select
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: "12px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontSize: "12px",
                }),
              }}
              closeMenuOnSelect={false}
              defaultValue={selectedRebateType}
              isMulti
              size="sm"
              options={selectedOptionRebateType}
              onChange={(selected) => {
                setSelectedRebateType(selected);
                filterProperties();
              }}
              className="basic-multi-select"
              placeholder="Select Rebate.."
              id="rebateType"
              onBlur={handlePopoverVisibilityOnBlur}
            />
          </Col>
        </OverlayTrigger>
        <OverlayTrigger
          trigger="click"
          placement="top"
          show={showLIMRPopover}
          onToggle={() => handlePopoverVisibility("limr")}
          overlay={
            <Popover id="popover-basic">
              <Popover.Body>
                <small>Select Multiple LI/MR</small>
              </Popover.Body>
            </Popover>
          }
        >
          <Col xs={12} sm={6} md={2} className="mb-2">
            <Select
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: "12px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontSize: "12px",
                }),
              }}
              closeMenuOnSelect={false}
              defaultValue={selectedLIMR}
              isMulti
              size="sm"
              options={selectedOptionLIMR}
              onChange={(selected) => {
                setSelectedLIMR(selected);
                filterProperties();
              }}
              className="basic-multi-select"
              placeholder="Select LI/MR"
              id="lowIncomeMartketRate"
              onBlur={handlePopoverVisibilityOnBlur}
            />
          </Col>
        </OverlayTrigger>
        <OverlayTrigger
          trigger="click"
          placement="top"
          show={showDatePopover}
          onToggle={() => handlePopoverVisibility("date")}
          overlay={
            <Popover id="popover-basic">
              <Popover.Body>
                <small>Select Multiple Date</small>
              </Popover.Body>
            </Popover>
          }
        >
          <Col xs={12} sm={6} md={2} className="mb-2">
            <Select
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: "12px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontSize: "12px",
                }),
              }}
              closeMenuOnSelect={false}
              defaultValue={selectedDate}
              isMulti
              size="sm"
              options={selectedOptionDate}
              onChange={(selected) => {
                setSelectedDate(selected);
                filterProperties();
              }}
              className="basic-multi-select"
              placeholder="Select Complete Date.."
              id="date"
              onBlur={handlePopoverVisibilityOnBlur}
            />
          </Col>
        </OverlayTrigger>
        <Col
          xs={12}
          sm={12}
          md={mdValue}
          className={`${
            isMobileScreen ? "text-end" : "d-flex justify-content-end"
          }`}
        >
          <div className="d-flex justify-content-md-end">
            <InputGroup style={{ maxHeight: "3rem" }}>
              <Form.Control
                type="search"
                name="search"
                value={search}
                onChange={handleSetSearch}
                placeholder={
                  role !== "utility_partner" && role !== "property_owner"
                    ? "Filter By Property Name"
                    : "Filter By Project Name"
                }
                size="sm"
                onKeyDown={handleSearch}
                style={{
                  height: `${isMobileScreen ? "100%" : "80%"}`,
                  width: "80%",
                }}
                className="placeholder-small-font-size"
              />
              <Button
                style={{
                  height: `${isMobileScreen ? "100%" : "80%"}`,
                }}
                variant="primary"
                size="sm"
                onClick={handleSearch}
                disabled={!search}
              >
                <i className="material-icons-outlined">search</i>
              </Button>
            </InputGroup>
          </div>
        </Col>
      </Row>
      <PropertyCard
        currentPageProperties={currentPageProperties}
        isFiltering={isFiltering}
        type={type}
        role={role}
        role_type={role_type}
        isLoading={isLoading}
        properties={properties}
        propertiesOffset={propertiesOffset}
        lastPageIndex={lastPageIndex}
        propertiesPerPage={propertiesPerPage}
        handlePropertiesPerPage={handlePropertiesPerPage}
        handlePageClick={handlePageClick}
      />
    </>
  );
};

export default PropertyOwner;
