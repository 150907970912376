import React, { useRef, useLayoutEffect, memo } from "react";
import * as echarts from "echarts";
import "./PropertyReport.scss";
import { roundOfNumber } from "../../../utils/RoundOfNumber";

const PropertyChart = (props) => {
  const { propertyMetrics = {}, onChartRendered, scope, properties } = props;
  const propertyRef = useRef(null);

  const statuses = [
    "In Progress",
    "Reserved",
    "Pipeline",
    "Complete",
    "Closed - Testing Only",
    "Closed - Not Interested",
    "Closed - Assessment Only",
    "Project on Hold",
  ];

  const responseData =
    properties && properties.filter((property) => property.ecm);

  function calculateGasRebateSumForStatusAndMarketRate(status, marketRate) {
    const filteredData =
      responseData &&
      responseData.filter(
        (property) =>
          property.lowIncomeMartketRate === marketRate &&
          property.ecm &&
          property.projectStatus === status
      );

    const gasRebateSum = filteredData
      ? sumValues(
          filteredData.map((property) => property.ecm.gasRebateAmount || 0)
        )
      : 0;

    return gasRebateSum;
  }

  function calculateThermDeemedSavingsSumForStatusAndMarketRate(
    status,
    marketRate
  ) {
    const filteredData =
      responseData &&
      responseData.filter(
        (property) =>
          property.lowIncomeMartketRate === marketRate &&
          property.ecm &&
          property.projectStatus === status
      );

    const thermDeemedSavingsSum = filteredData
      ? sumValues(
          filteredData.map((property) => property.ecm.thermDeemedSavings || 0)
        )
      : 0;

    return thermDeemedSavingsSum;
  }

  const gasRebateSumForStatusAndMarketRate = {};
  const gasRebateSumForStatusAndMarketIncomeRate = {};
  const thermDeemedSavingsSumForStatusAndMarketRate = {};
  const thermDeemedSavingsSumForStatusAndMarketIncomeRate = {};

  statuses.forEach((status) => {
    gasRebateSumForStatusAndMarketRate[
      status.toLowerCase().replace(/\s+/g, "_")
    ] = calculateGasRebateSumForStatusAndMarketRate(status, "Market Rate");

    gasRebateSumForStatusAndMarketIncomeRate[
      status.toLowerCase().replace(/\s+/g, "_")
    ] = calculateGasRebateSumForStatusAndMarketRate(status, "Income Eligible");

    thermDeemedSavingsSumForStatusAndMarketRate[
      status.toLowerCase().replace(/\s+/g, "_")
    ] = calculateThermDeemedSavingsSumForStatusAndMarketRate(
      status,
      "Market Rate"
    );

    thermDeemedSavingsSumForStatusAndMarketIncomeRate[
      status.toLowerCase().replace(/\s+/g, "_")
    ] = calculateThermDeemedSavingsSumForStatusAndMarketRate(
      status,
      "Income Eligible"
    );
  });

  function sumValues(values) {
    return values.reduce((acc, curr) => acc + curr, 0);
  }

  const totalStatusCount =
    parseFloat(propertyMetrics?.ClosedAssessmentOnly?.statusCount || 0) +
    parseFloat(propertyMetrics?.ClosedNotInterested?.statusCount || 0) +
    parseFloat(propertyMetrics?.ClosedTestingOnly?.statusCount || 0);

  useLayoutEffect(() => {
    const customColors = [
      "#0072CE",
      "#6CC24A",
      "#F2A900",
      "#E4002b",
      "#8A2BE2",
      "#6b7280",
    ];

    const dataRendered =
      scope === 1
        ? [
            {
              value: parseFloat(
                propertyMetrics?.completed?.kwhDeemedSavings ??
                  propertyMetrics?.completed?.gasRebateAmount.toFixed(2)
              ),
              name: "Complete",
              itemStyle: {
                color: customColors[0],
              },
            },
            {
              value: parseFloat(
                propertyMetrics?.in_progress?.kwhDeemedSavings ??
                  propertyMetrics?.in_progress?.gasRebateAmount.toFixed(2)
              ),
              name: "In Progress",
              itemStyle: {
                color: customColors[1],
              },
            },
            {
              value: parseFloat(
                propertyMetrics?.pipeline?.kwhDeemedSavings ??
                  propertyMetrics?.pipeline?.gasRebateAmount.toFixed(2)
              ),
              name: "Pipeline",
              itemStyle: {
                color: customColors[2],
              },
            },
            {
              value: parseFloat(
                propertyMetrics?.reserved?.kwhDeemedSavings ??
                  propertyMetrics?.reserved?.gasRebateAmount.toFixed(2)
              ),
              name: "Reserved",
              itemStyle: {
                color: customColors[3],
              },
            },
          ]
        : scope === 2
        ? [
            {
              value: parseFloat(
                propertyMetrics?.income_eligible?.kwhDeemedSavings ??
                  propertyMetrics?.income_eligible?.gasRebateAmount.toFixed(2)
              ),
              name: "Income Eligible",
              itemStyle: {
                color: customColors[0],
              },
            },
            {
              value: parseFloat(
                propertyMetrics?.market_rate?.kwhDeemedSavings ??
                  propertyMetrics?.market_rate?.gasRebateAmount.toFixed(2)
              ),
              name: "Market Rate",
              itemStyle: {
                color: customColors[1],
              },
            },
          ]
        : scope === 3
        ? [
            {
              value: parseFloat(propertyMetrics?.pipeline?.statusCount),
              name: "Pipeline",
              itemStyle: {
                color: customColors[2],
              },
            },
            {
              value: parseFloat(propertyMetrics?.completed?.statusCount),
              name: "Complete",
              itemStyle: {
                color: customColors[0],
              },
            },
            {
              value: parseFloat(propertyMetrics?.in_progress?.statusCount),
              name: "In Progress",
              itemStyle: {
                color: customColors[1],
              },
            },
            {
              value: totalStatusCount,
              name: "Closed",
              itemStyle: {
                color: customColors[3],
              },
            },
          ]
        : scope === 4
        ? [
            {
              value: parseFloat(propertyMetrics?.pipeline?.statusCount),
              name: "Pipeline",
              itemStyle: {
                color: customColors[2],
              },
            },
            {
              value: parseFloat(propertyMetrics?.completed?.statusCount),
              name: "Complete",
              itemStyle: {
                color: customColors[0],
              },
            },
            {
              value: parseFloat(propertyMetrics?.in_progress?.statusCount),
              name: "In Progress",
              itemStyle: {
                color: customColors[1],
              },
            },
            {
              value: totalStatusCount,
              name: "Closed",
              itemStyle: {
                color: customColors[3],
              },
            },
          ]
        : scope === 6
        ? [
            {
              value: parseFloat(
                propertyMetrics?.completed?.kwhDeemedSavings ??
                  propertyMetrics?.completed?.gasRebateAmount.toFixed(2)
              ),
              name: "Complete",
              itemStyle: {
                color: customColors[0],
              },
            },
            {
              value: parseFloat(
                propertyMetrics?.in_progress?.kwhDeemedSavings ??
                  propertyMetrics?.in_progress?.gasRebateAmount.toFixed(2)
              ),
              name: "In Progress",
              itemStyle: {
                color: customColors[1],
              },
            },

            {
              value: parseFloat(
                propertyMetrics?.reserved?.kwhDeemedSavings ??
                  propertyMetrics?.reserved?.gasRebateAmount.toFixed(2)
              ),
              name: "Reserved",
              itemStyle: {
                color: customColors[3],
              },
            },
          ]
        : scope === 7
        ? [
            {
              value: parseFloat(
                gasRebateSumForStatusAndMarketRate?.complete?.toFixed(2)
              ),
              name: "Complete",
              itemStyle: {
                color: customColors[0],
              },
            },
            // {
            //   value: parseFloat(
            //     gasRebateSumForStatusAndMarketRate?.in_progress?.toFixed(2)
            //   ),
            //   name: "In Progress",
            //   itemStyle: {
            //     color: customColors[1],
            //   },
            // },

            {
              value: parseFloat(
                gasRebateSumForStatusAndMarketRate?.reserved?.toFixed(2)
              ),
              name: "Reserved",
              itemStyle: {
                color: customColors[3],
              },
            },
          ]
        : scope === 8
        ? [
            {
              value: parseFloat(
                gasRebateSumForStatusAndMarketIncomeRate?.complete?.toFixed(2)
              ),
              name: "Complete",
              itemStyle: {
                color: customColors[0],
              },
            },
            {
              value: parseFloat(
                gasRebateSumForStatusAndMarketIncomeRate?.in_progress?.toFixed(
                  2
                )
              ),
              name: "In Progress",
              itemStyle: {
                color: customColors[1],
              },
            },

            {
              value: parseFloat(
                gasRebateSumForStatusAndMarketIncomeRate?.reserved?.toFixed(2)
              ),
              name: "Reserved",
              itemStyle: {
                color: customColors[3],
              },
            },
          ]
        : scope === 9
        ? [
            {
              value: parseFloat(propertyMetrics?.completed?.thermDeemedSavings),
              name: "Complete",
              itemStyle: {
                color: customColors[0],
              },
            },
            {
              value: parseFloat(
                propertyMetrics?.in_progress?.thermDeemedSavings
              ),
              name: "In Progress",
              itemStyle: {
                color: customColors[1],
              },
            },

            {
              value: parseFloat(propertyMetrics?.reserved?.thermDeemedSavings),
              name: "Reserved",
              itemStyle: {
                color: customColors[3],
              },
            },
          ]
        : scope === 10
        ? [
            {
              value: parseFloat(
                thermDeemedSavingsSumForStatusAndMarketRate?.complete?.toFixed(
                  2
                )
              ),
              name: "Complete",
              itemStyle: {
                color: customColors[0],
              },
            },
            // {
            //   value: parseFloat(
            //     thermDeemedSavingsSumForStatusAndMarketRate?.in_progress?.toFixed(
            //       2
            //     )
            //   ),
            //   name: "In Progress",
            //   itemStyle: {
            //     color: customColors[1],
            //   },
            // },

            {
              value: parseFloat(
                thermDeemedSavingsSumForStatusAndMarketRate?.reserved?.toFixed(
                  2
                )
              ),
              name: "Reserved",
              itemStyle: {
                color: customColors[3],
              },
            },
          ]
        : scope === 11
        ? [
            {
              value: parseFloat(
                thermDeemedSavingsSumForStatusAndMarketIncomeRate?.complete?.toFixed(
                  2
                )
              ),
              name: "Complete",
              itemStyle: {
                color: customColors[0],
              },
            },
            {
              value: parseFloat(
                thermDeemedSavingsSumForStatusAndMarketIncomeRate?.in_progress?.toFixed(
                  2
                )
              ),
              name: "In Progress",
              itemStyle: {
                color: customColors[1],
              },
            },

            {
              value: parseFloat(
                thermDeemedSavingsSumForStatusAndMarketIncomeRate?.reserved?.toFixed(
                  2
                )
              ),
              name: "Reserved",
              itemStyle: {
                color: customColors[3],
              },
            },
          ]
        : [
            {
              value: parseFloat(propertyMetrics?.total?.knobTubeCount),
              name: "Knob & Tube",
              itemStyle: {
                color: customColors[0],
              },
            },
            {
              value: parseFloat(propertyMetrics?.total?.moldMoisutreCount),
              name: "Mold & Moisture",
              itemStyle: {
                color: customColors[1],
              },
            },
            {
              value: parseFloat(propertyMetrics?.total?.vermiculiteCount),
              name: "Vermiculite",
              itemStyle: {
                color: customColors[2],
              },
            },
            {
              value: parseFloat(propertyMetrics?.total?.asbestosCount),
              name: "Asbestos",
              itemStyle: {
                color: customColors[3],
              },
            },
            {
              value: parseFloat(propertyMetrics?.total?.otherBarrierCount),
              name: "Other Barriers",
              itemStyle: {
                color: customColors[4],
              },
            },
          ];
    const option = {
      tooltip: {
        trigger: "item",
        formatter({ value, percent }) {
          if (scope >= 6 && scope <= 11) {
            return `$${roundOfNumber(value, 0)}`;
          } else {
            return `$${roundOfNumber(value, 0)} (${percent}%)`;
          }
        },
      },
      graphic: [
        {
          type: "text",
          right: 10,
          top: 20,
          style: {
            text: "Reporting Stage",
            fontSize: 12,
            fill: "black",
          },
        },
      ],
      legend: {
        orient: "vertical",
        right: scope !== 3 && scope !== 4 ? 1 : 0,
        top: 40,
        bottom: 20,
        textStyle: {
          fontSize: 10,
        },
      },
      grid: {
        left: 10,
        right: 10,
        top: 40,
        bottom: 10,
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: "50%",
          center:
            scope === 1 || scope === 2
              ? "60%"
              : scope !== 3 && scope !== 4
              ? ["35%", "50%"]
              : "50%",
          avoidLabelOverlap: true,
          label: {
            show: true,
            formatter({ value, percent }) {
              if ((scope >= 6 && scope <= 11) || scope === 4 || !scope) {
                return `${roundOfNumber(value, 0)}`;
              } else {
                return `$${roundOfNumber(value, 0)} (${percent}%)`;
              }
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          labelLine: {
            show: true,
          },
          data: dataRendered,
        },
      ],
    };
    if (scope === 1 || scope === 2) {
      option.legend.textStyle.fontSize = 12;
    }

    if (scope !== 3 && scope !== 4) {
      option.graphic = [];
    }
    if (scope === 1 || scope === 2) {
      option.legend.left = 1;
    }

    const chart = echarts.init(propertyRef.current);
    chart.setOption(option, true);

    chart.on("finished", () => {
      let src = chart.getDataURL({
        pixelRatio: 2,
        backgroundColor: "#fff",
      });

      onChartRendered(src);
    });

    return () => {
      chart.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyRef, propertyMetrics]);

  return (
    <div className="PropertyReportChart row justify-content-center">
      <div className="property-report-chart-container" ref={propertyRef} />
    </div>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.propertyMetrics) ===
    JSON.stringify(nextProps.propertyMetrics)
  );
};

export default memo(PropertyChart, arePropsEqual);
