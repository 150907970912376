import "@fortawesome/fontawesome-free/css/all.min.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Table,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
  Toast,
  Placeholder,
} from "react-bootstrap";
import { destroy, get } from "../../../utils/DeApi";
import CountDownTimer from "../../App/CountDownTimer/CountDownTimer";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";
import { UserContext } from "../../../contexts/UserProvider";
import { createPortal } from "react-dom";

const UndoDeleteToast = ({
  showToast,
  undoHandler,
  toastRef = null,
  setIsDisabled,
}) => {
  const [show, setShow] = useState(showToast);
  const [timeLeft, setTimeLeft] = useState(6);

  useEffect(() => {
    let timer, interval;
    document.body.style.position = "relative";

    if (timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
        setIsDisabled(true);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (timeLeft < 1) {
      setShow(false);
      setTimeLeft(6);
      setIsDisabled(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, timeLeft]);
  return (
    <>
      {createPortal(
        <div className="position-relative">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            autohide
            delay={5000}
            className={`border mx-auto bg-light position-fixed  start-50 translate-middle ${
              show ? "active" : "inactive"
            }`}
            style={{ zIndex: 10000, bottom: "10px" }}
            ref={toastRef}
          >
            <Toast.Body className={`bg-primary bg-opacity-10 `}>
              <div className="d-flex justify-content-around align-items-center">
                <>
                  <CountDownTimer />
                  <p className="py-0 my-0 ms-1 me-3">Deleting File(s)</p>
                  <Button
                    variant="primary"
                    size="sm"
                    className="mx-3"
                    onClick={() => {
                      undoHandler();
                    }}
                  >
                    Undo
                  </Button>
                </>
              </div>
            </Toast.Body>
          </Toast>
        </div>,
        document.querySelector("#root")
      )}
    </>
  );
};

const Files = ({ property, setFiles, files }) => {
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [filesToBeDeleted, setFilesToBeDeleted] = useState([
    {
      id: "",
      comment: "",
      initializeDeletion: false,
    },
  ]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [successDeleteModal, setSuccessDeleteModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(-1);
  const scrollToToast = useRef(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const { readOnly } = useContext(UserContext);

  useEffect(() => {
    fetchFiles();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  useEffect(() => {
    let timeout;
    if (files && successDeleteModal) {
      setFiles(
        files.filter((file, index) => file.id !== filesToBeDeleted[index]?.id)
      );
      scrollToToast.current.scrollIntoView();
      timeout = setTimeout(() => {
        filesToBeDeleted.forEach((file) => deleteFile(file?.id));
      }, 6000);
    }
    return () => {
      clearTimeout(timeout);
      setIsDisabled(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToBeDeleted, successDeleteModal]);

  const fetchFiles = () => {
    setError();
    setIsLoading(true);

    if (property.id) {
      const filesPromise = get(`/properties/documents/${property.id}`);

      filesPromise.promise
        .then((response) => {
          const sortedFiles = response.data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          setFiles(sortedFiles);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(filesPromise);
    }
  };

  const getFileIcon = (file) => {
    const fileUrl = file.file;
    const fileExtension = fileUrl.split(".").pop().toLowerCase();
    switch (fileExtension) {
      case "doc":
      case "docx":
        return (
          <img
            src="/Word.png"
            className="img-responsive pe-2"
            height={20}
            alt="ERM"
            loading="lazy"
          />
        );
      case "pdf":
        return (
          <img
            src="/PDF.png"
            className="img-responsive pe-2"
            height={20}
            alt="ERM"
            loading="lazy"
          />
        );
      case "xls":
      case "xlsx":
        return (
          <img
            src="/Excel.png"
            className="img-responsive pe-2"
            height={20}
            alt="ERM"
            loading="lazy"
          />
        );
      default:
        return (
          <img
            src="/Image (1).png"
            className="img-responsive pe-2"
            height={20}
            alt="ERM"
            loading="lazy"
          />
        );
    }
  };

  const deleteFile = (id) => {
    const fileDeletePromise = destroy(`property-documents/${id}`);
    fileDeletePromise.promise
      .then(() => {
        setError();
        setSuccessDeleteModal(false);
        fetchFiles();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(fileDeletePromise);
  };

  const downloadFile = (fileUrl, fileDescription, key) => {
    const timestamp = Date.now();
    setIsDownloading(key);
    const fileDownload = fetch(
      `${fileUrl}?crossorigin&timestamp="${timestamp}"`
    );
    fileDownload
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileDescription;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setIsDownloading(-1);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const undoHandler = () => {
    setFilesToBeDeleted([]);
    setSuccessDeleteModal(false);
    fetchFiles();
    setIsDisabled(false);
  };

  const handleDeleteFiles = () => {
    const tempFiles = filesToBeDeleted.map((file) => {
      return { ...file, initializeDeletion: true, message: null };
    });
    setIsDisabled(true);
    setError();
    setFilesToBeDeleted(tempFiles);
    handleDeleteModalClose();
    setSuccessDeleteModal(true);
  };

  const generatePlaceholderRows = (rowCount, columnCount) => {
    const placeholderRows = [];
    for (let i = 0; i < rowCount; i++) {
      const placeholderRow = [];
      for (let j = 0; j < columnCount + 1; j++) {
        placeholderRow.push(
          <Placeholder
            className="custom-placeholder col-7"
            as="td"
            animation="glow"
            key={`${i}-${j}-placeholder`}
          >
            <Placeholder xs={12} className="rounded-pill" />
          </Placeholder>
        );
      }
      placeholderRows.push(<tr key={`row-${i}`}>{placeholderRow}</tr>);
    }
    return placeholderRows;
  };

  return (
    <>
      {typeof error === "object" && !!Object.keys(error).length && (
        <ErrorHandler error={error} />
      )}
      {successDeleteModal && (
        <UndoDeleteToast
          showToast={successDeleteModal}
          undoHandler={undoHandler}
          toastRef={scrollToToast}
          setIsDisabled={setIsDisabled}
        />
      )}
      <div>
        <>
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th
                  className="text-secondary px-2 small col-7"
                  style={{ backgroundColor: "#EBEBEB" }}
                >
                  Filename
                </th>
                <th
                  className="text-secondary px-2 small"
                  style={{ backgroundColor: "#EBEBEB" }}
                >
                  Comments
                </th>
                <th
                  className="text-end pe-4 text-secondary px-2 small"
                  style={{ backgroundColor: "#EBEBEB" }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading && generatePlaceholderRows(5, 2)}
              {!isLoading && files && files.length ? (
                files.map((file, key) => (
                  <tr key={key}>
                    <td
                      className="text-secondary px-2 small col-4"
                      style={{
                        backgroundColor: "#FBFAFA",
                        borderBottomColor: "white",
                        borderBottomWidth: 2,
                        border: "none",
                      }}
                    >
                      {getFileIcon(file)}
                      {file?.document_type?.document_name}
                    </td>
                    <td className="text-secondary px-2 small col-5 text-wrap">
                      {file.comment}
                    </td>

                    <td className="text-end" style={{ border: "none" }}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Download</Tooltip>}
                      >
                        <Button
                          className="me-3 border"
                          variant="outline-success"
                          size="sm"
                          onClick={() => {
                            downloadFile(
                              file.file,
                              file?.document_type?.document_name,
                              key
                            );
                          }}
                          disabled={
                            isDownloading === key ? true : false || isDisabled
                          }
                        >
                          {isDownloading === key ? (
                            <Spinner size="sm" />
                          ) : (
                            <span className="material-symbols-outlined">
                              Download
                            </span>
                          )}
                        </Button>
                      </OverlayTrigger>
                      {!readOnly && (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Delete</Tooltip>}
                        >
                          <Button
                            disabled={isDisabled}
                            variant="outline-danger"
                            className="border"
                            size="sm"
                            onClick={() => {
                              setFilesToBeDeleted([
                                {
                                  id: file.id,
                                  comment: file.comment,
                                  initializeDeletion: false,
                                },
                              ]);
                              setSuccessDeleteModal(false);
                              handleDeleteModalClose();
                            }}
                          >
                            <span className="material-symbols-outlined">
                              Delete
                            </span>
                          </Button>
                        </OverlayTrigger>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  {files && files.length === 0 && (
                    <tr>
                      <td>
                        <Alert variant="info">
                          There are currently no files to show here.
                        </Alert>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </>
      </div>

      {showDeleteModal && (
        <Modal
          show={showDeleteModal}
          onHide={handleDeleteModalClose}
          size="md"
          centered
          style={{ background: "#ffffff70", backdropFilter: " blur(8px)" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <>Delete File</>
            </Modal.Title>
          </Modal.Header>
          <>
            {successDeleteModal && (
              <Alert variant="primary" className="mx-auto my-5">
                You have successfully deleted the file {filesToBeDeleted.name}{" "}
              </Alert>
            )}
            {!successDeleteModal && (
              <>
                <Modal.Body>
                  <p>Are you sure you want to delete this file? </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={handleDeleteModalClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    variant="danger"
                    type="button"
                    onClick={handleDeleteFiles}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </>
            )}
          </>
        </Modal>
      )}
    </>
  );
};

export default Files;
