import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const RedirectIfAuthenticated = ({ isAuthenticated = false }) => {
  const location = useLocation();
  return (
    isAuthenticated && (
      <Navigate
        to={{
          pathname: "/",
          state: { from: location },
        }}
      />
    )
  );
};

export default RedirectIfAuthenticated;
