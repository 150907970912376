import React, { useEffect } from "react";
import {
  Row,
  Col,
  Image,
  OverlayTrigger,
  Popover,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import Charts from "../../PropertyList/Charts";
import BarrierTable from "../../PropertyList/Barrier Table/BarrierTable";
import PropertyCard from "./PropertyCard/PropertyCard";
import Select from "react-select";
import Loader from "../../../Loader/Loader";

const Government = ({
  propertyMetrics,
  properties,
  role,
  program,
  type,
  incentiveUrl,
  setIncentiveUrl,
  kwhUrl,
  setKwhUrl,
  closeStatus,
  setCloseStatus,
  community,
  setCommunity,
  barrierCount,
  setBarrierCount,
  daysInReportingStage,
  setDaysInReportingStage,
  currentPageProperties,
  isLoading,
  isFiltering,
  showReferralTypePopover,
  handlePopoverVisibility,
  selectedReferralType,
  selectedOptionReferralType,
  setSelectedReferralType,
  filterProperties,
  handlePopoverVisibilityOnBlur,
  showStatusPopover,
  selectedStatus,
  selectedOptionStatus,
  setSelectedStatus,
  showBarrierTypePopover,
  selectedBarrierType,
  selectedOptionBarrierType,
  setSelectedBarrierType,
  isMobileScreen,
  mdValue,
  search,
  handleSetSearch,
  handleSearch,
  role_type,
  propertiesOffset,
  lastPageIndex,
  propertiesPerPage,
  handlePropertiesPerPage,
  handlePageClick,
}) => {
  useEffect(() => {
    if (isLoading) return;
    if (propertyMetrics.total === undefined || properties.length === 0) return;
    // Check if the data is already available before rendering the charts and table
  }, [propertyMetrics, properties, isLoading]);

  if (properties.length === 0 || isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div>
        <Row className="mt-3">
          <Col md={2} className="text-start">
            <Image
              src="/reps.png"
              fluid
              style={{ height: "120px", width: "120px", paddingBottom: "0px" }}
            />
          </Col>
          <Col md={8} className="text-center">
            <Image
              src="/repsTitle.png"
              fluid
              style={{ height: "100px", width: "150px", paddingBottom: "0px" }}
            />
          </Col>
        </Row>
      </div>

      {propertyMetrics.total !== undefined &&
        !Array.isArray(propertyMetrics.total) && (
          <Charts
            role={role}
            program={program}
            type={type}
            propertyMetrics={propertyMetrics}
            incentiveUrl={incentiveUrl}
            setIncentiveUrl={setIncentiveUrl}
            kwhUrl={kwhUrl}
            setKwhUrl={setKwhUrl}
            closeStatus={closeStatus}
            setCloseStatus={setCloseStatus}
            community={community}
            setCommunity={setCommunity}
            barrierCount={barrierCount}
            setBarrierCount={setBarrierCount}
            daysInReportingStage={daysInReportingStage}
            setDaysInReportingStage={setDaysInReportingStage}
            properties={properties}
            currentPageProperties={currentPageProperties}
            isLoading={isLoading}
            isFiltering={isFiltering}
          />
        )}

      <BarrierTable properties={properties} />

      <Row className="justify-content-start my-4">
        <OverlayTrigger
          trigger="click"
          placement="top"
          show={showReferralTypePopover}
          onToggle={() => handlePopoverVisibility("referral")}
          overlay={
            <Popover id="popover-basic">
              <Popover.Body>
                <small>Select Multiple Referral Source </small>
              </Popover.Body>
            </Popover>
          }
        >
          <Col xs={12} sm={6} md={2} className="mb-2">
            <Select
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: "12px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontSize: "12px",
                }),
              }}
              closeMenuOnSelect={false}
              defaultValue={selectedReferralType}
              isMulti
              size="sm"
              options={selectedOptionReferralType}
              onChange={(selected) => {
                setSelectedReferralType(selected);
                filterProperties();
              }}
              className="basic-multi-select"
              placeholder="Select ReferralSource.."
              id="referral"
              onBlur={handlePopoverVisibilityOnBlur}
            />
          </Col>
        </OverlayTrigger>
        <OverlayTrigger
          trigger="click"
          placement="top"
          show={showStatusPopover}
          onToggle={() => handlePopoverVisibility("status")}
          overlay={
            <Popover id="popover-basic">
              <Popover.Body>
                <small>Select Multiple Status</small>
              </Popover.Body>
            </Popover>
          }
        >
          <Col xs={12} sm={6} md={2} className="mb-2">
            <Select
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: "12px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontSize: "12px",
                }),
              }}
              closeMenuOnSelect={false}
              value={selectedStatus}
              isMulti
              size="sm"
              options={selectedOptionStatus}
              onChange={(selected) => {
                setSelectedStatus(selected);
                filterProperties();
              }}
              className="basic-multi-select"
              placeholder="Select Status.."
              id="status"
              onBlur={handlePopoverVisibilityOnBlur}
            />
          </Col>
        </OverlayTrigger>
        <OverlayTrigger
          trigger="click"
          placement="top"
          show={showBarrierTypePopover}
          onToggle={() => handlePopoverVisibility("barrier")}
          overlay={
            <Popover id="popover-basic">
              <Popover.Body>
                <small>Select Multiple Barrier Type </small>
              </Popover.Body>
            </Popover>
          }
        >
          <Col xs={12} sm={6} md={2} className="mb-2">
            <Select
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: "12px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontSize: "12px",
                }),
              }}
              closeMenuOnSelect={false}
              defaultValue={selectedBarrierType}
              isMulti
              size="sm"
              options={selectedOptionBarrierType}
              onChange={(selected) => {
                setSelectedBarrierType(selected);
                filterProperties();
              }}
              className="basic-multi-select"
              placeholder="Select BarrierType.."
              id="barrierType"
              onBlur={handlePopoverVisibilityOnBlur}
            />
          </Col>
        </OverlayTrigger>
        <Col
          xs={12}
          sm={12}
          md={mdValue}
          className={`${
            isMobileScreen ? "text-end" : "d-flex justify-content-end"
          }`}
        >
          <div className="d-flex justify-content-md-end">
            <InputGroup style={{ maxHeight: "3rem" }}>
              <Form.Control
                type="search"
                name="search"
                value={search}
                onChange={handleSetSearch}
                placeholder={
                  role !== "utility_partner" && role !== "property_owner"
                    ? "Filter By Property Name"
                    : "Filter By Project Name"
                }
                size="sm"
                onKeyDown={handleSearch}
                style={{
                  height: `${isMobileScreen ? "100%" : "80%"}`,
                  width: "80%",
                }}
                className="placeholder-small-font-size"
              />
              <Button
                style={{
                  height: `${isMobileScreen ? "100%" : "80%"}`,
                }}
                variant="primary"
                size="sm"
                onClick={handleSearch}
                disabled={!search}
              >
                <i className="material-icons-outlined">search</i>
              </Button>
            </InputGroup>
          </div>
        </Col>
      </Row>
      <PropertyCard
        currentPageProperties={currentPageProperties}
        isFiltering={isFiltering}
        type={type}
        role={role}
        role_type={role_type}
        isLoading={isLoading}
        properties={properties}
        propertiesOffset={propertiesOffset}
        lastPageIndex={lastPageIndex}
        propertiesPerPage={propertiesPerPage}
        handlePropertiesPerPage={handlePropertiesPerPage}
        handlePageClick={handlePageClick}
      />
    </>
  );
};

export default Government;
