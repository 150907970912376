import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { UserContext } from "../../../contexts/UserProvider";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import { get } from "../../../utils/DeApi";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";
import "./PropertyList.scss";
import Wap from "../Roles/Wap/Wap";
import PropertyOwner from "../Roles/PropertyOwner/PropertyOwner";
import Government from "../Roles/Government/Government";
import UtilityPartner from "../Roles/UtilityPartner/UtilityPartner";
import Icast from "../Roles/Icast/Icast";

const PropertyList = () => {
  const {
    is_admin,
    email,
    role,
    readOnly,
    program = {},
    type: role_type,
  } = useContext(UserContext);
  const { type } = program;

  const subscribedPromises = useRef([]);

  const [error, setError] = useState(undefined);
  const isMobileScreen = useCheckMobileScreen();
  const [properties, setProperties] = useState([]);
  const [currentProperties, setCurrentProperties] = useState([]);
  const [propertyMetrics, setPropertyMetrics] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [incentiveUrl, setIncentiveUrl] = useState(null);
  const [barrierCount, setBarrierCount] = useState(null);
  const [daysInReportingStage, setDaysInReportingStage] = useState(null);
  const [community, setCommunity] = useState(null);
  const [closeStatus, setCloseStatus] = useState(null);

  const [kwhUrl, setKwhUrl] = useState("");
  const [search, setSearch] = useState("");
  const [propertiesPerPage, setPropertiesPerPage] = useState(10);
  const [currentPageProperties, setCurrentPageProperties] = useState([]);
  const [propertiesOffset, setPropertiesOffset] = useState(0);
  const [lastPageIndex, setLastPageIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedRebateType, setSelectedRebateType] = useState("");
  const [selectedLIMR, setSelectedLIMR] = useState("");
  const [selectedReferralType, setSelectedReferralType] = useState("");
  const [selectedBarrierType, setSelectedBarrierType] = useState("");
  const [selectedOptionStatus, setSelectedOptionStatus] = useState("");
  const [selectedOptionClient, setSelectedOptionClient] = useState("");
  const [selectedOptionDate, setSelectedOptionDate] = useState("");
  const [selectedOptionRebateType, setSelectedOptionRebateType] = useState("");
  const [selectedOptionLIMR, setSelectedOptionLIMR] = useState("");
  const [selectedOptionReferralType, setSelectedOptionReferralType] =
    useState("");
  const [selectedOptionBarrierType, setSelectedOptionBarrierType] =
    useState("");
  const submitSearchTimeout = useRef(null);
  const [showDatePopover, setShowDatePopover] = useState(false);
  const [showStatusPopover, setShowStatusPopover] = useState(false);
  const [showClientPopover, setShowClientPopover] = useState(false);
  const [showRebateTypePopover, setShowRebateTypePopover] = useState(false);
  const [showBarrierTypePopover, setShowBarrierTypePopover] = useState(false);
  const [showReferralTypePopover, setShowReferralTypePopover] = useState(false);
  const [showLIMRPopover, setShowLIMRPopover] = useState(false);

  const [showYearPopover, setShowYearPopover] = useState(false);
  const [selectedYear, setSelectedYear] = useState("All");
  const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);
  const [storedPropertyMetrics, setStoredPropertyMetrics] = useState("");

  useEffect(() => {
    handleFetchContents(search);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filterProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedStatus,
    selectedClient,
    selectedDate,
    selectedRebateType,
    selectedLIMR,
    selectedReferralType,
    selectedBarrierType,
  ]);

  useEffect(() => {
    filterPropertiesByYear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  const generateYearOptions = (type) => {
    const currentYear = new Date().getFullYear();
    const range = 1;
    let yearOptions = [];

    if (type === "gas") {
      yearOptions = [
        { value: "All", label: "All" },
        {
          value: `${currentYear - 1}-${currentYear}`,
          label: `${currentYear - 1}-${currentYear}`,
        },
      ];
    } else {
      yearOptions = [
        { value: "All", label: "All" },
        ...Array.from({ length: range + 1 }, (_, i) => ({
          value: (currentYear - i).toString(),
          label: (currentYear - i).toString(),
        })),
      ];
    }

    return yearOptions;
  };

  const yearOptions = generateYearOptions(type);
  const handleFetchContents = (searchTerm) => {
    setError();

    const propertyPromise = get(`reports/properties?value=${searchTerm}`);

    Promise.all([propertyPromise.promise])
      .then((reponses) => {
        setProperties(reponses[0].data);
        setCurrentProperties(reponses[0].data);
        setIsLoading(false);
        setSelectedOptionRebateType(
          [...new Set(reponses[0].data.map((property) => property?.rebateType))]
            .filter((element) => element)
            .map((element) => {
              return { value: element, label: element };
            })
        );
        setSelectedOptionReferralType(
          [
            ...new Set(
              reponses[0].data.map((property) => property?.referralSource)
            ),
          ]
            .filter((element) => element)
            .map((element) => {
              return { value: element, label: element };
            })
        );
        setSelectedOptionBarrierType(
          [
            ...new Set(
              reponses[0].data.map((property) => property?.barrierType)
            ),
          ]
            .filter((element) => element)
            .map((element) => {
              return { value: element, label: element };
            })
        );
        setSelectedOptionLIMR(
          [
            ...new Set(
              reponses[0].data.map((property) => property?.lowIncomeMartketRate)
            ),
          ]
            .filter(
              (element) =>
                element === "Market Rate" || element === "Income Eligible"
            )
            .map((element) => {
              return { value: element, label: element };
            })
        );

        setSelectedOptionDate(
          [
            ...new Set(
              reponses[0].data.map((property) =>
                property?.projectCompleteDate
                  ? new Date(property?.projectCompleteDate).toLocaleDateString(
                      "en-US"
                    )
                  : null
              )
            ),
          ]
            .filter((element) => element)
            .map((element) => {
              return { value: element, label: element };
            })
        );
        setSelectedOptionStatus(
          [
            ...new Set(
              reponses[0].data.map((property) => property?.projectStatus)
            ),
          ]
            .filter((element) => element)
            .filter((element) =>
              role_type === "NM-MFA"
                ? element === "Complete" || element === "In Progress"
                : true
            )
            .map((element) => {
              return { value: element, label: element };
            })
        );
        setSelectedOptionClient(
          [...new Set(reponses[0].data.map((property) => property?.clientName))]
            .filter((element) => element)
            .map((element) => {
              return { value: element, label: element };
            })
        );

        const statuses = [
          "In Progress",
          "Reserved",
          "Pipeline",
          "Complete",
          "Closed - Testing Only",
          "Closed - Not Interested",
          "Closed - Assessment Only",
          "Project on Hold",
        ];

        function convertStatusToKey(status) {
          switch (status) {
            case "Closed - Testing Only":
              return "ClosedTestingOnly";
            case "Closed - Not Interested":
              return "ClosedNotInterested";
            case "Closed - Assessment Only":
              return "ClosedAssessmentOnly";
            case "Project on Hold":
              return "ProjectOnHold";
            case "Complete":
              return "completed";
            default:
              return status.toLowerCase().replace(/\s+/g, "_");
          }
        }

        const formattedStatuses = statuses.map((status) =>
          convertStatusToKey(status)
        );

        const reponsesData = reponses[0].data.filter(
          (property) => property.ecm
        );

        function calculateMetricsForStatus(status) {
          return {
            electricRebateAmount: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.electricRebateAmount || 0)
            ),
            thermDeemedSavings: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.thermDeemedSavings || 0)
            ),
            gasRebateAmount: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.gasRebateAmount || 0)
            ),
            kwhDeemedSavings: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.kwhDeemedSavings || 0)
            ),
            kwDeemedSavings: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.kwDeemedSavings || 0)
            ),
            energyBurdenCommunity: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ebCommunity || 0)
            ),
            envJusticeCommunity: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ejCommunity || 0)
            ),
            daysCount: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.daysInCurrentReportingStage || 0)
            ),
            liheapCount: sumValues(
              reponsesData
                .filter(
                  (property) => property.barrierType === selectedBarrierType
                )
                .map((property) => property.liheapExpenditure || 0)
            ),
            statusCount: reponsesData.filter(
              (property) => property.projectStatus === status
            ).length,
          };
        }

        function calculateTotalSums() {
          return {
            electricRebateAmount: sumValues(
              reponsesData.map(
                (property) => property.ecm.electricRebateAmount || 0
              )
            ),
            thermDeemedSavings: sumValues(
              reponsesData.map(
                (property) => property.ecm.thermDeemedSavings || 0
              )
            ),
            gasRebateAmount: sumValues(
              reponsesData.map((property) => property.ecm.gasRebateAmount || 0)
            ),
            kwhDeemedSavings: sumValues(
              reponsesData.map((property) => property.ecm.kwhDeemedSavings || 0)
            ),
            kwDeemedSavings: sumValues(
              reponsesData.map((property) => property.ecm.kwDeemedSavings || 0)
            ),
            otherBarrierCount: sumValues(
              reponsesData.map((property) => property.otherBarrierCount || 0)
            ),
            vermiculiteCount: sumValues(
              reponsesData.map((property) => property.vermiculiteCount || 0)
            ),
            knobTubeCount: sumValues(
              reponsesData.map((property) => property.knobTubeCount || 0)
            ),
            moldMoisutreCount: sumValues(
              reponsesData.map((property) => property.moldMoisutreCount || 0)
            ),
            asbestosCount: sumValues(
              reponsesData.map((property) => property.asbestosCount || 0)
            ),
            daysCount: sumValues(
              reponsesData.map((property) => property.daysCount || 0)
            ),
          };
        }

        const _propertyMetrics = {};
        statuses.forEach((status, index) => {
          _propertyMetrics[formattedStatuses[index]] =
            calculateMetricsForStatus(status);
        });
        function calculateMetricsForRate(rate) {
          const filteredData = reponsesData.filter(
            (property) => property.lowIncomeMartketRate === rate && property.ecm
          );

          return {
            electricRebateAmount: sumValues(
              filteredData.map(
                (property) => property.ecm.electricRebateAmount || 0
              )
            ),
            thermDeemedSavings: sumValues(
              filteredData.map(
                (property) => property.ecm.thermDeemedSavings || 0
              )
            ),
            gasRebateAmount: sumValues(
              filteredData.map((property) => property.ecm.gasRebateAmount || 0)
            ),
            kwhDeemedSavings: sumValues(
              filteredData.map((property) => property.ecm.kwhDeemedSavings || 0)
            ),
            kwDeemedSavings: sumValues(
              filteredData.map((property) => property.ecm.kwDeemedSavings || 0)
            ),
          };
        }

        _propertyMetrics["market_rate"] =
          calculateMetricsForRate("Market Rate");
        _propertyMetrics["income_eligible"] =
          calculateMetricsForRate("Income Eligible");

        _propertyMetrics["total"] = calculateTotalSums();

        function sumValues(arr) {
          return arr.reduce((total, amount) => total + amount, 0);
        }

        if (
          role === "utility_partner" ||
          role === "property_owner" ||
          role === "wap"
        ) {
          filterPropertiesByYear(reponses[0].data);
        }

        if (
          role !== "utility_partner" &&
          role !== "property_owner" &&
          role !== "wap"
        ) {
          setPropertyMetrics(_propertyMetrics);
        }

        setStoredPropertyMetrics(_propertyMetrics);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setShowAlert(true);
          setIsLoading(false);
        }
      })
      .finally(() => {
        setIsFiltering(false);
      });

    subscribedPromises.current.push(propertyPromise);
  };

  const mdValue =
    role === "govt" ? 6 : role === "utility_wap" || role === "wap" ? 8 : 4;

  const filterProperties = (_name = "") => {
    setIsFiltering(true);
    let filteredProperties = currentProperties;
    clearTimeout(submitSearchTimeout.current);
    const [startYearSelected, startYearEnded] = selectedYear.split("-") || [];
    submitSearchTimeout.current = setTimeout(() => {
      if (selectedYear !== "All") {
        if (type === "gas") {
          filteredProperties = filteredProperties.filter((property) => {
            if (!property?.projectCompleteDate) return false;
            const completionDate = new Date(property?.projectCompleteDate);
            return (
              completionDate >= new Date(`${startYearSelected}-04-16`) &&
              completionDate <=
                new Date(`${parseInt(startYearEnded) + 1}-04-15`)
            );
          });
        } else {
          filteredProperties = filteredProperties.filter((property) => {
            if (!property?.projectCompleteDate) return false;
            return (
              new Date(property?.projectCompleteDate)
                .getFullYear()
                .toString() === selectedYear
            );
          });
        }
      }

      if (
        selectedStatus.length === 0 &&
        selectedClient.length === 0 &&
        selectedDate.length === 0 &&
        selectedRebateType.length === 0 &&
        selectedLIMR.length === 0 &&
        selectedBarrierType === 0 &&
        selectedReferralType === 0 &&
        search === ""
      ) {
        setProperties(filteredProperties);
      } else {
        filteredProperties = filteredProperties.filter((property) => {
          const statusMatch =
            selectedStatus.length === 0 ||
            selectedStatus.some(
              (status) => status.value === property.projectStatus
            );
          const clientMatch =
            selectedClient.length === 0 ||
            selectedClient.some(
              (client) => client.value === property.clientName
            );

          const dateMatch =
            selectedDate.length === 0 ||
            selectedDate.some((date) => {
              if (!property.projectCompleteDate) return false;
              return (
                new Date(property.projectCompleteDate).toLocaleDateString(
                  "en-US"
                ) === date.value
              );
            });

          const rebateTypeMatch =
            selectedRebateType.length === 0 ||
            selectedRebateType.some(
              (type) => type.value === property?.rebateType
            );

          const limrMatch =
            selectedLIMR.length === 0 ||
            selectedLIMR.some(
              (limr) => limr.value === property?.lowIncomeMartketRate
            );

          const barrierType =
            selectedBarrierType.length === 0 ||
            selectedBarrierType.some(
              (barrier) => barrier.value === property?.barrierType
            );

          const referralSource =
            selectedReferralType.length === 0 ||
            selectedReferralType.some(
              (referral) => referral.value === property?.referralSource
            );

          const name = _name
            ? property?.name.toLowerCase().includes(search.toLowerCase())
            : true;

          return (
            statusMatch &&
            dateMatch &&
            rebateTypeMatch &&
            limrMatch &&
            barrierType &&
            referralSource &&
            clientMatch &&
            name
          );
        });

        setProperties(filteredProperties);
        const statuses = [
          "In Progress",
          "Reserved",
          "Pipeline",
          "Complete",
          "Closed - Testing Only",
          "Closed - Not Interested",
          "Closed - Assessment Only",
          "Project on Hold",
        ];

        function convertStatusToKey(status) {
          switch (status) {
            case "Closed - Testing Only":
              return "ClosedTestingOnly";
            case "Closed - Not Interested":
              return "ClosedNotInterested";
            case "Closed - Assessment Only":
              return "ClosedAssessmentOnly";
            case "Project on Hold":
              return "ProjectOnHold";
            default:
              if (status === "Complete") status = "Completed";
              return status.toLowerCase().replace(/\s+/g, "_");
          }
        }

        const formattedStatuses = statuses.map((status) =>
          convertStatusToKey(status)
        );

        const reponsesData = filteredProperties.filter(
          (property) => property.ecm
        );

        function calculateMetricsForStatus(status) {
          return {
            electricRebateAmount: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.electricRebateAmount || 0)
            ),
            thermDeemedSavings: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.thermDeemedSavings || 0)
            ),
            gasRebateAmount: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.gasRebateAmount || 0)
            ),
            kwhDeemedSavings: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.kwhDeemedSavings || 0)
            ),
            kwDeemedSavings: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.kwDeemedSavings || 0)
            ),
            energyBurdenCommunity: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ebCommunity || 0)
            ),
            envJusticeCommunity: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ejCommunity || 0)
            ),
            daysCount: sumValues(
              reponsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.daysInCurrentReportingStage || 0)
            ),
            liheapCount: sumValues(
              reponsesData

                .filter((property) => {
                  return (
                    property.barrierType === selectedBarrierType &&
                    !!property.liheapExpenditure
                  );
                })
                .map((property) => {
                  return property.liheapExpenditure || 0;
                })
            ),

            statusCount: reponsesData.filter(
              (property) => property.projectStatus === status
            ).length,
          };
        }

        function calculateTotalSums() {
          return {
            electricRebateAmount: sumValues(
              reponsesData.map(
                (property) => property.ecm.electricRebateAmount || 0
              )
            ),
            thermDeemedSavings: sumValues(
              reponsesData.map(
                (property) => property.ecm.thermDeemedSavings || 0
              )
            ),
            gasRebateAmount: sumValues(
              reponsesData.map((property) => property.ecm.gasRebateAmount || 0)
            ),
            kwhDeemedSavings: sumValues(
              reponsesData.map((property) => property.ecm.kwhDeemedSavings || 0)
            ),
            kwDeemedSavings: sumValues(
              reponsesData.map((property) => property.ecm.kwDeemedSavings || 0)
            ),
            otherBarrierCount: sumValues(
              reponsesData.map((property) => property.otherBarrierCount || 0)
            ),
            vermiculiteCount: sumValues(
              reponsesData.map((property) => property.vermiculiteCount || 0)
            ),
            knobTubeCount: sumValues(
              reponsesData.map((property) => property.knobTubeCount || 0)
            ),
            moldMoisutreCount: sumValues(
              reponsesData.map((property) => property.moldMoisutreCount || 0)
            ),
            asbestosCount: sumValues(
              reponsesData.map((property) => property.asbestosCount || 0)
            ),
            liheapCount: reponsesData.map(
              (property) => property.liheapCount || 0
            ),
            daysCount: reponsesData.map((property) => property.daysCount || 0),
          };
        }

        const _propertyMetrics = {};
        statuses.forEach((status, index) => {
          _propertyMetrics[formattedStatuses[index]] =
            calculateMetricsForStatus(status);
        });
        function calculateMetricsForRate(rate) {
          const filteredData = reponsesData.filter(
            (property) => property.lowIncomeMartketRate === rate && property.ecm
          );

          return {
            electricRebateAmount: sumValues(
              filteredData.map(
                (property) => property.ecm.electricRebateAmount || 0
              )
            ),
            thermDeemedSavings: sumValues(
              filteredData.map(
                (property) => property.ecm.thermDeemedSavings || 0
              )
            ),
            gasRebateAmount: sumValues(
              filteredData.map((property) => property.ecm.gasRebateAmount || 0)
            ),
            kwhDeemedSavings: sumValues(
              filteredData.map((property) => property.ecm.kwhDeemedSavings || 0)
            ),
            kwDeemedSavings: sumValues(
              filteredData.map((property) => property.ecm.kwDeemedSavings || 0)
            ),
          };
        }

        _propertyMetrics["market_rate"] =
          calculateMetricsForRate("Market Rate");
        _propertyMetrics["income_eligible"] =
          calculateMetricsForRate("Income Eligible");

        _propertyMetrics["total"] = calculateTotalSums();

        function sumValues(arr) {
          return arr.reduce((total, amount) => total + amount, 0);
        }

        setPropertyMetrics(_propertyMetrics);
      }
      setIsFiltering(false);
    }, 500);
  };

  const filterPropertiesByYear = (_currentProperties = null) => {
    setIsFiltering(true);
    let filteredProperties = _currentProperties ?? currentProperties;

    const statuses = [
      "In Progress",
      "Reserved",
      "Pipeline",
      "Complete",
      "Closed - Testing Only",
      "Closed - Not Interested",
      "Closed - Assessment Only",
      "Project on Hold",
    ];

    function convertStatusToKey(status) {
      switch (status) {
        case "Closed - Testing Only":
          return "ClosedTestingOnly";
        case "Closed - Not Interested":
          return "ClosedNotInterested";
        case "Closed - Assessment Only":
          return "ClosedAssessmentOnly";
        case "Project on Hold":
          return "ProjectOnHold";
        case "Complete":
          return "completed";
        default:
          return status.toLowerCase().replace(/\s+/g, "_");
      }
    }

    clearTimeout(submitSearchTimeout.current);
    const [startYearSelected, startYearEnded] = selectedYear.split("-") || [];

    submitSearchTimeout.current = setTimeout(() => {
      if (selectedYear !== "All") {
        if (type === "gas") {
          filteredProperties = filteredProperties.filter((property) => {
            if (!property?.projectCompleteDate) return false;
            const completionDate = new Date(property.projectCompleteDate);
            return (
              completionDate >= new Date(`${startYearSelected}-04-16`) &&
              completionDate <=
                new Date(`${parseInt(startYearEnded) + 1}-04-15`)
            );
          });
        } else {
          filteredProperties = filteredProperties.filter((property) => {
            if (!property?.projectCompleteDate) return false;
            return (
              new Date(property.projectCompleteDate)
                .getFullYear()
                .toString() === selectedYear
            );
          });
        }

        const formattedStatuses = statuses.map((status) =>
          convertStatusToKey(status)
        );

        const responsesData = filteredProperties.filter(
          (property) => property.ecm
        );

        function calculateMetricsForStatus(status) {
          return {
            electricRebateAmount: sumValues(
              responsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.electricRebateAmount || 0)
            ),
            thermDeemedSavings: sumValues(
              responsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.thermDeemedSavings || 0)
            ),
            gasRebateAmount: sumValues(
              responsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.gasRebateAmount || 0)
            ),
            kwhDeemedSavings: sumValues(
              responsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.kwhDeemedSavings || 0)
            ),
            kwDeemedSavings: sumValues(
              responsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.kwDeemedSavings || 0)
            ),
          };
        }

        function calculateTotalSums() {
          return {
            electricRebateAmount: sumValues(
              responsesData.map(
                (property) => property.ecm.electricRebateAmount || 0
              )
            ),
            thermDeemedSavings: sumValues(
              responsesData.map(
                (property) => property.ecm.thermDeemedSavings || 0
              )
            ),
            gasRebateAmount: sumValues(
              responsesData.map((property) => property.ecm.gasRebateAmount || 0)
            ),
            kwhDeemedSavings: sumValues(
              responsesData.map(
                (property) => property.ecm.kwhDeemedSavings || 0
              )
            ),
            kwDeemedSavings: sumValues(
              responsesData.map((property) => property.ecm.kwDeemedSavings || 0)
            ),
          };
        }

        const _propertyMetrics = {};
        statuses.forEach((status, index) => {
          _propertyMetrics[formattedStatuses[index]] =
            calculateMetricsForStatus(status);
        });

        function calculateMetricsForRate(rate) {
          const filteredData = responsesData.filter(
            (property) => property.lowIncomeMartketRate === rate && property.ecm
          );

          return {
            electricRebateAmount: sumValues(
              filteredData.map(
                (property) => property.ecm.electricRebateAmount || 0
              )
            ),
            thermDeemedSavings: sumValues(
              filteredData.map(
                (property) => property.ecm.thermDeemedSavings || 0
              )
            ),
            gasRebateAmount: sumValues(
              filteredData.map((property) => property.ecm.gasRebateAmount || 0)
            ),
            kwhDeemedSavings: sumValues(
              filteredData.map((property) => property.ecm.kwhDeemedSavings || 0)
            ),
            kwDeemedSavings: sumValues(
              filteredData.map((property) => property.ecm.kwDeemedSavings || 0)
            ),
          };
        }

        _propertyMetrics["market_rate"] =
          calculateMetricsForRate("Market Rate");
        _propertyMetrics["income_eligible"] =
          calculateMetricsForRate("Income Eligible");

        _propertyMetrics["total"] = calculateTotalSums();

        function sumValues(arr) {
          return arr.reduce((total, amount) => total + amount, 0);
        }

        setPropertyMetrics(_propertyMetrics);
      } else if (selectedYear === "All") {
        const formattedStatuses = statuses.map((status) =>
          convertStatusToKey(status)
        );

        const responsesData = filteredProperties;

        function calculateMetricsForStatus(status) {
          return {
            electricRebateAmount: sumValues(
              responsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.electricRebateAmount || 0)
            ),
            thermDeemedSavings: sumValues(
              responsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.thermDeemedSavings || 0)
            ),
            gasRebateAmount: sumValues(
              responsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.gasRebateAmount || 0)
            ),
            kwhDeemedSavings: sumValues(
              responsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.kwhDeemedSavings || 0)
            ),
            kwDeemedSavings: sumValues(
              responsesData
                .filter((property) => property.projectStatus === status)
                .map((property) => property.ecm.kwDeemedSavings || 0)
            ),
          };
        }

        function calculateTotalSums() {
          return {
            electricRebateAmount: sumValues(
              responsesData.map(
                (property) => property.ecm.electricRebateAmount || 0
              )
            ),
            thermDeemedSavings: sumValues(
              responsesData.map(
                (property) => property.ecm.thermDeemedSavings || 0
              )
            ),
            gasRebateAmount: sumValues(
              responsesData.map((property) => property.ecm.gasRebateAmount || 0)
            ),
            kwhDeemedSavings: sumValues(
              responsesData.map(
                (property) => property.ecm.kwhDeemedSavings || 0
              )
            ),
            kwDeemedSavings: sumValues(
              responsesData.map((property) => property.ecm.kwDeemedSavings || 0)
            ),
            liheapCount: sumValues(
              responsesData.map((property) => property.liheapCount || 0)
            ),
          };
        }

        const _propertyMetrics = {};
        statuses.forEach((status, index) => {
          _propertyMetrics[formattedStatuses[index]] =
            calculateMetricsForStatus(status);
        });

        function calculateMetricsForRate(rate) {
          const filteredData = responsesData.filter(
            (property) => property.lowIncomeMartketRate === rate && property.ecm
          );

          return {
            electricRebateAmount: sumValues(
              filteredData.map(
                (property) => property.ecm.electricRebateAmount || 0
              )
            ),
            thermDeemedSavings: sumValues(
              filteredData.map(
                (property) => property.ecm.thermDeemedSavings || 0
              )
            ),
            gasRebateAmount: sumValues(
              filteredData.map((property) => property.ecm.gasRebateAmount || 0)
            ),
            kwhDeemedSavings: sumValues(
              filteredData.map((property) => property.ecm.kwhDeemedSavings || 0)
            ),
            kwDeemedSavings: sumValues(
              filteredData.map((property) => property.ecm.kwDeemedSavings || 0)
            ),
          };
        }

        _propertyMetrics["market_rate"] =
          calculateMetricsForRate("Market Rate");
        _propertyMetrics["income_eligible"] =
          calculateMetricsForRate("Income Eligible");

        _propertyMetrics["total"] = calculateTotalSums();

        function sumValues(arr) {
          return arr.reduce((total, amount) => total + amount, 0);
        }

        setPropertyMetrics(_propertyMetrics);
      }

      setProperties(filteredProperties);
      setIsFiltering(false);
    }, 500);
  };

  const handleSetSearch = ({ target }) => {
    const { value } = target;
    setSearch(value);

    if (!value) {
      setSearch("");
      handleFetchContents(value);
      setIsFiltering(true);
    }
  };

  const getCurrentPageContents = () => {
    const filteredProperty =
      role_type === "NM-MFA"
        ? properties.filter(
            (property) =>
              property.projectStatus === "Complete" ||
              property.projectStatus === "In Progress"
          )
        : properties;

    const endOffset = propertiesOffset + parseInt(propertiesPerPage, 10);

    const currentProperties = filteredProperty.slice(
      propertiesOffset,
      endOffset
    );

    const lastPageIndex =
      endOffset > filteredProperty.length ? filteredProperty.length : endOffset;

    setLastPageIndex(lastPageIndex);
    setCurrentPageProperties(currentProperties);
  };

  const handlePageClick = (event) => {
    const filteredProperty =
      role_type === "NM-MFA"
        ? properties.filter(
            (property) =>
              property.projectStatus === "Complete" ||
              property.projectStatus === "In Progress"
          )
        : properties;

    const newOffset =
      (event.selected * propertiesPerPage) % filteredProperty.length;
    setPropertiesOffset(newOffset);

    getCurrentPageContents();
  };

  useLayoutEffect(() => {
    getCurrentPageContents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties, propertiesPerPage, propertiesOffset, role_type]);

  const handlePropertiesPerPage = ({ target }) => {
    const { value } = target;
    setPropertiesPerPage(value);
  };

  const handleAlertClose = () => {
    setError({});
    setShowAlert(false);
  };

  const handleSearch = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      filterProperties(search);
      setPropertiesOffset(0);
    }
  };

  const handlePopoverVisibility = (popoverName) => {
    setShowDatePopover(popoverName === "date");
    setShowStatusPopover(popoverName === "status");
    setShowClientPopover(popoverName === "client");
    setShowRebateTypePopover(popoverName === "rebateType");
    setShowLIMRPopover(popoverName === "limr");
    setShowYearPopover(popoverName === "year");
    setShowReferralTypePopover(popoverName === "referral");
    setShowBarrierTypePopover(popoverName === "barrier");
  };

  const handlePopoverVisibilityOnBlur = (popoverName) => {
    setShowDatePopover(false);
    setShowStatusPopover(false);
    setShowClientPopover(false);
    setShowRebateTypePopover(false);
    setShowLIMRPopover(false);
    setShowYearPopover(false);
    setShowBarrierTypePopover(false);
    setShowReferralTypePopover(false);
  };
  const renderAdminComponent = (is_admin) => {
    if (is_admin === 1) {
      return (
        <Icast
          propertyMetrics={propertyMetrics}
          email={email}
          properties={properties}
          is_admin={is_admin}
          role={role}
          program={program}
          type={type}
          incentiveUrl={incentiveUrl}
          setIncentiveUrl={setIncentiveUrl}
          kwhUrl={kwhUrl}
          setKwhUrl={setKwhUrl}
          closeStatus={closeStatus}
          setCloseStatus={setCloseStatus}
          community={community}
          setCommunity={setCommunity}
          barrierCount={barrierCount}
          setBarrierCount={setBarrierCount}
          daysInReportingStage={daysInReportingStage}
          setDaysInReportingStage={setDaysInReportingStage}
          currentPageProperties={currentPageProperties}
          isDashboardLoading={isLoading}
          setIsLoading={setIsLoading}
          isFiltering={isFiltering}
          showReferralTypePopover={showReferralTypePopover}
          handlePopoverVisibility={handlePopoverVisibility}
          selectedReferralType={selectedReferralType}
          selectedOptionReferralType={selectedOptionReferralType}
          setSelectedReferralType={setSelectedReferralType}
          filterProperties={filterProperties}
          handlePopoverVisibilityOnBlur={handlePopoverVisibilityOnBlur}
          showStatusPopover={showStatusPopover}
          selectedStatus={selectedStatus}
          selectedOptionStatus={selectedOptionStatus}
          setSelectedStatus={setSelectedStatus}
          showBarrierTypePopover={showBarrierTypePopover}
          selectedBarrierType={selectedBarrierType}
          selectedOptionBarrierType={selectedOptionBarrierType}
          setSelectedBarrierType={setSelectedBarrierType}
          isMobileScreen={isMobileScreen}
          mdValue={mdValue}
          search={search}
          handleSetSearch={handleSetSearch}
          handleSearch={handleSearch}
          role_type={role_type}
          propertiesOffset={propertiesOffset}
          lastPageIndex={lastPageIndex}
          propertiesPerPage={propertiesPerPage}
          handlePropertiesPerPage={handlePropertiesPerPage}
          handlePageClick={handlePageClick}
        />
      );
    }
  };
  const renderRoleComponent = (role) => {
    switch (role) {
      case "wap":
        return (
          <Wap
            currentPageProperties={currentPageProperties}
            isFiltering={isFiltering}
            type={type}
            role={role}
            role_type={role_type}
            isLoading={isLoading}
            properties={properties}
            propertiesOffset={propertiesOffset}
            lastPageIndex={lastPageIndex}
            propertiesPerPage={propertiesPerPage}
            showStatusPopover={showStatusPopover}
            selectedStatus={selectedStatus}
            selectedOptionStatus={selectedOptionStatus}
            showClientPopover={showClientPopover}
            selectedClient={selectedClient}
            selectedOptionClient={selectedOptionClient}
            search={search}
            isMobileScreen={isMobileScreen}
            mdValue={mdValue}
            handleSearch={handleSearch}
            handleSetSearch={handleSetSearch}
            setSelectedClient={setSelectedClient}
            setSelectedStatus={setSelectedStatus}
            filterProperties={filterProperties}
            handlePropertiesPerPage={handlePropertiesPerPage}
            handlePageClick={handlePageClick}
            handlePopoverVisibility={handlePopoverVisibility}
            handlePopoverVisibilityOnBlur={handlePopoverVisibilityOnBlur}
          />
        );
      case "property_owner":
        return (
          <PropertyOwner
            role={role}
            properties={properties}
            handlePopoverVisibility={handlePopoverVisibility}
            handlePopoverVisibilityOnBlur={handlePopoverVisibilityOnBlur}
            isMobileScreen={isMobileScreen}
            type={type}
            showStatusPopover={showStatusPopover}
            selectedStatus={selectedStatus}
            selectedOptionStatus={selectedOptionStatus}
            setSelectedStatus={setSelectedStatus}
            filterProperties={filterProperties}
            showRebateTypePopover={showRebateTypePopover}
            selectedRebateType={selectedRebateType}
            selectedOptionRebateType={selectedOptionRebateType}
            setSelectedRebateType={setSelectedRebateType}
            showLIMRPopover={showLIMRPopover}
            selectedLIMR={selectedLIMR}
            selectedOptionLIMR={selectedOptionLIMR}
            setSelectedLIMR={setSelectedLIMR}
            showDatePopover={showDatePopover}
            selectedDate={selectedDate}
            selectedOptionDate={selectedOptionDate}
            setSelectedDate={setSelectedDate}
            search={search}
            handleSetSearch={handleSetSearch}
            handleSearch={handleSearch}
            currentPageProperties={currentPageProperties}
            isFiltering={isFiltering}
            role_type={role_type}
            isLoading={isLoading}
            propertiesOffset={propertiesOffset}
            lastPageIndex={lastPageIndex}
            propertiesPerPage={propertiesPerPage}
            handlePropertiesPerPage={handlePropertiesPerPage}
            handlePageClick={handlePageClick}
            mdValue={mdValue}
          />
        );
      case "govt":
        return (
          <Government
            propertyMetrics={propertyMetrics}
            properties={properties}
            role={role}
            program={program}
            type={type}
            incentiveUrl={incentiveUrl}
            setIncentiveUrl={setIncentiveUrl}
            kwhUrl={kwhUrl}
            setKwhUrl={setKwhUrl}
            closeStatus={closeStatus}
            setCloseStatus={setCloseStatus}
            community={community}
            setCommunity={setCommunity}
            barrierCount={barrierCount}
            setBarrierCount={setBarrierCount}
            daysInReportingStage={daysInReportingStage}
            setDaysInReportingStage={setDaysInReportingStage}
            currentPageProperties={currentPageProperties}
            isLoading={isLoading}
            isFiltering={isFiltering}
            showReferralTypePopover={showReferralTypePopover}
            handlePopoverVisibility={handlePopoverVisibility}
            selectedReferralType={selectedReferralType}
            selectedOptionReferralType={selectedOptionReferralType}
            setSelectedReferralType={setSelectedReferralType}
            filterProperties={filterProperties}
            handlePopoverVisibilityOnBlur={handlePopoverVisibilityOnBlur}
            showStatusPopover={showStatusPopover}
            selectedStatus={selectedStatus}
            selectedOptionStatus={selectedOptionStatus}
            setSelectedStatus={setSelectedStatus}
            showBarrierTypePopover={showBarrierTypePopover}
            selectedBarrierType={selectedBarrierType}
            selectedOptionBarrierType={selectedOptionBarrierType}
            setSelectedBarrierType={setSelectedBarrierType}
            isMobileScreen={isMobileScreen}
            mdValue={mdValue}
            search={search}
            handleSetSearch={handleSetSearch}
            handleSearch={handleSearch}
            role_type={role_type}
            propertiesOffset={propertiesOffset}
            lastPageIndex={lastPageIndex}
            propertiesPerPage={propertiesPerPage}
            handlePropertiesPerPage={handlePropertiesPerPage}
            handlePageClick={handlePageClick}
          />
        );

      case "icast":
        return <></>;

      case "utility_partner":
        return (
          <UtilityPartner
            role={role}
            propertyMetrics={propertyMetrics}
            properties={properties}
            kwhUrl={kwhUrl}
            incentiveUrl={incentiveUrl}
            program={program}
            showYearPopover={showYearPopover}
            handlePopoverVisibility={handlePopoverVisibility}
            isYearDropdownOpen={isYearDropdownOpen}
            setIsYearDropdownOpen={setIsYearDropdownOpen}
            yearOptions={yearOptions}
            setSelectedYear={setSelectedYear}
            filterPropertiesByYear={filterPropertiesByYear}
            handlePopoverVisibilityOnBlur={handlePopoverVisibilityOnBlur}
            isMobileScreen={isMobileScreen}
            selectedYear={selectedYear}
            type={type}
            storedPropertyMetrics={storedPropertyMetrics}
            isLoading={isFiltering}
            search={search}
            setKwhUrl={setKwhUrl}
            closeStatus={closeStatus}
            setCloseStatus={setCloseStatus}
            community={community}
            setCommunity={setCommunity}
            barrierCount={barrierCount}
            setBarrierCount={setBarrierCount}
            daysInReportingStage={daysInReportingStage}
            setDaysInReportingStage={setDaysInReportingStage}
            currentPageProperties={currentPageProperties}
            isFiltering={isFiltering}
            showStatusPopover={showStatusPopover}
            selectedStatus={selectedStatus}
            selectedOptionStatus={selectedOptionStatus}
            setSelectedStatus={setSelectedStatus}
            filterProperties={filterProperties}
            showRebateTypePopover={showRebateTypePopover}
            selectedRebateType={selectedRebateType}
            selectedOptionRebateType={selectedOptionRebateType}
            setSelectedRebateType={setSelectedRebateType}
            showLIMRPopover={showLIMRPopover}
            selectedLIMR={selectedLIMR}
            selectedOptionLIMR={selectedOptionLIMR}
            setSelectedLIMR={setSelectedLIMR}
            showDatePopover={showDatePopover}
            selectedDate={selectedDate}
            selectedOptionDate={selectedOptionDate}
            setSelectedDate={setSelectedDate}
            mdValue={mdValue}
            handleSetSearch={handleSetSearch}
            handleSearch={handleSearch}
            role_type={role_type}
            propertiesOffset={propertiesOffset}
            lastPageIndex={lastPageIndex}
            propertiesPerPage={propertiesPerPage}
            handlePropertiesPerPage={handlePropertiesPerPage}
            handlePageClick={handlePageClick}
            setIncentiveUrl={setIncentiveUrl}
          />
        );
      default:
        return (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <span className="fs-4 fw-bold text-secondary text-center">
              Dashboard Under Construction
            </span>
          </div>
        );
    }
  };

  return (
    <>
      {isLoading && !isFiltering ? (
        <Loader />
      ) : (
        <>
          {error && (
            <ErrorHandler
              error={error}
              handleClose={handleAlertClose}
              show={showAlert}
            />
          )}
          {renderAdminComponent(is_admin)}
          {renderRoleComponent(role)}
        </>
      )}
    </>
  );
};

export default PropertyList;
