import React, { useEffect, useState, useContext } from "react";
import Loader from "../../Loader/Loader";
import { logOut } from "../../../utils/BeeApi";
import { logOut as deLogout } from "../../../utils/DeApi";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { UserDispatchDestoryContext } from "../../../contexts/UserProvider";

const Logout = () => {
  const clearContext = useContext(UserDispatchDestoryContext);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    const handleLogout = () => {
      setIsLoggingOut(true);
      setError();
      logOut()
        .then(() => {
          return deLogout();
        })
        .then(() => {
          clearContext();
          setIsLoggingOut(false);
        })
        .catch((error) => {
          setError(error);
          setIsLoggingOut(false);
        });
    };

    handleLogout();
  });

  if (isLoggingOut) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
};

export default Logout;
