import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

const BreadCrumbs = ({ breadcrumbs }) => {
  if (!breadcrumbs || !breadcrumbs.length) return <span />;

  return (
    <Breadcrumb>
      {breadcrumbs.map((crumb, index) => {
        if (crumb.link)
          return (
            <Breadcrumb.Item
              key={index}
              linkAs={Link}
              linkProps={{ to: crumb.link }}
              active={!!crumb.active}
            >
              {crumb.name}
            </Breadcrumb.Item>
          );
        else
          return (
            <Breadcrumb.Item key={index} linkAs={"span"}>
              {crumb.name}
            </Breadcrumb.Item>
          );
      })}
    </Breadcrumb>
  );
};

export default BreadCrumbs;
