import React, { useRef, useState } from "react";
import ReportHeader from "./ReportHeader";
import ReportSubHeader from "./ReportSubHeader";
import ReportContent from "./ReportContent";
import ReportTable from "./ReportTable";
import ReportChart from "./ReportChart";
import { Container, Modal, Button, Col, Spinner, Row } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { fileConvert } from "../../../utils/BeeApi";
import { saveAs } from "file-saver";

const ReportPreview = ({
  propertyMetrics = {},
  properties = [],
  kwhUrl = "",
  incentiveUrl = "",
  program = {},
}) => {
  const reportRef = useRef();
  const [show, setShow] = useState(false);
  const [downloadingReport, setDownloadingReport] = useState(false);

  const hanldeClose = () => {
    setShow(false);
    setDownloadingReport(false);
  };
  const handleShow = () => setShow(true);
  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
  });

  const handleDownload = useReactToPrint({
    content: () => reportRef.current,
    print: async (printIframe) => {
      setDownloadingReport(true);
      const document = printIframe.contentDocument;
      if (document) {
        var base = document.createElement("base");
        base.href = window.location.origin;
        document.getElementsByTagName("head")[0].appendChild(base);

        var head = document.getElementsByTagName("head")[0];
        var links = head.getElementsByTagName("link");
        for (var link in links) {
          if (links.hasOwnProperty(link)) {
            var l = links[link];
            if (l.rel === "stylesheet") {
              l.href = "" + l.href;
            }
          }
        }
        const html = document.getElementsByTagName("html")[0];
        const htmlFile = new Blob([html.outerHTML], {
          type: "text/plain;charset=utf-8",
        });
        downloadReport(htmlFile);
      } else {
        console.warn("Printing Error");
      }
    },
  });

  const downloadReport = (htmlFile) => {
    const formData = new FormData();

    formData.append("html_file", htmlFile);
    formData.append("file_name", "report");

    const auditPromise = fileConvert(`/services/html-to-pdf`, formData);
    auditPromise.promise
      .then(async (response) => {
        const pdfFile = new Blob([response.data], {
          type: "text/plain;charset=utf-8",
        });
        saveAs(pdfFile, `${program.name ?? "Report"} Monthly Report.pdf`);
      })
      .catch((error) => {
        console.warn(error);
      })
      .finally(() => {
        setDownloadingReport(false);
      });
  };

  return (
    <>
      {/* <Button onClick={handleShow} size="sm" variant="outline-primary">
        Preview Report
      </Button> */}
      <Modal show={show} onHide={hanldeClose} size="xl" scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>{program?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid ref={reportRef}>
            <ReportHeader />
            <ReportSubHeader propertyMetrics={propertyMetrics} />
            <hr />
            {properties.map((property) => (
              <span key={property.id}>
                <ReportContent property={property} />
                <hr />
              </span>
            ))}
            <ReportTable properties={properties} />
            <ReportChart kwhUrl={kwhUrl} incentiveUrl={incentiveUrl} />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row className="gx-2">
            <Col>
              <Button
                disabled={downloadingReport}
                onClick={handlePrint}
                variant="outline-primary"
              >
                Print
              </Button>
            </Col>
            <Col>
              <Button
                disabled={downloadingReport}
                onClick={handleDownload}
                variant="primary"
              >
                {downloadingReport && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}{" "}
                Download
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReportPreview;
