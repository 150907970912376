import axios from "axios";
import { apiErrorHandler } from "./ApiErrorHandling";
import { MakeCancelable } from "./CancelablePromise";

const END_POINT = process.env.REACT_APP_BEE_ENDPOINT;
const xsrfToken = Math.random().toString(36);

const httpOnlyConfig = {
  "XSRF-TOKEN": xsrfToken,
  version: "httpOnly",
};

let instance = axios.create({
  baseURL: END_POINT,
  headers: { common: { "XSRF-TOKEN": xsrfToken } },
  withCredentials: true,
  transformRequest: [
    (data) => {
      return data;
    },
    ...axios.defaults.transformRequest,
  ],
});

const registerInterceptor = (instance) => {
  instance.interceptors.response.use(
    function (response) {
      return Promise.resolve(response.data);
    },
    function (error) {
      return apiErrorHandler(error);
    }
  );
};

registerInterceptor(instance);

const login = (data) => {
  return instance
    .post("/login", { ...data, ...httpOnlyConfig })
    .then((response) => {
      return Promise.resolve(response);
    });
};

const get = (url, data) => {
  return MakeCancelable(instance.get(url, { ...data, ...httpOnlyConfig }));
};

const logOut = () => {
  return instance
    .delete("/login", {
      params: { ...httpOnlyConfig },
    })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const put = (url, data) => {
  return MakeCancelable(instance.put(url, { ...data, ...httpOnlyConfig }));
};

const upload = (url, formData) => {
  for (const [key, value] of Object.entries(httpOnlyConfig)) {
    formData.append(key, value);
  }
  return MakeCancelable(
    instance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  );
};

function fileConvert(url, data) {
  let getFile = axios.create({
    baseURL: `https://multitenant-dev.pixeledge.io/api/v2/`,
    headers: { common: { "XSRF-TOKEN": xsrfToken } },
    withCredentials: true,
    transformRequest: [
      (data) => {
        return data;
      },
      ...axios.defaults.transformRequest,
    ],
  });

  Object.keys(httpOnlyConfig).forEach((key) => {
    data.append(key, httpOnlyConfig[key]);
  });
  return MakeCancelable(
    getFile.post(url, data, {
      responseType: "blob",
      contentType: "application/octet-stream",
    })
  );
}

export { login, get, logOut, put, upload, fileConvert };
