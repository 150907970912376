import React from "react";
import { Card, Col, Row, Alert, InputGroup, Form } from "react-bootstrap";
import PropertyTable from "./PropertyTable/PropertyTable";
import PropertyTablePagination from "./PropertyTable/PropertyTablePagination";

const PropertyCard = ({
  currentPageProperties,
  isFiltering,
  type,
  role,
  isLoading,
  properties,
  propertiesOffset,
  lastPageIndex,
  propertiesPerPage,
  handlePropertiesPerPage,
  handlePageClick,
  role_type,
}) => {
  const filteredProperties = properties;
  return (
    <div>
      <Card className="border-0">
        <Card.Body className="p-0">
          <Row className="px-2">
            <Col className="table-wrapper px-0 mx-1">
              <PropertyTable
                properties={currentPageProperties}
                isLoading={isFiltering}
                type={type}
                role={role}
                role_type={role_type}
              />
              {!isLoading && properties.length === 0 && (
                <Alert variant="primary">No Properties could be found.</Alert>
              )}
            </Col>
          </Row>
          {!!properties.length && !isFiltering && (
            <Row className="p-2 small">
              <Col className="col-6 pt-2">
                <span>
                  Showing {propertiesOffset + 1} to {lastPageIndex} of{" "}
                  {filteredProperties.length} entries
                </span>
              </Col>
              <Col className="col-2">
                <InputGroup style={{ width: "150px" }}>
                  <InputGroup.Text style={{ width: "70px" }}>
                    Rows
                  </InputGroup.Text>
                  <Form.Select
                    size="sm"
                    value={propertiesPerPage}
                    style={{ width: "10px" }}
                    onChange={handlePropertiesPerPage}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col className="col-4 d-flex justify-content-end">
                <PropertyTablePagination
                  propertiesPerPage={propertiesPerPage}
                  totalProperties={filteredProperties.length}
                  handlePageClick={handlePageClick}
                />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default PropertyCard;
