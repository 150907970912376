import React from "react";
import { Card, Table } from "react-bootstrap";

const DemographicTable = ({ title, data, columnWidths, propertyDetails }) => (
  <Card className="my-3 shadow-sm border-0">
    <Table
      hover
      style={{
        backgroundColor: "white",
        borderCollapse: "collapse",
        border: "1px solid #ddd",
      }}
      className="small"
    >
      <thead>
        <tr>
          <th
            scope="col"
            className="p-2 text-primary"
            style={{
              backgroundColor: "#EBEBEB",
              border: "1px solid #ddd",
            }}
          >
            {title}
          </th>
          <th
            scope="col"
            className="p-2 text-primary"
            style={{
              backgroundColor: "#EBEBEB",
              border: "1px solid #ddd",
              width: columnWidths || "150px", // Default width if not specified
            }}
          >
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td
              className="text-secondary px-2 small"
              style={{
                border: "1px solid #ddd",
                fontSize: "14px",
              }}
            >
              {row.label}
            </td>
            <td
              className="text-secondary px-3 small"
              style={{ border: "1px solid #ddd" }}
            >
              {row.value}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Card>
);

const Demographics = ({ propertyDetails }) => {
  const occupiedUnitsData = [
    { label: "Elderly", value: propertyDetails.elderlyOccupiedUnits },
    { label: "Disabled", value: propertyDetails.disabledOccupiedUnits },
    {
      label: "Native American",
      value: propertyDetails.nativeAmericanOccupiedUnits,
    },
    { label: "Children", value: propertyDetails.childrenOccupiedUnits },
  ];

  const assistedIndividualsData = [
    { label: "Elderly", value: propertyDetails.elderlyIndividualsAssisted },
    { label: "Disabled", value: propertyDetails.disabledIndividualsAssisted },
    {
      label: "Native American",
      value: propertyDetails.nativeAmericanIndividualsAssisted,
    },
    { label: "Children", value: propertyDetails.childrenAssisted },
  ];

  return (
    <>
      <h6
        className="text-center text-primary small fw-bold mt-5"
        style={{ fontStyle: "italic" }}
      >
        Demographics
      </h6>
      <DemographicTable title="Occupied Units" data={occupiedUnitsData} />
      <DemographicTable
        title="Individual Assisted"
        data={assistedIndividualsData}
      />
    </>
  );
};

export default Demographics;
