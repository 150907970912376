import React from "react";
import { Col, Row } from "react-bootstrap";
import { roundOfNumber } from "../../../utils/RoundOfNumber";

const RenderContent = ({ property = "", value = "" }) => {
  return (
    <Row className="p-1">
      <Col className="small col-7 fs-7 reportFontSize">{property}</Col>
      <Col className="small fs-7 reportFontSize">{value}</Col>
    </Row>
  );
};

const ReportSubHeader = ({ propertyMetrics = {} }) => {
  return (
    <Row className="mt-3">
      <Col className="pr-0">
        <Row>
          <h6 className="text-center small text-decoration-underline">
            Completed Summary
          </h6>
        </Row>
        <RenderContent
          property={"Total kWh Completed"}
          value={`$${roundOfNumber(
            propertyMetrics.completed.kwhDeemedSavings,
            1
          )}`}
        />
        <RenderContent
          property={"Total Rebates Completed"}
          value={`$${roundOfNumber(
            propertyMetrics.completed.electricRebateAmount,
            1
          )}`}
        />
      </Col>
      <Col className="p-0">
        <Row>
          <h6 className="text-center small text-decoration-underline">
            In Progress
          </h6>
        </Row>
        <RenderContent
          property={"Total kWh Completed"}
          value={`$ ${roundOfNumber(
            propertyMetrics.in_progress.kwhDeemedSavings,
            1
          )}`}
        />
        <RenderContent
          property={"Total Rebates Completed"}
          value={`$ ${roundOfNumber(
            propertyMetrics.in_progress.electricRebateAmount,
            1
          )}`}
        />
      </Col>
      <Col className="pl-0">
        <Row>
          <h6 className="text-center small text-decoration-underline">
            Reserved Summary
          </h6>
        </Row>
        <RenderContent
          property={"Total kWh Completed"}
          value={`$ ${roundOfNumber(
            propertyMetrics.reserved.kwhDeemedSavings,
            1
          )}`}
        />
        <RenderContent
          property={"Total Rebates Completed"}
          value={`$ ${roundOfNumber(
            propertyMetrics.reserved.electricRebateAmount,
            1
          )}`}
        />
      </Col>
    </Row>
  );
};

export default ReportSubHeader;
