import React, { useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useLocation,
  Navigate,
} from "react-router-dom";

import Login from "./Login/Login";
import Signup from "./SignUp/SignUp";
import Logout from "./Logout/Logout";
import NoMatch from "./NoMatch/NoMatch";
import Dashboard from "../Dashboard/Dashboard";
import ResetPassword from "./ResetPassword/ResetPassword";
import { UserContext } from "../../contexts/UserProvider";
import { UserProvider } from "../../contexts/UserProvider";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import Property from "../Property/Property";

const App = () => {
  return (
    <div className="Main">
      <ScrollToTop />
      <UserProvider>
        <Router>
          <Routes>
            <Route path="" element={<PrivateRoute />}>
              <Route path="logout" element={<Logout />} />
              <Route index path="/" element={<Dashboard />} />
              <Route path="properties/*" element={<Property />} />
            </Route>

            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Router>
      </UserProvider>
    </div>
  );
};

/** Middleware for private routes. */
const PrivateRoute = () => {
  const location = useLocation();
  const user = useContext(UserContext);
  return user ? (
    <Outlet />
  ) : (
    <Navigate to="login" state={{ from: location }} replace />
  );
};

/** Scroll To Top on Navigation */
const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

export default App;
