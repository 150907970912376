import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import Loader from "../../Loader/Loader";
import { roundOfNumber } from "../../../utils/RoundOfNumber";

const PropertyMetrics = ({
  propertyMetrics = {},
  storedPropertyMetrics = {},
  isLoading,
  type,
  program,
  selectedYear,
}) => {
  const RenderRows = ({ property, value }) => {
    return (
      <Row>
        <Col>
          <h6 className="text-dark small">{property}</h6>
        </Col>
        <Col>
          <h6 className="text-end small text-dark">
            {property.includes("Rebate") ? <>$</> : null}{" "}
            {roundOfNumber(value, 0)}
          </h6>
        </Col>
      </Row>
    );
  };

  const RenderMetrics = ({
    summary,
    property1,
    value1,
    property2,
    value2,
    property3,
    value3,
  }) => {
    return (
      <Col md={4}>
        <Row>
          <Col className="d-flex flex-column justify-content-between">
            <Card className="shadow-sm border-0">
              <Card.Header
                style={{
                  backgroundColor: "#EBEBEB",
                  width: "100%",
                  textAlign: "center",
                }}
                className="border-0"
              >
                <span className="text-primary small">{summary}</span>
              </Card.Header>
              <Card.Body>
                {property1 && (
                  <RenderRows property={property1} value={value1} />
                )}
                {property2 && (
                  <RenderRows property={property2} value={value2} />
                )}
                {property3 && (
                  <>
                    <RenderRows property={property3} value={value3} />
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <Col className="my-3">
      <h5 className="text-center text-primary small fw-bolder mb-3">
        Program Status {selectedYear.replace("-", " to ")}
      </h5>
      <Row className="align-items-center mb-5">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {type === "electric" && (
              <>
                <RenderMetrics
                  summary={"Completed"}
                  property1={"Electric Rebate Amount"}
                  value1={propertyMetrics?.completed?.electricRebateAmount}
                  property2={"kWh Deemed Savings"}
                  value2={propertyMetrics?.completed?.kwhDeemedSavings}
                  property3={"kW Deemed Savings"}
                  value3={propertyMetrics?.completed?.kwDeemedSavings}
                />
                <RenderMetrics
                  summary={"In Progress"}
                  property1={"Electric Rebate Amount"}
                  value1={
                    storedPropertyMetrics?.in_progress?.electricRebateAmount
                  }
                  property2={"kWh Deemed Savings"}
                  value2={storedPropertyMetrics?.in_progress?.kwhDeemedSavings}
                  property3={"kW Deemed Savings"}
                  value3={storedPropertyMetrics?.in_progress?.kwDeemedSavings}
                />{" "}
                <RenderMetrics
                  summary={"Reserved"}
                  property1={"Electric Rebate Amount"}
                  value1={storedPropertyMetrics?.reserved?.electricRebateAmount}
                  property2={"kWh Deemed Savings"}
                  value2={storedPropertyMetrics?.reserved?.kwhDeemedSavings}
                  property3={"kW Deemed Savings"}
                  value3={storedPropertyMetrics?.reserved?.kwDeemedSavings}
                />
              </>
            )}{" "}
            {type === "gas" &&
              !(program?.name?.trim().toLowerCase() === "nm gas") && (
                <>
                  {" "}
                  <RenderMetrics
                    summary={"Completed"}
                    property1={"Gas Rebate Amount"}
                    value1={propertyMetrics?.completed?.gasRebateAmount}
                  />
                  <RenderMetrics
                    summary={"In Progress"}
                    property1={"Gas Rebate Amount"}
                    value1={storedPropertyMetrics?.in_progress?.gasRebateAmount}
                  />{" "}
                  <RenderMetrics
                    summary={"Reserved"}
                    property1={"Gas Rebate Amount"}
                    value1={storedPropertyMetrics?.reserved?.gasRebateAmount}
                  />
                </>
              )}{" "}
            {type === "gas" &&
              program?.name?.trim().toLowerCase() === "nm gas" && (
                <>
                  {" "}
                  <RenderMetrics
                    summary={"Completed"}
                    property1={"Gas Rebate Amount"}
                    value1={propertyMetrics?.completed?.gasRebateAmount}
                    property2={"Therm Savings"}
                    value2={propertyMetrics?.completed?.thermDeemedSavings}
                  />
                  <RenderMetrics
                    summary={"In Progress"}
                    property1={"Gas Rebate Amount"}
                    value1={storedPropertyMetrics?.in_progress?.gasRebateAmount}
                    property2={"Therm Savings"}
                    value2={
                      storedPropertyMetrics?.in_progress?.thermDeemedSavings
                    }
                  />{" "}
                  <RenderMetrics
                    summary={"Reserved"}
                    property1={"Gas Rebate Amount"}
                    value1={storedPropertyMetrics?.reserved?.gasRebateAmount}
                    property2={"Therm Savings"}
                    value2={storedPropertyMetrics?.reserved?.thermDeemedSavings}
                  />
                </>
              )}
          </>
        )}

        {!isLoading && !Object.keys(propertyMetrics).length && (
          <span>Ooops No Metrics Could be found</span>
        )}
      </Row>

      <h5 className="text-center text-primary small fw-bolder mb-3">
        Income Eligible/Market Rate
      </h5>
      <Row className="justify-content-center mt-2">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {type === "electric" && (
              <>
                <RenderMetrics
                  summary={"Market Rate"}
                  property1={"Electric Rebate Amount"}
                  value1={
                    storedPropertyMetrics?.market_rate?.electricRebateAmount
                  }
                  property2={"kWh Deemed Savings"}
                  value2={storedPropertyMetrics?.market_rate?.kwhDeemedSavings}
                  property3={"kW Deemed Savings"}
                  value3={storedPropertyMetrics?.market_rate?.kwDeemedSavings}
                />
                <RenderMetrics
                  summary={"Income Eligible"}
                  property1={"Electric Rebate Amount"}
                  value1={
                    storedPropertyMetrics?.income_eligible?.electricRebateAmount
                  }
                  property2={"kWh Deemed Savings"}
                  value2={
                    storedPropertyMetrics?.income_eligible?.kwhDeemedSavings
                  }
                  property3={"kW Deemed Savings"}
                  value3={
                    storedPropertyMetrics?.income_eligible?.kwDeemedSavings
                  }
                />{" "}
              </>
            )}{" "}
            {type === "gas" &&
              !(program?.name?.trim().toLowerCase() === "nm gas") && (
                <>
                  {" "}
                  <RenderMetrics
                    summary={"Market Rate"}
                    property1={"Gas Rebate Amount"}
                    value1={storedPropertyMetrics?.market_rate?.gasRebateAmount}
                  />
                  <RenderMetrics
                    summary={"Income Eligible"}
                    property1={"Gas Rebate Amount"}
                    value1={
                      storedPropertyMetrics?.income_eligible?.gasRebateAmount
                    }
                  />
                </>
              )}
            {type === "gas" &&
              program?.name?.trim().toLowerCase() === "nm gas" && (
                <>
                  {" "}
                  <RenderMetrics
                    summary={"Market Rate"}
                    property1={"Gas Rebate Amount"}
                    value1={storedPropertyMetrics?.market_rate?.gasRebateAmount}
                    property2={"Therm Savings"}
                    value2={
                      storedPropertyMetrics?.market_rate?.thermDeemedSavings
                    }
                  />
                  <RenderMetrics
                    summary={"Income Eligible"}
                    property1={"Gas Rebate Amount"}
                    value1={
                      storedPropertyMetrics?.income_eligible?.gasRebateAmount
                    }
                    property2={"Therm Savings"}
                    value2={
                      storedPropertyMetrics?.income_eligible?.thermDeemedSavings
                    }
                  />
                </>
              )}
          </>
        )}
        {!isLoading && !Object.keys(propertyMetrics).length && (
          <span>Ooops No Metrics Could be found</span>
        )}
      </Row>
    </Col>
  );
};

export default PropertyMetrics;
